import React from 'react';

type LoadingProps = {
  className?: string;
};

export const Loading = ({ className }: LoadingProps) => {
  return (
    <div className={`flex items-center justify-center my-20 ${className}`}>
      <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-b-4 border-blue_3"></div>
    </div>
  );
};
