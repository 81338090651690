import { CLASS_MANAGEMENT, HOST_URL_PORTAL } from '@core/constant/api.contant';
import axiosInstance_admin from './restful.portal.service';

const getAllClasses = (params: any) => {
  return axiosInstance_admin
    .get(HOST_URL_PORTAL + CLASS_MANAGEMENT.CLASS, { params })
    .then(response => {
      return response?.data;
    });
};

const addClass = (params: any) => {
    return axiosInstance_admin
    .post(HOST_URL_PORTAL + CLASS_MANAGEMENT.CLASS, params)
    .then(response => {
      return response?.data;
    });
}

const updateClass = (params: any) => {
    return axiosInstance_admin
    .put(HOST_URL_PORTAL + CLASS_MANAGEMENT.CLASS, params)
    .then(response => {
      return response?.data;
    });
}

const classDetail = (id: string) => {
    return axiosInstance_admin
    .get(HOST_URL_PORTAL + CLASS_MANAGEMENT.DETAIL_CLASS(id))
    .then(response => {
      return response?.data;
    });
}

const addStudentToClass = (params: any) => {
    return axiosInstance_admin
    .post(HOST_URL_PORTAL + CLASS_MANAGEMENT.ADD_STUDENT_TO_CLASS, params)
    .then(response => {
      return response?.data;
    });
}

const removeStudentFromClass = (params: any) => {
    return axiosInstance_admin
    .delete(HOST_URL_PORTAL + CLASS_MANAGEMENT.REMOVE_STUDENT_FROM_CLASS, { data: params })
    .then(response => {
      return response?.data;
    })
    ;
}

const getStudentInClass = (params: any, id: string) => {
    return axiosInstance_admin
    .get(HOST_URL_PORTAL + CLASS_MANAGEMENT.GET_STUDENT_IN_CLASS(id), { params })
    .then(response => {
      return response?.data;
    });
}

const ClassManagementService = {
    getAllClasses,
    addClass,
    updateClass,
    classDetail,
    addStudentToClass,
    removeStudentFromClass,
    getStudentInClass,
};

export default ClassManagementService;
