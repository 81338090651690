import MasterLayout from '@shared/layouts/MasterLayout';
import Search from 'pages/search/Search';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const SearchRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route index element={<Search />} />
      </Route>
    </Routes>
  );
};
