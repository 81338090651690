import React from 'react';
import { book } from 'types/data.type';
import my_learning_icon from 'assets/imgs/my-learning-icon.svg';
import process_icon from 'assets/imgs/processIcon.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BoxContainer } from './BoxContainer';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import { FaFireAlt } from 'react-icons/fa';
import { FaRegClock } from 'react-icons/fa6';
import {
  checkComingSoon,
  countComingSoonDays,
  countRemainingDays,
  processBook
} from '@core/hooks/common';
import { Button } from './button/Button';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';

type SliderBookAssignProps = {
  items: book[];
  title?: string;
};

export const SliderBookAssign: React.FC<SliderBookAssignProps> = ({
  items,
  title
}) => {
  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <div className="flex gap-2 items-center">
          <div className="p-2 rounded-lg flex justify-center items-center bg-gray-F7F7F7">
            <img src={my_learning_icon} alt="My Learning Icon" />
          </div>
          <p className="text-[22px] font-medium">{title}</p>
        </div>
        <a href="/list-assign" className="text-235E5A font-medium text-sm">
          View All
        </a>
      </div>
      <div className="relative">
        <div className="hidden xl:flex gap-x-[10px] bottom-[50%] w-full justify-between">
          <Button className="swiper-button-prev md:w-8 md:h-8 !bg-F3FAFA rounded-[4px] !p-0 absolute top-[50%] -left-[45px] z-50">
            <LiaAngleLeftSolid size={16} color="#235E5A" />
          </Button>
          <Button className="swiper-button-next md:w-8 md:h-8 !bg-F3FAFA rounded-[4px] !p-0 absolute top-[50%] -right-[45px] z-50">
            <LiaAngleRightSolid size={16} color="#235E5A" />
          </Button>
        </div>
        <Swiper
          loop={true}
          spaceBetween={20}
          autoplay={false}
          speed={500}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
          breakpoints={{
            500: {
              slidesPerView: 2
            },
            768: {
              slidesPerView: 3
            },
            1024: {
              slidesPerView: 4
            },
            1220: {
              slidesPerView: 5
            }
          }}
          grabCursor={true}
          touchEventsTarget="wrapper"
          modules={[Autoplay, Pagination, Navigation]}
          className="flex relative sliderBookAssign"
        >
          {items?.map(item => (
            <SwiperSlide key={item?.id} className="!flex justify-center">
              <BoxContainer className="w-[195px] max-h-[300px] h-[300px]">
                <Link to={`/books/view/${item?.id}`}>
                  <div className="max-h-[222px] h-[222px] overflow-hidden w-full">
                    <img
                      src={
                        item?.is_listen
                          ? item?.cover_listen_img
                          : item?.is_read
                          ? item?.cover_read_img
                          : item?.cover_img
                      }
                      alt="Book Img"
                      className="rounded-lg object-cover"
                    />
                  </div>
                  <div className="mt-2 flex flex-col gap-1">
                    <div
                      className={`flex items-center gap-[2px] ${
                        countRemainingDays(item?.to_date) > 1
                          ? ''
                          : 'text-D92D20'
                      }`}
                    >
                      {countRemainingDays(item?.to_date) > 1 ? (
                        <FaRegClock />
                      ) : (
                        <FaFireAlt />
                      )}
                      <p className="text-sm font-normal leading-[14px]">
                        {!checkComingSoon(item?.from_date)
                          ? `${countRemainingDays(item?.to_date)}${
                              countRemainingDays(item?.to_date) > 1
                                ? ' days left'
                                : ' day left'
                            }`
                          : `Opens in ${countComingSoonDays(item?.from_date)}${
                              countComingSoonDays(item?.from_date) > 1
                                ? ' days'
                                : ' day'
                            }`}
                      </p>
                    </div>
                    {!checkComingSoon(item?.from_date) && (
                      <div className="flex gap-2 items-center">
                        <div className="flex-1 flex gap-[2px] items-center">
                          <img
                            src={process_icon}
                            alt="Process Icon"
                            className="w-4"
                          />
                          <ProgressBar
                            now={processBook(
                              Math.round(
                                (item?.current_page_count /
                                  item?.total_page_count) *
                                  100
                              ),
                              Math.round(
                                (item?.current_cover_count /
                                  item?.total_cover_count) *
                                  100
                              )
                            )}
                            label={
                              <>
                                <div className="h-[8px] bg-blue_2 rounded"></div>
                              </>
                            }
                            className="w-full h-[8px]"
                          />
                        </div>
                        <p className="text-sm">
                          {processBook(
                            Math.round(
                              (item?.current_page_count /
                                item?.total_page_count) *
                                100
                            ),
                            Math.round(
                              (item?.current_cover_count /
                                item?.total_cover_count) *
                                100
                            )
                          )}
                          %
                        </p>
                      </div>
                    )}
                  </div>
                </Link>
              </BoxContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};
