import { HOST_URL_PORTAL } from '@core/constant/api.contant';
import axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

const axiosInstance_admin = axios.create({
  baseURL: HOST_URL_PORTAL,
  headers: {
    Accept: 'application/json',
    'ngrok-skip-browser-warning': '69420',
    'Content-Type': 'application/json'
  }
});

axiosInstance_admin.interceptors.request.use(
  (config: any) => {
    let admin: any = localStorage.getItem('admin');
    // let user: any = sessionStorage.getItem('admin');
    if (admin) {
      admin = JSON.parse(admin);
      const token = admin.access_token;
      const auth = token ? `Bearer ${token}` : '';
      config.headers['Authorization'] = auth;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance_admin.interceptors.response?.use(
  response => response,
  error => {
    // Handle 401 errors here
    if (error?.response?.status === 401) {
      // Perform the action you want for unauthorized requests
      // For example, redirect to the login page or refresh the token
      localStorage.removeItem('admin');
      window.location.href = '/portal/login';
    }
    // return Promise.reject(error);
  }
);

export default axiosInstance_admin;
