import {
  checkComingSoon,
  countComingSoonDays,
  countRemainingDays,
  getAssignStatus,
  processBook,
  processReadOrListen
} from '@core/hooks/common';
import StudentManagementService from '@core/services/student.portal.service';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

type StudentDetailProps = {
  studentClass: string[];
  studentID: any;
};

const titleStyle = 'text-lg font-bold leading-6 text-center';

const StudentDetail = ({ studentClass, studentID }: StudentDetailProps) => {
  const [listBooksAssign, setListBooksAssign] = useState<any>([]);

  useEffect(() => {
    const getBooksAssignToStudent = async (id: string) => {
      const res = await StudentManagementService.getBooksAssignToStudent(id);
      if (res?.status === 1) {
        setListBooksAssign(res.data);
      }
    };

    getBooksAssignToStudent(studentID);
  }, [studentID]);

  return (
    <div className="flex flex-col gap-5">
      <div>
        <p className={titleStyle}>Student Class</p>
        <div className="w-full h-auto relative mt-2 overflow-auto rounded shadow">
          <table className="table w-full rounded-lg">
            <thead className="bg-gray-f2 border-b-2 border-gray-200">
              <tr>
                <th scope="col">
                  <span>STT</span>
                </th>
                <th scope="col">
                  <span>Name</span>
                </th>
                <th scope="col">
                  <span>Type</span>
                </th>
                <th scope="col">
                  <span>Status</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {studentClass?.map((item: any, index: number) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.type}</td>
                    <td>
                      <span
                        className={`status status-${
                          item?.is_active ? 'active' : 'inactive'
                        }`}
                      >
                        {item?.is_active ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-10">
        <p className={titleStyle}>Student Books Assign</p>
        <div className="w-full h-auto relative mt-2 overflow-auto rounded shadow">
          <table className="table w-full rounded-lg">
            <thead className="bg-gray-f2 border-b-2 border-gray-200">
              <tr>
                <th scope="col">
                  <span>STT</span>
                </th>
                <th scope="col" className="text-center">
                  <span>Image</span>
                </th>
                <th scope="col">
                  <span>Title</span>
                </th>
                <th scope="col">
                  <span>From date</span>
                </th>
                <th scope="col">
                  <span>To Date</span>
                </th>
                <th scope="col" className="text-center">
                  <span>Progress</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {listBooksAssign?.items?.map((item: any, index: number) => {
                return (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td className="flex justify-center">
                      <img
                        src={
                          item?.cover_listen_img
                            ? item?.cover_listen_img
                            : item?.cover_read_img
                        }
                        alt=""
                        className="h-[200px]"
                      />
                    </td>
                    <td>{item?.title}</td>
                    <td>{moment(item?.from_date).format('DD/MM/YYYY')}</td>
                    <td>{moment(item?.to_date).format('DD/MM/YYYY')}</td>
                    <td className="!text-center">
                      <div className="flex flex-col gap-1 text-sm">
                        <span>{getAssignStatus(item)}</span>
                        <span>
                          {!checkComingSoon(item?.from_date)
                            ? `${countRemainingDays(item?.to_date)}${
                                countRemainingDays(item?.to_date) > 1
                                  ? ' days left'
                                  : ' day left'
                              }`
                            : `Opens in ${countComingSoonDays(
                                item?.from_date
                              )}${
                                countComingSoonDays(item?.from_date) > 1
                                  ? ' days'
                                  : ' day'
                              }`}
                        </span>
                        {item?.is_read && (
                          <span>
                            Reading:{' '}
                            {processReadOrListen(
                              item?.current_cover_count,
                              item?.total_cover_count
                            )}
                            %
                          </span>
                        )}
                        {item?.is_listen && (
                          <span>
                            Listening:{' '}
                            {processReadOrListen(
                              item?.current_page_count,
                              item?.total_page_count
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StudentDetail;
