import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/imgs/logo_header.svg';
import 'animate.css';
import { FiLogOut } from 'react-icons/fi';
import { IoMdMenu } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { AccoutType } from 'types/Auth.type';

const HeaderNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const accountType = localStorage.getItem('user_type');

  const url = useLocation().pathname;

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate(`/login`);
  };

  const handleClickMenu = (route: string) => {
    navigate(route);
  };

  return (
    <div className="container">
      <div className=" hidden md:flex justify-between py-4">
        <div className="flex gap-8">
          <img
            src={Logo}
            alt="Logo"
            className=" cursor-pointer"
            onClick={() => handleClickMenu('/')}
          />
          <ul className="header">
            <li
              onClick={() => handleClickMenu('/')}
              className={`header-item ${
                url === '/' ? 'header-item-active' : ''
              }`}
            >
              <p>Home</p>
            </li>
            {accountType === AccoutType.PREMIUM && (
              <li
                onClick={() => handleClickMenu('/list-assign')}
                className={`header-item ${
                  url.includes('/list-assign') ? 'header-item-active' : ''
                }`}
              >
                My Learning
              </li>
            )}

            <li
              onClick={() => handleClickMenu('/books')}
              className={`header-item ${
                url.includes('/books') ? 'header-item-active' : ''
              }`}
            >
              Book Nook
            </li>
            <li
              onClick={() => handleClickMenu('/news')}
              className={`header-item ${
                url.includes('/news') ? 'header-item-active' : ''
              }`}
            >
              News Hub
            </li>
          </ul>
        </div>
        <div className="flex items-end pb-2">
          <div
            className="rounded-lg p-2 bg-F3FAFA flex justify-center items-center gap-1 h-fit cursor-pointer"
            onClick={handleLogout}
          >
            <FiLogOut className="text-235E5A" />
            <p className="text-sm font-medium text-235E5A">Log Out</p>
          </div>
        </div>
      </div>
      <div className="flex md:hidden justify-between py-4 items-center relative">
        <img
          src={Logo}
          alt="Logo"
          className=" cursor-pointer"
          onClick={() => handleClickMenu('/')}
        />
        <div className="p-1" onClick={() => setIsOpen(true)}>
          <IoMdMenu className="text-blue_2 w-8 h-8 " />
        </div>
      </div>
      {isOpen && (
        <div className=" absolute top-0 right-0 left-0 bg-white h-[300px] z-10 shadow">
          <div className=" relative w-full h-full flex flex-col items-center justify-center gap-y-5">
            <div
              onClick={() => setIsOpen(false)}
              className=" absolute top-3 right-4 p-3"
            >
              <IoClose className="text-blue_2 w-8 h-8 " />
            </div>
            {accountType === AccoutType.PREMIUM && (
              <div
                onClick={() => handleClickMenu('/list-assign')}
                className={`header-item cursor-pointer ${
                  url.includes('/list-assign') ? 'text-blue_2' : ''
                }`}
              >
                My Learning
              </div>
            )}
            <div
              onClick={() => handleClickMenu('/')}
              className={`header-item cursor-pointer ${
                url === '/' ? 'text-blue_2' : ''
              }`}
            >
              <p>Home</p>
            </div>
            <div
              onClick={() => handleClickMenu('/books')}
              className={`header-item cursor-pointer ${
                url.includes('/books') ? 'text-blue_2' : ''
              }`}
            >
              Book Nook
            </div>
            <div
              onClick={() => handleClickMenu('/news')}
              className={`header-item cursor-pointer ${
                url.includes('/news') ? 'text-blue_2' : ''
              }`}
            >
              News Hub
            </div>
            <div onClick={handleLogout} className="header-item cursor-pointer">
              Log Out
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderNav;
