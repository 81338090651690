import { ArticleCard } from '@shared/components/ArticleCard';
import Breadcrumb from '@shared/components/breadcrumb/Breadcrumb';
import TitlePage from '@shared/components/titlePage/TitlePage';
import { ClassroomIcon } from 'assets/icons/ClassroomIcon';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Modal from '@shared/components/Modal';
import { CircleSpin } from 'assets/icons/CircleSpin';
import ClassManagementService from '@core/services/class.portal.service';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import StudentManagementService from '@core/services/student.portal.service';
import SearchButton from '@shared/components/SearchButton';
import { BiPlusMedical } from 'react-icons/bi';
import { PortalButton } from '@shared/components/button/PortalButton';

const OptionStyle = {
  form_group_plus: 'w-full',
  label_plus: 'font-semibold text-sm leading-5'
};

const classTypeOptions = [
  {
    value: 'Senior7',
    label: 'Senior - 7'
  },
  {
    value: 'Senior8',
    label: 'Senior - 8'
  },
  {
    value: 'Senior9',
    label: 'Senior - 9'
  },
  {
    value: 'Senior10',
    label: 'Senior - 10'
  },
  {
    value: 'Junior4',
    label: 'Junior - 4'
  },
  {
    value: 'Junior5',
    label: 'Junior - 5'
  },
  {
    value: 'Junior6',
    label: 'Junior - 6'
  },
  {
    value: 'Kid1',
    label: 'Kid - 1'
  },
  {
    value: 'Kid2',
    label: 'Kid - 2'
  },
  {
    value: 'Kid3',
    label: 'Kid - 3'
  },
  {
    value: 'Jolly',
    label: 'Jolly'
  },
  {
    value: 'Phonics',
    label: 'Phonics'
  },
  {
    value: 'Grammar',
    label: 'Grammar'
  },
  {
    value: 'TestPreparationIELTS',
    label: 'Test Preparation - IELTS'
  },
  {
    value: 'TestPreparationPTE',
    label: 'Test Preparation - PTE'
  },
  {
    value: 'TestPreparationDuoLingo',
    label: 'Test Preparation - DuoLingo'
  },
  {
    value: 'TestPreparationAdmission10',
    label: 'Test Preparation - Tuyển sinh 10'
  },
  {
    value: 'TestPreparationCollegeEntrance',
    label: 'Test Preparation - Tuyển sinh ĐH'
  },
  {
    value: 'StudyingAbroad',
    label: 'Studying Abroad'
  },
  {
    value: 'OneOnOne',
    label: '1 on 1'
  },
  {
    value: 'Pathways1',
    label: 'Pathways - 1'
  },
  {
    value: 'Pathways2',
    label: 'Pathways - 2'
  },
  {
    value: 'Pathways3',
    label: 'Pathways - 3'
  },
  {
    value: 'Pathways4',
    label: 'Pathways - 4'
  }
];

const ClassManagementAdd = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors }
  } = useForm<any>({});
  const _paramsURL = useParams();
  const [paramsURL, setParamsURL] = useState({ ..._paramsURL });
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
  const [confirmAddModal, setConfirmAddModal] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [submitUpdate, setSubmitUpdate] = useState(false);
  const [allStudentLoading, setAllStudentLoading] = useState(true);
  const [reloadPage, setReloadPage] = useState<any>();
  const [studentInClassLoading, setStudentInClassLoading] = useState(true);
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [allStudentData, setAllStudentData] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>();

  const [classStudentData, setClassStudentData] = useState<any>([]);
  const navigate = useNavigate();
  const [classType, setClassType] = useState({
    value: 'Senior7',
    label: 'Senior - 7'
  });
  const [status, setStatus] = useState({
    value: true,
    label: 'Active'
  });
  const [loading, setLoading] = useState(true);
  const [detailClass, setDetailClass] = useState<any>({});
  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const [crumbs] = useState([
    {
      name: 'Class Management',
      url: `/portal/class-management`
    },
    {
      name: `${paramsURL.id ? 'Classroom Adjustment' : 'Create Classroom'}`,
      url: `${
        paramsURL.id
          ? `/portal/class-management/${paramsURL.id}`
          : '/portal/class-management'
      }`
    }
    // ${detailAccount?.id}
  ]);

  const getDetailClass = async (id: string) => {
    setLoading(true);
    const res = await ClassManagementService.classDetail(id);
    if (res?.status === 1) {
      setDetailClass(res?.data);
      setValue('name', res?.data?.name);
      setValue('is_active', res?.data?.is_active);
      setValue('type', res?.data?.type);
      setStatus({
        value: res?.data?.is_active,
        label: res?.data?.is_active ? 'Active' : 'Inactive'
      });
      setClassType({
        value: res?.data?.type,
        label: res?.data?.type
      });
      setLoading(false);
    } else {
      toast.error('Get Detail Fail! Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
      setLoading(false);
    }
  };

  const getStudent = async (id: string) => {
    const params = {
      class_id: id,
      page_index: 1,
      page_size: 100
    };

    try {
      setStudentInClassLoading(true);
      const res = await ClassManagementService.getStudentInClass(params, id);
      if (res?.status === 1) {
        setClassStudentData(res?.data?.items);

        setStudentInClassLoading(false);
      } else {
        toast.error("Can't get student list. Try again!", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
        setStudentInClassLoading(false);
      }
    } catch (error) {
      toast.error("Can't get student list. Try again!", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
      setStudentInClassLoading(false);
    }
  };

  const getAllStudent = async () => {
    let params = {
      search_key: '',
      page_index: 1,
      page_size: 10
    };

    if (keyword) {
      params = {
        ...params,
        search_key: keyword,
        page_size: 100
      };
    }

    try {
      setAllStudentLoading(true);
      const res = await StudentManagementService.getAllStudents(params);
      if (res?.status === 1) {
        setAllStudentData(
          res?.data?.items?.filter(
            (item: any) =>
              !classStudentData?.some(
                (classItem: any) => item?.id === classItem?.id
              )
          )
        );

        setAllStudentLoading(false);
      } else {
        toast.error("Can't get student list. Try again!", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });

        setAllStudentLoading(false);
      }
    } catch (error) {
      toast.error("Can't get student list. Try again!", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });

      setAllStudentLoading(false);
    }
  };

  useEffect(() => {
    getAllStudent();
  }, [keyword, classStudentData, reloadPage]);

  useEffect(() => {
    setValue('is_active', status.value);
    setValue('type', classType.value);
    if (paramsURL.id) {
      getDetailClass(paramsURL.id);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (paramsURL.id) {
      getStudent(paramsURL.id as string);
    }
  }, [paramsURL.id, reloadPage]);

  const submitHandler: SubmitHandler<any> = async value => {
    if (paramsURL.id) {
      const params = {
        ...value,
        id: paramsURL.id
      };
      setSubmitUpdate(true);
      try {
        const res = await ClassManagementService.updateClass(params);
        if (res?.status === 1) {
          setSubmitUpdate(false);
          setConfirmUpdateModal(false);
          toast.success('Update Success!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          navigate(`/portal/class-management/edit-class/${paramsURL.id}`);
        } else {
          toast.error('Update Fail! Please try again.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          setSubmitUpdate(false);
          setConfirmUpdateModal(false);
        }
      } catch (error) {
        toast.error('Update Fail! Please try again.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
        setSubmitUpdate(false);
        setConfirmUpdateModal(false);
      }
    }

    if (!paramsURL.id) {
      const params = {
        ...value
      };
      setSubmitAdd(true);
      try {
        const res = await ClassManagementService.addClass(params);
        if (res?.status === 1) {
          setSubmitAdd(false);
          setConfirmAddModal(false);
          toast.success('Create Success!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          navigate(`/portal/class-management/edit-class/${res?.data?.id}`);
        } else {
          toast.error('Create Fail! Please try again.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          setSubmitAdd(false);
          setConfirmAddModal(false);
        }
      } catch (error) {
        toast.error('Create Fail! Please try again.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
        setSubmitAdd(false);
        setConfirmAddModal(false);
      }
    }
  };

  const onError = (errors: any, e: any) => {
    if (errors) {
      for (const key in errors) {
        const messageError = `${errors[key].message}`;
        toast.error(messageError || 'Có lỗi, vui lòng kiểm tra lại!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      }
      if (paramsURL.id) {
        setConfirmUpdateModal(false);
      } else {
        setConfirmAddModal(false);
      }
    }
  };

  const onSearch = (reset?: boolean) => {
    navigate(`/portal/class-management/edit-class/${paramsURL.id}/add-student`);
    const newKeywork = reset ? '' : keyword?.trim();
    setKeyword(newKeywork);
    setReloadPage(!reloadPage);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
      onSearch();
    }
  };

  const handleClassTypeChange = (select: any) => {
    setValue('type', select.value);
    setClassType(select);
  };

  const handleStatusChange = (select: any) => {
    setValue('is_active', select.value);
    setStatus(select);
  };

  const handleRemoveStudent = async (id: string) => {
    const params = {
      student_id: id,
      class_id: parseInt(paramsURL.id as string)
    };
    const res = await ClassManagementService.removeStudentFromClass(params);
    if (res?.status === 1) {
      toast.success('Remove student success!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
      setReloadPage(!reloadPage);
    } else {
      toast.error("Can't remove student. Try again!", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
    }
  };

  const handleAddStudentToClass = async (id: string) => {
    const params = {
      student_id: id,
      class_id: parseInt(paramsURL.id as string)
    };
    const res = await ClassManagementService.addStudentToClass(params);
    if (res?.status === 1) {
      toast.success('Add student success!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
      setReloadPage(!reloadPage);
    } else {
      toast.error("Can't add student. Try again!", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
    }
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage
        icon={() => <ClassroomIcon />}
        name={`${paramsURL.id ? 'Classroom Adjustment' : 'Create Classroom'}`}
      />
      <div className="mt-10">
        {paramsURL.id && (
          <div className="flex">
            <div className="p-[10px] font-bold bg-white border-l border-r border-t-primary-main border-t-[3px] border-gray-e0 rounded-t-[4px]">
              <p>Class</p>
            </div>
            {/* <div className="p-[10px]">
              <Link to={'add-student'}>Học Sinh</Link>
            </div> */}
            <div className="p-[10px]">
              <Link to={'assign-book'}>Book</Link>
            </div>
          </div>
        )}
        <form
          className="form"
          onSubmit={handleSubmit(submitHandler, onError)}
          noValidate
          autoComplete="off"
        >
          <ArticleCard
            className={`${paramsURL.id && 'border-t-0 rounded-t-none'}`}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <CircleSpin />
              </div>
            ) : (
              <>
                <div className="flex justify-between">
                  <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
                    <label
                      htmlFor="name"
                      className={`${OptionStyle.label_plus}`}
                    >
                      Class Name
                    </label>
                    <input
                      id="name"
                      type="text"
                      required
                      placeholder="Enter Class Name"
                      className="form-control"
                      {...register('name', {
                        required: 'Class name is required',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('name');
                        }
                      })}
                    />
                    {errors?.name?.message && (
                      <span className="text-redCustom-3b text-xs">
                        {errors?.name?.message as string}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                    <label className={`${OptionStyle.label_plus}`} htmlFor="">
                      Type
                    </label>
                    <Select
                      options={classTypeOptions}
                      placeholder="Choose class type"
                      value={classType}
                      onChange={handleClassTypeChange}
                      className="form-select mt-1"
                      classNamePrefix="select"
                    />
                    <span className="text-redCustom-3b text-xs">
                      {errors?.is_active?.message as string}
                    </span>
                  </div>
                  <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                    <label className={`${OptionStyle.label_plus}`} htmlFor="">
                      Status
                    </label>
                    <Select
                      options={[
                        {
                          value: true,
                          label: 'Active'
                        },
                        {
                          value: false,
                          label: 'Inactive'
                        }
                      ]}
                      placeholder="Choose status"
                      value={status}
                      onChange={handleStatusChange}
                      className="form-select mt-1"
                      classNamePrefix="select"
                    />
                    <span className="text-redCustom-3b text-xs">
                      {errors?.is_active?.message as string}
                    </span>
                  </div>
                </div>
              </>
            )}
          </ArticleCard>
          {paramsURL.id && (
            <ArticleCard className="mt-3">
              <p className="text-lg font-bold text-center">
                List student in class
              </p>
              <div className="mt-5">
                {studentInClassLoading ? (
                  <div className="flex justify-center items-center">
                    <CircleSpin />
                  </div>
                ) : (
                  <table className="table w-full rounded-lg scroll-table student-assign-table">
                    <thead className="bg-gray-f2 border-b-2 border-gray-200">
                      <tr>
                        <th className="!w-[60px]">STT</th>
                        <th scope="col">
                          <span>User Name</span>
                        </th>
                        <th scope="col">
                          <span>Full Name</span>
                        </th>
                        <th scope="col">
                          <span>Account Type</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span>Action</span>
                        </th>
                      </tr>
                    </thead>

                    <>
                      <tbody>
                        {classStudentData?.map((item: any, index: number) => {
                          return (
                            <tr key={item?.id}>
                              <th className="!w-[60px]">{index + 1}</th>
                              <th className="font-semibold" scope="row">
                                <span>{item?.username}</span>
                              </th>
                              <td className="order">
                                <span>{item?.fullname}</span>
                              </td>
                              <td className="order">
                                <span>{item?.type || '-'}</span>
                              </td>
                              <td className="flex justify-center">
                                <div
                                  className="table-action-btn table-action-edit w-fit !p-[10px] text-base"
                                  onClick={() => {
                                    handleRemoveStudent(item?.id);
                                  }}
                                >
                                  <AiOutlineDelete className="text-redCustom-3b" />
                                </div>
                                <Link
                                  to={`/portal/student-management/edit-student/${item.id}`}
                                >
                                  <div className="table-action-btn table-action-edit w-fit !p-[10px] text-base">
                                    <AiOutlineEdit />
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  </table>
                )}
              </div>
            </ArticleCard>
          )}
          <div className="flex gap-2">
            <PortalButton
              type="button"
              className="min-w-[140px]"
              onClick={() => {
                paramsURL.id
                  ? setConfirmUpdateModal(true)
                  : setConfirmAddModal(true);
              }}
            >
              {paramsURL.id ? 'Update' : 'Add'}
            </PortalButton>
            {paramsURL.id && (
              <PortalButton
                type="button"
                className="min-w-[140px]"
                onClick={() => {
                  setShowAddStudentModal(true);
                }}
              >
                Add Student
              </PortalButton>
            )}
          </div>
          <Modal
            show={confirmAddModal}
            title={'Do you want to add this class?'}
            onClose={() => {
              setConfirmAddModal(false);
            }}
            className="max-w-[420px] font-Roboto"
          >
            <div className="flex space-x-4">
              <PortalButton
                type="button"
                className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                onClick={() => {
                  setConfirmAddModal(false);
                }}
              >
                Cancel
              </PortalButton>
              <PortalButton
                type="submit"
                className="flex justify-center w-full md:w-52"
                disabled={submitAdd}
                onClick={handleSubmit(submitHandler, onError)}
              >
                {submitAdd && <CircleSpin />}
                Submit
              </PortalButton>
            </div>
          </Modal>
          <Modal
            show={confirmUpdateModal}
            title={'Confirm Update'}
            onClose={() => {
              setConfirmUpdateModal(false);
            }}
            className="max-w-[420px] font-Roboto"
          >
            <div className="flex space-x-4">
              <PortalButton
                type="button"
                className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                onClick={() => {
                  setConfirmUpdateModal(false);
                }}
              >
                Cancel
              </PortalButton>
              <PortalButton
                type="submit"
                className="flex justify-center w-full md:w-52"
                disabled={submitUpdate}
                onClick={handleSubmit(submitHandler, onError)}
              >
                {submitUpdate && <CircleSpin />}
                Submit
              </PortalButton>
            </div>
          </Modal>
          <Modal
            show={showAddStudentModal}
            title={'Add Student'}
            onClose={() => {
              setShowAddStudentModal(false);
            }}
            className="max-w-[700px] font-Roboto"
          >
            <ArticleCard>
              <div className="flex justify-between items-center">
                <p className="text-lg font-bold">List student</p>
                <div className="flex items-center -mt-3">
                  <div className="mt-3">
                    <SearchButton
                      onSearch={onSearch}
                      keyword={keyword}
                      setKeyword={setKeyword}
                      _handleKeyDown={_handleKeyDown}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5">
                {allStudentLoading ? (
                  <div className="flex justify-center items-center">
                    <CircleSpin />
                  </div>
                ) : (
                  <table className="table scroll-table student-table w-full rounded-lg">
                    <thead className="bg-gray-f2 border-b-2 border-gray-200">
                      <tr>
                        <th className="w-[60px]">STT</th>
                        <th scope="col">
                          <span>User Name</span>
                        </th>
                        <th scope="col">
                          <span>Full Name</span>
                        </th>
                        <th scope="col">
                          <span>Account type</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span>Action</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {allStudentData?.map((item: any, index: number) => {
                        return (
                          <tr key={item?.id}>
                            <th className="w-[60px]">{index + 1}</th>
                            <th
                              className="font-semibold overflow-x-scroll"
                              scope="row"
                            >
                              <span>{item?.username}</span>
                            </th>
                            <td className="order">
                              <span>{item?.fullname}</span>
                            </td>
                            <td className="order">
                              <span>{item?.type || '-'}</span>
                            </td>
                            <td className="flex justify-center">
                              <div
                                className="table-action-btn table-action-edit w-fit text-base !p-[10px]"
                                onClick={() => {
                                  handleAddStudentToClass(item?.id);
                                }}
                              >
                                <BiPlusMedical className="text-green-500" />
                              </div>
                              <Link
                                to={`/portal/student-management/edit-student/${item.id}`}
                              >
                                <div className="table-action-btn table-action-edit w-fit !p-[10px] text-base">
                                  <AiOutlineEdit />
                                </div>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </ArticleCard>
            <div className="flex space-x-4">
              <PortalButton
                type="button"
                className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                onClick={() => {
                  setShowAddStudentModal(false);
                }}
              >
                Cancel
              </PortalButton>
            </div>
          </Modal>
        </form>
      </div>
    </>
  );
};

export default ClassManagementAdd;
