import React, { useEffect, useState } from 'react';
import HeaderNav from './HeaderNav';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import { Button } from '@shared/components/Index';
import { AiOutlineArrowUp } from 'react-icons/ai';
import zalo_icon from 'assets/imgs/zalo_icon.png';
import ZaloSupportPopup from '@shared/components/ZaloSupportPopup';

const MasterLayout = () => {
  const [isShow, setIsShow] = useState(false);
  const [isShowZaloSupportPopup, setIsShowZaloSupportPopup] = useState(false);

  const handleScroll = () => {
    const position = window.scrollY;
    if (position > 400) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  };

  // const handleShowZaloSupportPopup = () => {
  //   setIsShowZaloSupportPopup(!isShowZaloSupportPopup);
  // };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleRollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="flex flex-col h-full">
      <header>
        <HeaderNav />
      </header>
      <main className="flex-1">
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
      {/* <div className="flex flex-col gap-3 fixed bottom-20 right-0 z-50">
        {isShowZaloSupportPopup && (
          <ZaloSupportPopup onClose={handleShowZaloSupportPopup} />
        )}
        <div className='cursor-pointer' onClick={() => {handleShowZaloSupportPopup()}}>
          <img src={zalo_icon} alt="Zalo Icon" className="w-20" />
        </div>
      </div> */}
      <div
        className={`fixed bottom-[30px] right-[14px] z-50 ${
          isShow ? 'appear-animation' : 'dissappear-animation'
        }  `}
        onClick={handleRollToTop}
      >
        <Button
          className="!w-[51px] !h-[51px] !p-0 border border-solid border-[#fff] rounded-md transition-all"
          style={`primary`}
        >
          <AiOutlineArrowUp size={20} className="text-white" />
        </Button>
      </div>
    </div>
  );
};

export default MasterLayout;
