import MasterLayoutPortal from '@shared/layouts/MasterLayoutPortal';
import AddStudentToClass from 'portal-pages/classManagement/AddStudentToClass';
import AssignBook from 'portal-pages/classManagement/AssignBook';
import ClassManagement from 'portal-pages/classManagement/ClassManagement';
import ClassManagementAdd from 'portal-pages/classManagement/ClassManagementAdd';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const ClassManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayoutPortal />}>
        <Route index element={<ClassManagement />} />
      </Route>
      <Route path="/add-class" element={<MasterLayoutPortal />}>
        <Route index element={<ClassManagementAdd />} />
      </Route>
      <Route path="/edit-class/:id" element={<MasterLayoutPortal />}>
        <Route index element={<ClassManagementAdd />} />
      </Route>
      {/* <Route
        path="/edit-class/:id/add-student"
        element={<MasterLayoutPortal />}
      >
        <Route index element={<AddStudentToClass />} />
      </Route> */}
      <Route
        path="/edit-class/:id/assign-book"
        element={<MasterLayoutPortal />}
      >
        <Route index element={<AssignBook />} />
      </Route>
    </Routes>
  );
};

export default ClassManagementRoutes;
