import React from 'react';

export const LoginIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 11.5781L9.07812 9L6.5 6.44531V7.6875C6.5 8.01562 6.24219 8.25 5.9375 8.25H3.125V9.75H5.9375C6.24219 9.75 6.5 10.0078 6.5 10.3125V11.5781ZM10.25 9C10.25 9.28125 10.1328 9.53906 9.94531 9.72656L7.22656 12.4453C7.01562 12.6562 6.73438 12.75 6.45312 12.75C5.84375 12.75 5.375 12.2812 5.375 11.6719V10.875H3.125C2.49219 10.875 2 10.3828 2 9.75V8.25C2 7.64062 2.49219 7.125 3.125 7.125H5.375V6.35156C5.375 5.74219 5.84375 5.25 6.45312 5.25C6.73438 5.25 7.01562 5.36719 7.22656 5.57812L9.94531 8.29688C10.1328 8.48438 10.25 8.74219 10.25 9ZM10.0625 13.125H11.9375C12.4531 13.125 12.875 12.7266 12.875 12.1875V5.8125C12.875 5.29688 12.4531 4.875 11.9375 4.875H10.0625C9.73438 4.875 9.5 4.64062 9.5 4.3125C9.5 4.00781 9.73438 3.75 10.0625 3.75H11.9375C13.0625 3.75 14 4.6875 14 5.8125V12.1875C14 13.3359 13.0625 14.25 11.9375 14.25H10.0625C9.73438 14.25 9.5 14.0156 9.5 13.6875C9.5 13.3828 9.73438 13.125 10.0625 13.125Z"
        fill="#F3FAFA"
      />
    </svg>
  );
};
