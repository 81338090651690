import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BooksService from '@core/services/books.service';
import { DetailBookSlider } from '@shared/components/DetailBookSlider';
import { Loading } from '@shared/components/Loading';
import { jwtDecode } from 'jwt-decode';

const BookDetail = () => {
  const params = useParams();

  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const token = user?.data;
  const decodedPayload: any = jwtDecode(token);
  const studentID = decodedPayload?.student_id;

  const bookID = params?.id?.split('-')[1] || '';
  const typeBook = params?.id?.split('-')[0] || '';

  const [bookData, setBookData] = useState<any>();
  const [pageData, setPageData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currentPageRead, setCurrentPageRead] = useState<number>();

  useEffect(() => {
    setLoading(true);
    const getDetailBook = async (bookID: string) => {
      const res = await BooksService.getDetailBook(bookID);
      if (res) {
        setLoading(false);
        setBookData(res?.data);
        if (typeBook === 'listen') {
          setPageData(res?.data?.book_pages);
          if (res?.data?.current_page_count >= res?.data?.total_page_count) {
            setCurrentPageRead(0);
          } else {
            setCurrentPageRead(res?.data?.current_page_count);
          }
        } else {
          setPageData(res?.data?.book_cover);
          if (res?.data?.current_cover_count >= res?.data?.total_cover_count) {
            setCurrentPageRead(0);
          } else {
            setCurrentPageRead(res?.data?.current_cover_count);
          }
        }
      }
    };
    getDetailBook(bookID);
  }, [bookID, typeBook]);

  return (
    <div>
      <div className="container">
        {loading ? (
          <Loading />
        ) : (
          <DetailBookSlider
            title={bookData?.title}
            items={pageData}
            bookType={typeBook}
            studentID={studentID}
            currentPageRead={currentPageRead}
            isExpiredAssign={bookData?.is_expired_assign}
            bookData={bookData}
          />
        )}
      </div>
    </div>
  );
};

export default BookDetail;
