import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RouteProtection: React.FC<{ children: JSX.Element }> = ({
  children
}: {
  children: JSX.Element;
}) => {
  const location = useLocation();

  const user = localStorage.getItem('user');

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RouteProtection;
