import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderPortal from './HeaderPortal';
import FooterPortal from './FooterPortal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MasterLayoutPortal = () => {
  return (
    <div className="flex-1 font-Roboto flex flex-col">
      <HeaderPortal />
      <main
        className={`flex-1 mt-0 px-2 py-2 xl:px-8 xl:py-4 bg-[#D2E6F7] bg-opacity-20`}
      >
        <ToastContainer hideProgressBar={true} />
        <Outlet />
      </main>
      <FooterPortal />
    </div>
  );
};

export default MasterLayoutPortal;
