import { Button } from '@shared/components/button/Button';
import { SubNav } from '@shared/components/SubNav';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ChangePasswordForm } from 'types/data.type';
import { EyeClose, EyeOpen } from 'assets/icons/Index';
import AuthService from '@core/services/auth.service';

export const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState<any>([false, false, false]);

  const {
    register,
    handleSubmit,
    setError,
    trigger,
    formState: { errors }
  } = useForm<ChangePasswordForm>({
    defaultValues: {
      old_pass: '',
      new_pass: '',
      confirm_new_pass: ''
    },
    mode: 'onChange'
  });

  const inputStyle =
    'border border-yellow_2 border-solid p-[10px] text-base font-normal text-yellow_3 rounded w-[100%] lg:w-[50%] h-[42px] mt-1';
  const errorStyle = 'text-sm font-normal leading-5 mt-1 text-red';
  const successStyle = 'text-sm font-normal leading-5 mt-1 text-[#57F287]';

  const crums = [
    {
      name: 'Home',
      url: '/'
    },
    {
      name: 'Change Password',
      url: '/user-info/change-password',
      selected: true
    }
  ];

  const onSubmit = async (data: ChangePasswordForm) => {
    if (data?.new_pass !== data?.confirm_new_pass) {
      setError('confirm_new_pass', {
        message: 'New password and confirm new password must be the same'
      });
    } else if (data?.new_pass === data?.old_pass) {
      setError('new_pass', {
        message: 'New password must be different from current password'
      });
    } else {
      const params = {
        old_password: data?.old_pass,
        new_password: data?.confirm_new_pass
      };
      const res = await AuthService.changePassword(params);
      if (res) {
        if (res?.status === 1) {
          setError('old_pass', {
            type: 'success',
            message: 'Change password successfully.'
          });
        } else if (res?.code === 'EL-101') {
          setError('old_pass', {
            message: 'Current password is incorrect.'
          });
        } else {
          setError('old_pass', {
            message: 'Change password failed! Please try again.'
          });
        }
      }
    }
  };

  return (
    <>
      <SubNav title="Change Password" crums={crums} />
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-y-5 font-Roboto pb-12 mt-5">
            <div className="flex flex-col relative">
              <label
                htmlFor="old_pass"
                className="text-yellow_3 text-base font-normal leading-[22px] mb-1"
              >
                Current password *
              </label>
              <input
                type={showPassword[0] ? 'text' : 'password'}
                className={`${inputStyle} outline-none`}
                maxLength={8}
                {...register('old_pass', {
                  required: 'Please enter current password',
                  onChange: () => {
                    trigger('old_pass');
                  }
                })}
              />
              <div
                className="absolute w-[30px] h-fit top-[36px] right-[4%] md:right-[2%] lg:right-[51%] cursor-pointer"
                onClick={() => {
                  const newArray = [...showPassword];
                  newArray[0] = !newArray[0];
                  setShowPassword(newArray);
                }}
              >
                {showPassword[0] ? <EyeOpen /> : <EyeClose />}
              </div>
              {errors.old_pass?.type === 'success' ? (
                <p
                  className={`${successStyle} lg:text-sm lg:font-medium lg:leading-6`}
                >
                  {errors.old_pass?.message}
                </p>
              ) : (
                <p
                  className={`${errorStyle} lg:text-sm lg:font-medium lg:leading-6`}
                >
                  {errors.old_pass?.message}
                </p>
              )}
            </div>
            <div className="flex flex-col relative">
              <label
                htmlFor="new_pass"
                className="text-yellow_3 text-base font-normal leading-[22px] mb-1"
              >
                New password *
              </label>
              <input
                type={showPassword[1] ? 'text' : 'password'}
                className={`${inputStyle} outline-none`}
                maxLength={8}
                {...register('new_pass', {
                  required: 'Please enter your new password',
                  onChange: () => {
                    trigger('new_pass');
                  }
                })}
              />
              <div
                className="absolute w-[30px] h-fit top-[36px] right-[4%] md:right-[2%] lg:right-[51%] cursor-pointer"
                onClick={() => {
                  const newArray = [...showPassword];
                  newArray[1] = !newArray[1];
                  setShowPassword(newArray);
                }}
              >
                {showPassword[1] ? <EyeOpen /> : <EyeClose />}
              </div>
              {errors.new_pass && (
                <p
                  className={`${errorStyle} lg:text-sm lg:font-medium lg:leading-6`}
                >
                  {errors.new_pass?.message}
                </p>
              )}
            </div>
            <div className="flex flex-col relative">
              <label
                htmlFor="confirm_new_pass"
                className="text-yellow_3 text-base font-normal leading-[22px] mb-1"
              >
                Confirm new password *
              </label>
              <input
                type={showPassword[2] ? 'text' : 'password'}
                maxLength={8}
                className={`${inputStyle} outline-none`}
                {...register('confirm_new_pass', {
                  required: 'Please enter your new password again to confirm',
                  onChange: () => {
                    trigger('confirm_new_pass');
                  }
                })}
              />
              <div
                className="absolute w-[30px] h-fit top-[36px] right-[4%] md:right-[2%] lg:right-[51%] cursor-pointer"
                onClick={() => {
                  const newArray = [...showPassword];
                  newArray[2] = !newArray[2];
                  setShowPassword(newArray);
                }}
              >
                {showPassword[2] ? <EyeOpen /> : <EyeClose />}
              </div>
              {errors.confirm_new_pass && (
                <p
                  className={`${errorStyle} lg:text-sm lg:font-medium lg:leading-6`}
                >
                  {errors.confirm_new_pass?.message}
                </p>
              )}
            </div>
            <div className="mt-5">
              <Button
                type="submit"
                className="font-primary !w-1/2 !h-10 text-lg font-bold leading-[18px] text-[#fff] !rounded !bg-blue_2 border-bottom-button hover:!bg-red transition-all"
              >
                Change Password
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
