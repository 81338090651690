import React from 'react';

export const ClassroomIcon = () => {
  return (
    <svg
      fill="#000000"
      height="24px"
      width="24px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 418.088 418.088"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path
          id="XMLID_718_"
          d="M267.539,225.169c-25.875,5.066-40.173,3.353-46.353-5.554c-7.97-11.485,2.206-28.049,12.046-44.067 c3.813-6.207,9.972-16.231,10.673-20.455c-5.316-0.803-20.918,1.309-30.548,2.613c-29.523,4-45.133,5.612-49.392-4.576 c-3.471-8.299,4.406-16.63,17.3-26.586c26.606-20.544,28.63-28.606,28.771-29.961c-1.128-0.79-8.93-4.913-41.283,1.495 c-22.746,4.503-50.321,12.902-73.764,22.466c-3.584,1.462-7.666-0.258-9.126-3.837c-1.46-3.58,0.258-7.666,3.837-9.126 c24.997-10.198,54.239-19.021,78.223-23.604c41.252-7.882,50.776-0.541,54.351,5.667c6.319,10.975-3.689,25.772-32.452,47.982 c-5.523,4.265-8.661,7.217-10.438,9.157c7.481,0.386,22.431-1.642,32.094-2.95c22.618-3.063,34.715-4.47,40.981-0.312 c4.107,2.727,8.027,8.404,3.372,19.975c-2.343,5.824-6.388,12.408-10.67,19.38c-4.115,6.698-8.37,13.624-10.833,19.527 c-2.229,5.346-2.277,8.313-1.641,9.23c0.151,0.22,4.05,5.3,32.159-0.203c18.665-3.659,31.043-2.721,37.837,2.871 c3.61,2.971,5.519,7.107,5.519,11.963c0,3.866-3.134,7-7,7s-7-3.134-7-7c0-0.812-0.184-0.963-0.415-1.153 C293.277,224.69,288.201,221.12,267.539,225.169z M351.712,294.42c-3.866,0-7,3.134-7,7s3.134,7,7,7h23.866c3.866,0,7-3.134,7-7 V40.901c0-3.866-3.134-7-7-7H42.508c-3.866,0-7,3.134-7,7V301.42c0,3.866,3.134,7,7,7h206.185c3.866,0,7-3.134,7-7s-3.134-7-7-7 H49.508V47.901h319.07V294.42H351.712z M332.457,279.287v121.937c0,9.299-7.565,16.864-16.865,16.864h-30.781 c-9.3,0-16.865-7.565-16.865-16.864V279.287c0-14.74,11.991-26.731,26.731-26.731h11.051 C320.466,252.556,332.457,264.547,332.457,279.287z M318.457,279.287c0-7.021-5.711-12.731-12.729-12.731h-11.051 c-7.021,0-12.731,5.711-12.731,12.731v121.937c0,1.553,1.313,2.864,2.865,2.864h30.781c1.553,0,2.865-1.312,2.865-2.864V279.287z M411.088,0H7C3.134,0,0,3.134,0,7v328.322c0,3.866,3.134,7,7,7h241.692c3.866,0,7-3.134,7-7s-3.134-7-7-7H14V14h390.088v314.322 h-52.376c-3.866,0-7,3.134-7,7s3.134,7,7,7h59.376c3.866,0,7-3.134,7-7V7C418.088,3.134,414.954,0,411.088,0z"
        ></path>{' '}
      </g>
    </svg>
  );
};