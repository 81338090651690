import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../button/Button';
import { toast } from 'react-toastify';
import BooksManagementService from '@core/services/books.portal.service';
import { CircleSpin } from 'assets/icons/CircleSpin';
import { PortalButton } from '../button/PortalButton';

type BookPageListenDetailProps = {
  bookPage: any;
  bookId: string;
};

const BookPageListenDetail = ({
  bookPage,
  bookId
}: BookPageListenDetailProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors }
  } = useForm<any>({});

  const imgUrlWatch = watch('img_url');
  const [loading, setLoading] = useState<any>(true);

  useEffect(() => {
    setLoading(true);
    if (bookPage) {
      setValue('audio1_url', bookPage.audio1_url);
      setValue('audio2_url', bookPage.audio2_url);
      setValue('audio3_url', bookPage.audio3_url);
      setValue('img_url', bookPage.img_url);
    }
    setLoading(false);
  }, [bookPage]);

  const submitHandler = async (data: any) => {
    try {
      const params = {
        book_type: 'Listen',
        book_page_detail: {
          id: bookPage?.id,
          img_url: data.img_url,
          audio1_url: data.audio1_url,
          audio2_url: data.audio2_url,
          audio3_url: data.audio3_url,
          content: '',
          has_sound: false
        }
      };
      const response = await BooksManagementService.updateBookPage(
        bookId,
        params
      );

      if (response?.code === 'Ok') {
        toast.success('Update Success!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      } else {
        toast.error(response?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      }
    } catch (error) {
      toast.error('Update Fail! Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
    }
  };
  return (
    <>
      {!loading && (
        <form
          className="form"
          onSubmit={handleSubmit(submitHandler)}
          noValidate
          autoComplete="off"
        >
          <h1 className="py-3 font-medium">Book Page Detail</h1>
          <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
            <label htmlFor="type" className={``}>
              Audio Url 1
            </label>
            <input
              id="audio1_url"
              type="text"
              required
              placeholder="Image Url"
              className="form-control"
              {...register('audio1_url', {
                required: 'Audio Url 1 is required',
                setValueAs: (value: string) => value?.trim(),
                onChange: () => {
                  trigger('audio1_url');
                }
              })}
            />
            {errors?.audio1_url?.message && (
              <span className="text-redCustom-3b text-xs">
                {errors?.title?.message as string}
              </span>
            )}
          </div>
          <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
            <label htmlFor="type" className={``}>
              Audio Url 2
            </label>
            <input
              id="audio2_url"
              type="text"
              required
              placeholder="Image Url"
              className="form-control"
              {...register('audio2_url', {
                setValueAs: (value: string) => value?.trim(),
                onChange: () => {
                  trigger('audio2_url');
                }
              })}
            />
            {errors?.audio2_url?.message && (
              <span className="text-redCustom-3b text-xs">
                {errors?.title?.message as string}
              </span>
            )}
          </div>
          <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
            <label htmlFor="type" className={``}>
              Audio Url 3
            </label>
            <input
              id="audio3_url"
              type="text"
              required
              placeholder="Image Url"
              className="form-control"
              {...register('audio3_url', {
                setValueAs: (value: string) => value?.trim(),
                onChange: () => {
                  trigger('audio3_url');
                }
              })}
            />
            {errors?.audio3_url?.message && (
              <span className="text-redCustom-3b text-xs">
                {errors?.title?.message as string}
              </span>
            )}
          </div>
          <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
            <label htmlFor="img_url" className={``}>
              Image Url
            </label>
            <input
              id="img_url"
              type="text"
              required
              placeholder="Image Url"
              className="form-control"
              {...register('img_url', {
                required: 'Image Url is required',
                setValueAs: (value: string) => value?.trim(),
                onChange: () => {
                  trigger('img_url');
                }
              })}
            />
            {errors?.img_url?.message && (
              <span className="text-redCustom-3b text-xs">
                {errors?.title?.message as string}
              </span>
            )}
          </div>
          {imgUrlWatch && (
            <div className="mt-3">
              <img
                src={imgUrlWatch}
                alt="ImageUrl"
                className="w-[30vw] h-auto"
              />
            </div>
          )}

          <PortalButton type="submit" className="min-w-[140px]">
            Update
          </PortalButton>
        </form>
      )}
      {loading && (
        <>
          <div className="flex justify-center mt-4 items-center tex-sm">
            <CircleSpin color="text-primary-e2" /> Loading...
          </div>
        </>
      )}
    </>
  );
};

export default BookPageListenDetail;
