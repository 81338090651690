import { getAllNewsType, readNewsType } from 'types/data.type';
import axiosInstance from './restful.service';
import { HOST_URL, NEWS } from '@core/constant/api.contant';

const getAllNews = (params: getAllNewsType) => {
  return axiosInstance
    .get(HOST_URL + NEWS.GET_ALL, { params })
    .then(response => {
      return response?.data;
    });
};

const getAllNewsHome = (params: getAllNewsType) => {
  return axiosInstance
    .get(HOST_URL + NEWS.GET_ALL_HOME, { params })
    .then(response => {
      return response?.data;
    });
};

const getDetailNews = (id: string) => {
  return axiosInstance
    .get(HOST_URL + NEWS.GET_NEWS_BY_ID(id))
    .then(response => {
      return response?.data;
    });
};

const getDetailNewsHome = (id: string) => {
  const params = {
    id: id
  };

  return axiosInstance
    .get(HOST_URL + NEWS.GET_NEWS_BY_ID_HOME, { params })
    .then(response => {
      return response?.data;
    });
};

const postReadNews = (params: readNewsType) => {
  return axiosInstance
    .post(HOST_URL + NEWS.READ_NEWS, params)
    .then(response => {
      return response?.data;
    });
};

const NewsService = {
  getAllNews,
  getDetailNews,
  postReadNews,
  getDetailNewsHome,
  getAllNewsHome
};

export default NewsService;
