import React from 'react';
import styles from './index.module.scss';
import SideBar from '../SideBar';

type ContainerLayoutProps = {
  children: React.ReactNode;
};

const ContainerLayout = ({ children }: ContainerLayoutProps) => {
  return (
    <div className="flex min-w-0 min-h-screen relative">
      <div className="lg:flex flex-col flex-none w-[17.5rem]">
        <div className={`${styles['masterlayoutside-navbar']} flex-grow`}>
          <SideBar />
        </div>
      </div>
      <div className="flex flex-1">{children}</div>
    </div>
  );
};

export default ContainerLayout;
