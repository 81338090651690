import { BoxContainer, Button } from '@shared/components/Index';
import { SubNav } from '@shared/components/SubNav';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ContactFrom } from 'types/data.type';

const Contact = () => {
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm<ContactFrom>({
    defaultValues: {
      name: '',
      email: '',
      message: ''
    },
    mode: 'onChange'
  });

  // const onSubmit = async (data: ContactFrom) => {
  //   console.log(data);
  // };

  // const inputStyle =
  //   'border border-yellow_2 border-solid p-[10px] text-base font-normal text-yellow_3 rounded w-[100%] lg:w-[50%] h-[42px] mt-1';
  // const errorStyle = 'text-red-500 text-sm font-normal leading-5 mt-1 text-red';

  const crums = [
    {
      name: 'Home',
      url: '/'
    },
    {
      name: 'Contact',
      url: '/contact',
      selected: true
    }
  ];

  return (
    <>
      <SubNav title="Contact" crums={crums} />
      <div className="container">
        <BoxContainer className="mt-[30px]">
          <iframe
            title="QUANG MINH LOCATION"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.135820909895!2d106.62081221047323!3d10.800907958697518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752bfc78353d29%3A0x85b7285780db6631!2zMzQxIFTDom4gS-G7syBUw6JuIFF1w70sIFTDom4gUXXDvSwgVMOibiBQaMO6LCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1699763448541!5m2!1svi!2s"
            width="1200"
            height="470"
            className="border:0;"
            loading="lazy"
          ></iframe>
        </BoxContainer>
        <div className="mt-[45px] flex flex-col lg:flex-row gap-[30px]">
          {/* <div className="p-[15px] lg:w-[75%]">
            <h1 className="text-[32px] font-bold leading-[1.25] text-blue_2">
              About Our Company
            </h1>
            <p className="mt-4 text-base font-normal leading-[22px] text-yellow_3 font-Roboto">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magn aliquam erat
              volutpat. Duis autem vel eum iriure dolor in hendrerit in
              vulputate velit esse molestie consequat, vel illum dolore eu
              feugiat nulla facilisis at vero eros et accumsa et iusto odio
              dignissim qui blandit praesent luptatum zzril delenit augue duis
              dolore te feugait nulla facilisi.
            </p>
            <div className="mt-5">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-y-[10px] font-Roboto pb-12">
                  <div className="flex flex-col">
                    <input
                      type="text"
                      placeholder="Name"
                      className={`${inputStyle} outline-none`}
                      {...register('name', {
                        required: 'Please enter your name',
                        onChange: () => {
                          trigger('name');
                        }
                      })}
                    />
                    {errors.name && (
                      <p
                        className={`${errorStyle} lg:text-sm lg:font-medium lg:leading-6`}
                      >
                        {errors.name?.message}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <input
                      type="text"
                      className={`${inputStyle} outline-none`}
                      placeholder="E-mail"
                      {...register('email', {
                        required: 'Please enter your email',
                        onChange: () => {
                          trigger('email');
                        }
                      })}
                    />
                    {errors.email && (
                      <p
                        className={`${errorStyle} lg:text-sm lg:font-medium lg:leading-6`}
                      >
                        {errors.email?.message}
                      </p>
                    )}
                  </div>
                  <textarea
                    cols={6}
                    rows={6}
                    className={`${inputStyle} outline-none !h-auto !w-full`}
                    placeholder="Message"
                  ></textarea>
                  <div>
                    <Button className="float-right font-primary !w-[200px] !h-10 text-lg font-bold leading-[18px] text-[#fff] !rounded !bg-blue_2 border-bottom-button hover:!bg-red transition-all">
                      Send
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div> */}
          <div className="p-[15px]">
            <h1 className="text-[32px] font-bold leading-[1.25] text-blue_2">
              Contact Details
            </h1>
            <div className="flex flex-col gap-5 mt-4 text-base leading-[22px] text-yellow_3 font-Roboto">
              <p className="font-normal">
                Quang Minh Foreign Language Center 341 Tan Ky Tan Quy, Tan Quy
                ward, Tan Phu district, Ho Chi Minh City
              </p>
              <p className="font-bold">
                Phone: 0987 008 608 (Mr Hung) - 096 77 33 703 (Ms Phuong)
              </p>
              {/* <p className="font-bold">
                Support:{' '}
                <a href="mailto:support@support.com" className="text-blue_3">
                  support@support.com
                </a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
