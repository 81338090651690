import BooksManagementService from '@core/services/books.portal.service';
import { ArticleCard } from '@shared/components/ArticleCard';
import Breadcrumb from '@shared/components/breadcrumb/Breadcrumb';
import TitlePage from '@shared/components/titlePage/TitlePage';
import { CircleSpin } from 'assets/icons/CircleSpin';
import { ClassroomIcon } from 'assets/icons/ClassroomIcon';
import React, { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { BiShow } from 'react-icons/bi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { bookRes, category } from 'types/data.type';
import DatePicker from 'react-datepicker';
import Modal from '@shared/components/Modal';
import { toast } from 'react-toastify';
import StudentManagementService from '@core/services/student.portal.service';
import ClassManagementService from '@core/services/class.portal.service';
import moment from 'moment';
import { LevelBox } from '@shared/components/LevelBox';
import BooksService from '@core/services/books.service';
import { AccoutType } from 'types/Auth.type';
import {
  checkComingSoon,
  countComingSoonDays,
  countRemainingDays,
  getAssignStatus,
  processReadOrListen
} from '@core/hooks/common';
import { PortalButton } from '@shared/components/button/PortalButton';

const titleTableStyle = 'text-lg font-bold text-center';

const AssignBook = () => {
  const _paramsURL = useParams();
  const [paramsURL, setParamsURL] = useState({ ..._paramsURL });
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>();
  const [reloadPage, setReloadPage] = useState<any>();
  const [allBooksLoading, setAllBooksLoading] = useState(true);
  const [allBooksAssignLoading, setAllBooksAssignLoading] = useState(false);
  const [booksData, setBooksData] = useState<bookRes>();
  const [listBooksAssign, setListBooksAssign] = useState<any>([]);
  const [listIDBooksAssign, setListIDBooksAssign] = useState<any>([]);
  const [fromDate, setFromDate] = useState<string>();
  const [toDate, setToDate] = useState<string>();
  const [isShowEditBookAssignModal, setIsShowEditBookAssignModal] =
    useState(false);
  const [submitAssign, setSubmitAssign] = useState(false);
  const [studentInClassLoading, setStudentInClassLoading] = useState(true);
  const [classStudentData, setClassStudentData] = useState<any>([]);
  const [bookEdit, setBookEdit] = useState<any>({});
  const [listStudentID, setListStudentID] = useState<any>([]);
  const [studentIDView, setStudentIDView] = useState('');
  const [isShowAssignBookModal, setIsShowAssignBookModal] = useState(false);
  const [listCategory, setListCategory] = useState<category>();
  const [level, setLevel] = useState<any>();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isShowListBookAssign, setIsShowListBookAssign] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [submitDelete, setSubmitDelete] = useState(false);
  const [bookAssignID, setBookAssignID] = useState('');

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const [crumbs] = useState([
    {
      name: 'Class Management',
      url: `/portal/class-management`
    },
    {
      name: `${paramsURL.id ? 'Classroom Adjustment' : 'Create Classroom'}`,
      url: `${
        paramsURL.id
          ? `/portal/class-management/${paramsURL.id}`
          : '/portal/class-management'
      }`
    }
  ]);

  const getBooksData = async (params: any) => {
    setAllBooksLoading(true);
    const res = await BooksManagementService.getAllBooks(params);

    if (res?.status === 1) {
      setBooksData(res?.data);
    }
    setAllBooksLoading(false);
  };

  const getStudent = async (id: string) => {
    setStudentInClassLoading(true);
    const params = {
      class_id: id,
      page_index: 1,
      page_size: 100
    };

    const res = await ClassManagementService.getStudentInClass(params, id);
    if (res?.status === 1) {
      const student_premium = res?.data?.items.filter(
        (item: any) => item?.type === AccoutType.PREMIUM
      );
      setClassStudentData(student_premium);
    }
    setStudentInClassLoading(false);
  };

  // const onSearch = (reset?: boolean) => {
  //   navigate(`/portal/class-management/edit-class/${paramsURL.id}/assign-book`);
  //   const newKeywork = reset ? '' : keyword?.trim();
  //   setKeyword(newKeywork);
  //   setReloadPage(!reloadPage);
  // };

  // const _handleKeyDown = (e: any) => {
  //   if (e.key === 'Enter') {
  //     setKeyword(e.target.value);
  //     onSearch();
  //   }
  // };

  useEffect(() => {
    let params = {
      level: '',
      page_index: pageIndex,
      page_size: pageSize
    };
    if (level) {
      params = {
        ...params,
        level: level
      };
    }

    getBooksData(params);
  }, [level, reloadPage, pageIndex]);

  useEffect(() => {
    const fetchDataCategory = async () => {
      const resCategory = await BooksService.getListCategoryPortal();
      if (resCategory) {
        setListCategory(resCategory?.data);
      }
    };

    fetchDataCategory();
  }, []);

  const getBooksAssignToStudent = async (id: string) => {
    setAllBooksAssignLoading(true);
    const res = await StudentManagementService.getBooksAssignToStudent(id);
    if (res?.status === 1) {
      setListBooksAssign(res.data.items);
    }
    setAllBooksAssignLoading(false);
  };

  const handleSubmitEditBookAssign = async () => {
    setSubmitAssign(true);
    const params = {
      student_ids: [studentIDView],
      book_ids: [bookEdit?.id],
      from_date: moment(fromDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      to_date: moment(toDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    };
    handleAssignStudentBook(params);
  };

  const handleSubmitAssignBooks = async () => {
    setSubmitAssign(true);
    if (listIDBooksAssign.length === 0) {
      setSubmitAssign(false);
      toast.error('Please choose book assign!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
      return;
    } else if (!fromDate || !toDate) {
      setSubmitAssign(false);
      toast.error('Please choose date assign!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
      return;
    } else {
      const params = {
        book_ids: listIDBooksAssign,
        student_ids: listStudentID,
        from_date: moment(fromDate)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        to_date: moment(toDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
      };
      handleAssignStudentBook(params);
      setTimeout(() => {
        setListIDBooksAssign([]);
        setFromDate('');
        setToDate('');
      }, 1500);
    }
  };

  const handleAssignStudentBook = async (params: any) => {
    const res = await StudentManagementService.assignStudentBook(params);
    if (res?.status === 1) {
      toast.success('Edit Success!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
    } else {
      toast.error('Edit Fail! Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
    }
    setSubmitAssign(false);
    setIsShowEditBookAssignModal(false);
    setIsShowAssignBookModal(false);
    getBooksAssignToStudent(studentIDView);
  };

  const handleViewBooksAssign = (id: string) => {
    setStudentIDView(id);
    setIsShowListBookAssign(true);
    getBooksAssignToStudent(id);
  };

  const handleEditBookAssign = (book: any) => {
    const _fromDate = new Date(book?.from_date);

    const _toDate = new Date(book?.to_date);
    setIsShowEditBookAssignModal(true);
    setBookEdit(book);
    setFromDate(_fromDate as any);
    setToDate(_toDate as any);
  };

  const handleCheckStudent = (id: string) => {
    const _listStudentID = [...listStudentID];
    if (_listStudentID.includes(id)) {
      const index = _listStudentID.indexOf(id);
      _listStudentID.splice(index, 1);
    } else {
      _listStudentID.push(id);
    }
    setListStudentID(_listStudentID);
  };

  const handleCheckAllStudent = () => {
    const _listStudentID = [...listStudentID];
    if (_listStudentID.length === classStudentData.length) {
      setListStudentID([]);
    } else {
      const _listStudentId = classStudentData.map((item: any) => item?.id);
      setListStudentID(_listStudentId);
    }
  };

  const handleViewAssignBookModal = () => {
    if (listStudentID.length === 0) {
      toast.error('Please choose student to assign book!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
    } else {
      setIsShowAssignBookModal(true);
      setFromDate('');
      setToDate('');
      setKeyword('');
    }
  };

  const handleCheckBook = (id: string) => {
    const _listIDBooksAssign = [...listIDBooksAssign];
    if (_listIDBooksAssign.includes(id)) {
      const index = _listIDBooksAssign.indexOf(id);
      _listIDBooksAssign.splice(index, 1);
    } else {
      _listIDBooksAssign.push(id);
    }
    setListIDBooksAssign(_listIDBooksAssign);
  };

  const handleRemoveBookAssign = async (
    book_assign_id: string,
    student_id: string
  ) => {
    setSubmitDelete(true);
    const res = await StudentManagementService.deleteBookAssignToStudent(
      student_id,
      book_assign_id
    );

    if (res?.code === 'Ok') {
      toast.success('Delete Success!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
    } else {
      toast.error(res?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
    }
    setSubmitDelete(false);
    setConfirmDeleteModal(false);
    setBookAssignID('');
    getBooksAssignToStudent(studentIDView);
  };

  useEffect(() => {
    getStudent(paramsURL.id as string);
  }, [paramsURL.id]);

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage
        icon={() => <ClassroomIcon />}
        name={`${paramsURL.id ? 'Classroom Adjustment' : 'Create Classroom'}`}
      />
      <div className="mt-10">
        {paramsURL.id && (
          <div className="flex">
            <div className="p-[10px] border-b border-gray-e0">
              <Link to={`/portal/class-management/edit-class/${paramsURL.id}`}>
                Class
              </Link>
            </div>
            {/* <div className="p-[10px] border-b border-gray-e0">
              <Link
                to={`/portal/class-management/edit-class/${paramsURL.id}/add-student`}
              >
                Học Sinh
              </Link>
            </div> */}
            <div className="p-[10px] font-bold bg-white border-l border-r border-t-primary-main border-t-[3px] border-gray-e0 rounded-t-[4px]">
              <p>Book</p>
            </div>
          </div>
        )}
        <div className="form">
          <ArticleCard
            className={`${paramsURL.id && 'border-t-0 rounded-t-none'}`}
          >
            <>
              <div className="flex justify-end mb-4">
                <PortalButton
                  type="submit"
                  className="flex justify-center w-full md:w-52"
                  onClick={handleViewAssignBookModal}
                >
                  Assign Book
                </PortalButton>
              </div>
              <ArticleCard className="min-h-[340px]">
                <p className={titleTableStyle}>List student in class</p>
                <div className="flex gap-3 mt-5 pl-[10px]">
                  <input
                    id="checkAll"
                    type="checkbox"
                    className="w-5 h-5"
                    onChange={() => {
                      handleCheckAllStudent();
                    }}
                  />
                  <label htmlFor="checkAll" className="font-bold !text-sm">
                    Select all
                  </label>
                </div>
                <div>
                  {studentInClassLoading ? (
                    <div className="flex justify-center items-center">
                      <CircleSpin />
                    </div>
                  ) : (
                    <table className="table w-full rounded-lg scroll-table student-assign-table ">
                      <thead className="bg-gray-f2 border-b-2 border-gray-200">
                        <tr>
                          <th className="w-[60px]">Select</th>
                          <th scope="col">
                            <span>User Name</span>
                          </th>
                          <th scope="col" className="text-right">
                            <span>Full Name</span>
                          </th>
                          <th scope="col" className="text-right">
                            <span>Acount Type</span>
                          </th>
                          <th scope="col" className="text-center">
                            <span>View Books</span>
                          </th>
                        </tr>
                      </thead>

                      <>
                        <tbody>
                          {classStudentData?.map((item: any, index: number) => {
                            return (
                              <tr key={item?.id}>
                                <th className="w-[60px]">
                                  <div className="flex justify-center">
                                    <input
                                      type="checkbox"
                                      className="w-5 h-5"
                                      checked={listStudentID.includes(item?.id)}
                                      onChange={() => {
                                        handleCheckStudent(item?.id);
                                      }}
                                    />
                                  </div>
                                </th>
                                <th className="font-semibold overflow-x-scroll">
                                  <span>{item?.username}</span>
                                </th>
                                <td className="!text-right">
                                  <span>{item?.fullname}</span>
                                </td>
                                <td className="!text-right">
                                  <span>{item?.type || '-'}</span>
                                </td>
                                <td className="flex justify-center">
                                  <div
                                    className="table-action-btn table-action-edit text-base felx justify-center !p-2 !w-fit"
                                    onClick={() => {
                                      handleViewBooksAssign(item?.id);
                                    }}
                                  >
                                    <BiShow
                                      className={`text-2xl ${
                                        item?.id === studentIDView
                                          ? 'text-green-500'
                                          : 'text-gray-500'
                                      }`}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </>
                    </table>
                  )}
                </div>
              </ArticleCard>
            </>
          </ArticleCard>
          <Modal
            show={isShowEditBookAssignModal}
            title={''}
            onClose={() => {
              setIsShowEditBookAssignModal(false);
            }}
            className="max-w-[900px] font-Roboto"
          >
            <p className="text-center text-2xl font-bold">Edit Book Assign</p>
            <div className="flex flex-col gap-3 items-center mt-5">
              <img
                src={
                  bookEdit?.cover_listen_img
                    ? bookEdit?.cover_listen_img
                    : bookEdit?.cover_read_img
                }
                className="h-[300px] border border-solid border-gray-e0"
                alt=""
              />
            </div>
            <div className="flex gap-3 mt-5">
              <div className="w-1/2">
                <p>From the date</p>
                <DatePicker
                  selected={fromDate as any}
                  onChange={(date: any) => setFromDate(date)}
                  className="w-full form-control"
                />
              </div>
              <div className="w-1/2">
                <p>Until the day</p>
                <DatePicker
                  selected={toDate as any}
                  onChange={(date: any) => setToDate(date)}
                  className="w-full form-control"
                />
              </div>
            </div>
            <div className="flex justify-end space-x-4 mt-6">
              <PortalButton
                type="button"
                className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                onClick={() => {
                  setIsShowEditBookAssignModal(false);
                }}
              >
                Cancel
              </PortalButton>
              <PortalButton
                type="submit"
                className="flex justify-center w-full md:w-52"
                disabled={submitAssign}
                onClick={handleSubmitEditBookAssign}
              >
                {submitAssign && <CircleSpin />}
                Save
              </PortalButton>
            </div>
          </Modal>
          <Modal
            show={isShowAssignBookModal}
            title={''}
            onClose={() => {
              setIsShowAssignBookModal(false);
            }}
            className="max-w-[80vw] max-h-[90vh] font-Roboto relative"
          >
            <PortalButton
              type="button"
              className="flex justify-center !w-[140px] absolute right-6 top-3"
              onClick={() => {
                setPageIndex((pre: number) => {
                  return (pre = pre + 1);
                });
              }}
              disabled={pageIndex >= (booksData?.total_pages || 0)}
            >
              Next
            </PortalButton>
            <PortalButton
              type="button"
              className="flex justify-center !w-[140px]  absolute left-6 top-3"
              onClick={() => {
                setPageIndex((pre: number) => {
                  return (pre = pre - 1);
                });
              }}
              disabled={pageIndex === 1}
            >
              Back
            </PortalButton>
            <p className="text-center text-2xl font-bold">Assign Book</p>
            <ArticleCard className="mt-3">
              {/* <div className="mb-2">
                <p className={titleTableStyle}>Choose Book Assign</p>
              </div> */}
              {/* <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <SearchButton
                    onSearch={onSearch}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    _handleKeyDown={_handleKeyDown}
                    className="w-full"
                  />
                </div>
              </div> */}
              <div>
                <h1 className="text-base font-bold leading-10">
                  Search Books By Level
                </h1>
                <div className="flex gap-3 flex-wrap">
                  {listCategory?.level?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setLevel(item);
                        }}
                      >
                        <LevelBox
                          className={`${
                            level === item ? '!bg-yellow_2' : ''
                          } text-xs`}
                        >
                          {item}
                        </LevelBox>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-5">
                {allBooksLoading ? (
                  <div className="flex justify-center items-center">
                    <CircleSpin />
                  </div>
                ) : (
                  <table className="table w-full rounded-lg scroll-table table-assign-books-scroll">
                    <thead className="bg-gray-f2 border-b-2 border-gray-200">
                      <tr>
                        <th className="max-w-[50px]">STT</th>
                        <th className="text-center">Image</th>
                        <th className="text-center">
                          <span>Title</span>
                        </th>
                        <th className="text-center">
                          <span>Select</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {booksData?.items?.map((item: any, index: number) => {
                        return (
                          <tr key={item?.id}>
                            <th className="max-w-[50px]">
                              {index + 1 + (pageIndex - 1) * pageSize}
                            </th>
                            <td className="flex justify-center">
                              <img
                                src={
                                  item?.cover_listen_img
                                    ? item?.cover_listen_img
                                    : item?.cover_read_img
                                }
                                className="h-[100px]"
                                alt=""
                              />
                            </td>
                            <th className="font-semibold !text-center">
                              <span>{item?.title}</span>
                            </th>
                            <td className="!text-center">
                              <input
                                type="checkbox"
                                className="w-5 h-5"
                                checked={listIDBooksAssign.includes(item?.id)}
                                onChange={() => {
                                  handleCheckBook(item?.id);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </ArticleCard>
            <ArticleCard>
              {/* <div className="mb-2">
                <p className={titleTableStyle}>Reading Period</p>
              </div> */}
              <div className="flex gap-3">
                <div className="w-1/2">
                  <p>From the date</p>
                  <DatePicker
                    selected={fromDate as any}
                    onChange={(date: any) => setFromDate(date)}
                    className="w-full form-control"
                    dateFormat={'dd/MM/yyyy'}
                  />
                </div>
                <div className="w-1/2">
                  <p>Until the day</p>
                  <DatePicker
                    selected={toDate as any}
                    onChange={(date: any) => setToDate(date)}
                    className="w-full form-control"
                    dateFormat={'dd/MM/yyyy'}
                  />
                </div>
              </div>
            </ArticleCard>
            <div className="flex justify-end space-x-4 mt-6">
              <PortalButton
                type="button"
                className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                onClick={() => {
                  setIsShowAssignBookModal(false);
                }}
              >
                Cancel
              </PortalButton>
              <PortalButton
                type="submit"
                className="flex justify-center w-full md:w-52"
                disabled={submitAssign}
                onClick={handleSubmitAssignBooks}
              >
                {submitAssign && <CircleSpin />}
                Save
              </PortalButton>
            </div>
          </Modal>
          <Modal
            show={isShowListBookAssign}
            title={''}
            onClose={() => {
              setIsShowListBookAssign(false);
              setStudentIDView('');
            }}
            className="max-w-[1200px] font-Roboto"
          >
            <ArticleCard className="min-h-[340px]">
              <p className={titleTableStyle}>List book assign</p>
              <div className="mt-5">
                {allBooksAssignLoading ? (
                  <div className="flex justify-center items-center">
                    <CircleSpin />
                  </div>
                ) : (
                  <table className="table w-full rounded-lg scroll-table book-assign-table">
                    <thead className="bg-gray-f2 border-b-2 border-gray-200">
                      <tr>
                        <th className="w-[10%]">STT</th>
                        <th scope="col" className="text-center">
                          <span>Image</span>
                        </th>
                        <th scope="col">
                          <span>Title</span>
                        </th>
                        <th scope="col">
                          <span>Level</span>
                        </th>
                        <th scope="col">
                          <span>Remaining days</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span>Action</span>
                        </th>
                      </tr>
                    </thead>
                    <>
                      <tbody>
                        {listBooksAssign?.map((item: any, index: number) => {
                          return (
                            <tr key={item?.id}>
                              <th className="w-[10%]">{index + 1}</th>
                              <td className="flex justify-center">
                                <img
                                  src={
                                    item?.cover_listen_img
                                      ? item?.cover_listen_img
                                      : item?.cover_read_img
                                  }
                                  className="h-[150px]"
                                  alt=""
                                />
                              </td>
                              <td>
                                <span>{item?.title}</span>
                              </td>
                              <td>
                                <span>{item?.level}</span>
                              </td>
                              <td className="!text-center">
                                <div className="flex flex-col gap-1 text-sm">
                                  <span>{getAssignStatus(item)}</span>
                                  <span>
                                    {!checkComingSoon(item?.from_date)
                                      ? `${countRemainingDays(item?.to_date)}${
                                          countRemainingDays(item?.to_date) > 1
                                            ? ' days left'
                                            : ' day left'
                                        }`
                                      : `Opens in ${countComingSoonDays(
                                          item?.from_date
                                        )}${
                                          countComingSoonDays(item?.from_date) >
                                          1
                                            ? ' days'
                                            : ' day'
                                        }`}
                                  </span>
                                  {item?.is_read && (
                                    <span>
                                      Reading:{' '}
                                      {processReadOrListen(
                                        item?.current_cover_count,
                                        item?.total_cover_count
                                      )}
                                      %
                                    </span>
                                  )}
                                  {item?.is_listen && (
                                    <span>
                                      Listening:{' '}
                                      {processReadOrListen(
                                        item?.current_page_count,
                                        item?.total_page_count
                                      )}
                                      %
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="flex justify-center">
                                  <div
                                    className="table-action-btn table-action-edit w-fit !p-[10px] text-base"
                                    onClick={() => {
                                      setConfirmDeleteModal(true);
                                      setBookAssignID(item?.book_assign_id);
                                    }}
                                  >
                                    <AiOutlineDelete className="text-redCustom-3b" />
                                  </div>
                                  <div
                                    className="table-action-btn table-action-edit text-base felx justify-center !p-2 !w-fit"
                                    onClick={() => {
                                      handleEditBookAssign(item);
                                    }}
                                  >
                                    <AiOutlineEdit className="text-lg" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  </table>
                )}
              </div>
            </ArticleCard>
            <div className="flex justify-end space-x-4 mt-6">
              <PortalButton
                type="button"
                className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                onClick={() => {
                  setIsShowListBookAssign(false);
                  setStudentIDView('');
                }}
              >
                Cancel
              </PortalButton>
            </div>
          </Modal>
          <Modal
            show={confirmDeleteModal}
            title={'Confirm Delete'}
            onClose={() => {
              setConfirmDeleteModal(false);
            }}
            className="max-w-[420px] font-Roboto"
          >
            <div className="flex space-x-4">
              <PortalButton
                type="button"
                className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                onClick={() => {
                  setConfirmDeleteModal(false);
                }}
              >
                Cancel
              </PortalButton>
              <PortalButton
                type="submit"
                className="flex justify-center w-full md:w-52"
                disabled={submitDelete}
                onClick={() => {
                  handleRemoveBookAssign(bookAssignID, studentIDView);
                }}
              >
                {submitDelete && <CircleSpin />}
                Submit
              </PortalButton>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AssignBook;
