import MasterLayoutPortal from '@shared/layouts/MasterLayoutPortal';
import StudentManagement from 'portal-pages/studentManagement/StudentManagement';
import StudentManagementAdd from 'portal-pages/studentManagement/StudentManagementAdd';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const StudentManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayoutPortal />}>
        <Route index element={<StudentManagement />} />
      </Route>
      <Route path="/add-student" element={<MasterLayoutPortal />}>
        <Route index element={<StudentManagementAdd />} />
      </Route>
      <Route path="/edit-student/:id" element={<MasterLayoutPortal />}>
        <Route index element={<StudentManagementAdd />} />
      </Route>
      <Route
        path="/edit-student/:id/student-detail"
        element={<MasterLayoutPortal />}
      >
        <Route index element={<StudentManagementAdd />} />
      </Route>
    </Routes>
  );
};

export default StudentManagementRoutes;
