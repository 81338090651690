import React from 'react';
import MasterLayout from '@shared/layouts/MasterLayout';
import { HomePage } from 'pages/home/HomePage';
import { Route, Routes } from 'react-router-dom';

export const HomeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route index element={<HomePage />} />
      </Route>
    </Routes>
  );
};
