import { HOST_URL, HOST_URL_PORTAL } from '@core/constant/api.contant';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const axiosInstance = axios.create({
  baseURL: HOST_URL,
  headers: {
    Accept: 'application/json',
    'ngrok-skip-browser-warning': '69420',
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    let user: any = localStorage.getItem('user');
    // let user: any = sessionStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      const token = user.data;
      const auth = token ? `Bearer ${token}` : '';
      config.headers['Authorization'] = auth;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response?.use(
  response => response,
  error => {
    // Handle 401 errors here
    if (error?.response?.status === 401) {
      // Perform the action you want for unauthorized requests
      // For example, redirect to the login page or refresh the token
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
    // return Promise.reject(error);
  }
);

export default axiosInstance;