import { ArticleCard } from '@shared/components/ArticleCard';
import Breadcrumb from '@shared/components/breadcrumb/Breadcrumb';
import TitlePage from '@shared/components/titlePage/TitlePage';
import { UserIcon } from 'assets/icons/UserIcon';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Modal from '@shared/components/Modal';
import { CircleSpin } from 'assets/icons/CircleSpin';
import moment from 'moment';
import StudentManagementService from '@core/services/student.portal.service';
import StudentDetail from './StudentDetail';
import { PortalButton } from '@shared/components/button/PortalButton';

const OptionStyle = {
  form_group_plus: 'w-full',
  label_plus: 'font-semibold text-sm leading-5'
};

const StudentManagementAdd = () => {
  const _paramsURL = useParams();
  const [paramsURL, setParamsURL] = useState({ ..._paramsURL });
  const [status, setStatus] = useState<any>({
    value: true,
    label: 'Active'
  });
  const [dob, setDOB] = useState<any>();
  const [gender, setGender] = useState<any>({
    value: 'Female',
    label: 'Female'
  });
  const [type, setType] = useState<any>({
    value: 'Premium',
    label: 'Premium'
  });
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
  const [confirmAddModal, setConfirmAddModal] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [submitUpdate, setSubmitUpdate] = useState(false);
  const [detailStudent, setDetailStudent] = useState<any>();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const navigate = useNavigate();
  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors }
  } = useForm<any>({});
  const [crumbs] = useState([
    {
      name: 'Student Management',
      url: `/portal/student-management`
    },
    {
      name: `${paramsURL.id ? 'Edit Account' : 'Create Account'}`,
      url: `${
        paramsURL.id
          ? `/portal/student-management/${detailStudent?.id}`
          : '/portal/student-management'
      }`
    }
    // ${detailAccount?.id}
  ]);

  const getDetailStudent = async (id: string) => {
    setLoading(true);
    const res = await StudentManagementService.getDetailStudent(id);
    if (res?.status === 1) {
      setDetailStudent(res?.data);
      setValue('username', res?.data?.username);
      setValue('password', res?.data?.password);
      setValue('fullname', res?.data?.fullname);
      setValue('email', res?.data?.email);
      setValue('phone', res?.data?.phone);
      setValue('parent', res?.data?.parent);
      setValue('parent_phone', res?.data?.parent_phone);
      setValue('type', res?.data?.type);
      setDOB(new Date(res?.data?.dob));
      setStatus({
        value: res?.data?.is_active,
        label: res?.data?.is_active ? 'Active' : 'Inactive'
      });
      setGender({
        value: res?.data?.gender,
        label: res?.data?.gender
      });
      setType({
        value: res?.data?.type,
        label: res?.data?.type
      });
      setLoading(false);
    } else {
      toast.error('Get Detail Fail! Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue('is_active', status.value);
    setValue('gender', gender.value);
    setValue('type', type.value);
    if (paramsURL.id) {
      getDetailStudent(paramsURL.id);
    } else {
      setLoading(false);
    }
  }, []);

  const handleStatusChange = (status: any) => {
    setStatus(status);
    setValue('is_active', status.value);
  };

  const handleGenderChange = (status: any) => {
    setGender(status);
    setValue('gender', status.value);
  };

  const handleAcountTypeChange = (status: any) => {
    setType(status);
    setValue('type', status.value);
  };

  const submitHandler: SubmitHandler<any> = async value => {
    const dateOfBirth = moment(dob).format('YYYY-MM-DD');

    if (paramsURL.id) {
      const params = {
        ...value,
        id: paramsURL.id,
        dob: dateOfBirth
      };

      setSubmitUpdate(true);
      try {
        const res = await StudentManagementService.editStudent(params);
        if (res?.status === 1) {
          setSubmitUpdate(false);
          setConfirmUpdateModal(false);
          toast.success('Update Success!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          navigate(`/portal/student-management/edit-student/${paramsURL.id}`);
        } else {
          toast.error('Update Fail! Please try again.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          setSubmitUpdate(false);
          setConfirmUpdateModal(false);
        }
      } catch (error) {
        toast.error('Update Fail! Please try again.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
        setSubmitUpdate(false);
        setConfirmUpdateModal(false);
      }
    }

    if (!paramsURL.id) {
      const params = {
        ...value,
        dob: dateOfBirth
      };
      setSubmitAdd(true);
      try {
        const res = await StudentManagementService.addStudent(params);
        if (res?.status === 1) {
          setSubmitAdd(false);
          setConfirmAddModal(false);
          toast.success('Create Success!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          navigate(`/portal/student-management`);
        } else {
          toast.error('Create Fail! Please try again.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          setSubmitAdd(false);
          setConfirmAddModal(false);
        }
      } catch (error) {
        toast.error('Create Fail! Please try again.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
        setSubmitAdd(false);
        setConfirmAddModal(false);
      }
    }
  };

  const onError = (errors: any, e: any) => {
    if (errors) {
      for (const key in errors) {
        const messageError = `${errors[key].message}`;
        toast.error(messageError || 'Có lỗi, vui lòng kiểm tra lại!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      }
      setConfirmAddModal(false);
    }
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage
        icon={() => <UserIcon />}
        name={`${paramsURL.id ? 'Edit Account' : 'Create Account'}`}
      />
      <div className="mt-10">
        {paramsURL.id && (
          <div className="flex">
            <div className="">
              <p></p>
            </div>
            <Link
              to={`/portal/student-management/edit-student/${paramsURL.id}`}
              className={`${
                location?.pathname ===
                `/portal/student-management/edit-student/${paramsURL.id}`
                  ? 'p-[10px] font-bold bg-white border-l border-r border-t-primary-main border-t-[3px] border-gray-e0 rounded-t-[4px]'
                  : 'p-[10px]'
              }`}
            >
              Edit Student
            </Link>
            <div
              className={`${
                location?.pathname !==
                `/portal/student-management/edit-student/${paramsURL.id}`
                  ? 'p-[10px] font-bold bg-white border-l border-r border-t-primary-main border-t-[3px] border-gray-e0 rounded-t-[4px]'
                  : 'p-[10px]'
              }`}
            >
              <Link
                to={`/portal/student-management/edit-student/${paramsURL.id}/student-detail`}
              >
                Student Detail
              </Link>
            </div>
          </div>
        )}
      </div>
      <form
        className="form"
        onSubmit={handleSubmit(submitHandler, onError)}
        noValidate
        autoComplete="off"
      >
        <ArticleCard
          className={`${paramsURL.id && 'border-t-0 rounded-t-none'}`}
        >
          {loading && (
            <div className="flex justify-center items-center">
              <CircleSpin />
            </div>
          )}
          {location?.pathname ===
            `/portal/student-management/edit-student/${paramsURL.id}` ||
          location?.pathname === '/portal/student-management/add-student' ? (
            <>
              <div className="flex justify-between">
                <div className="form-group my-2 ml-[5px] mr-[23px] w-1/2">
                  <label
                    htmlFor="username"
                    className={`${OptionStyle.label_plus}`}
                  >
                    Username
                  </label>
                  <input
                    id="username"
                    type="text"
                    disabled={paramsURL.id ? true : false}
                    required
                    placeholder="Enter username"
                    className="form-control"
                    {...register('username', {
                      required: 'Username is required',
                      setValueAs: (value: string) => value?.trim(),
                      onChange: () => {
                        trigger('username');
                      }
                    })}
                    //   defaultValue={detailAccount?.username}
                  />
                  {errors?.username?.message && (
                    <span className="text-redCustom-3b text-xs">
                      {errors?.username?.message as string}
                    </span>
                  )}
                </div>
                <div className="form-group my-2 ml-[5px] mr-[23px] w-1/2">
                  <label
                    htmlFor="password"
                    className={`${OptionStyle.label_plus}`}
                  >
                    Password
                  </label>
                  <input
                    id="password"
                    type="text"
                    required
                    placeholder="Enter password"
                    className="form-control"
                    {...register('password', {
                      required: 'Password is required',
                      setValueAs: (value: string) => value?.trim(),
                      onChange: () => {
                        trigger('password');
                      }
                    })}
                    //   defaultValue={detailAccount?.password}
                  />
                  {errors?.password?.message && (
                    <span className="text-redCustom-3b text-xs">
                      {errors?.password?.message as string}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="form-group my-2 ml-[5px] mr-[23px] w-1/2">
                  <label
                    htmlFor="fullname"
                    className={`${OptionStyle.label_plus}`}
                  >
                    Full Name
                  </label>
                  <input
                    id="fullname"
                    type="text"
                    required
                    placeholder="Enter full name"
                    className="form-control"
                    {...register('fullname', {
                      required: 'Full name is required',
                      setValueAs: (value: string) => value?.trim(),
                      onChange: () => {
                        trigger('fullname');
                      }
                    })}
                    //   defaultValue={detailAccount?.fullname}
                  />
                  {errors?.fullname?.message && (
                    <span className="text-redCustom-3b text-xs">
                      {errors?.fullname?.message as string}
                    </span>
                  )}
                </div>
                <div className="form-group my-2 ml-[5px] mr-[23px] w-1/2">
                  <label
                    htmlFor="email"
                    className={`${OptionStyle.label_plus}`}
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    type="text"
                    required
                    placeholder="Enter email"
                    className="form-control"
                    {...register('email', {
                      required: 'Email is required',
                      setValueAs: (value: string) => value?.trim(),
                      onChange: () => {
                        trigger('email');
                      }
                    })}
                    //   defaultValue={detailAccount?.email}
                  />
                  {errors?.email?.message && (
                    <span className="text-redCustom-3b text-xs">
                      {errors?.email?.message as string}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="form-group my-2 ml-[5px] mr-[23px] w-1/2">
                  <label
                    htmlFor="phone"
                    className={`${OptionStyle.label_plus}`}
                  >
                    Phone Number
                  </label>
                  <input
                    id="phone"
                    type="number"
                    required
                    placeholder="Enter phone number"
                    className="form-control"
                    {...register('phone', {
                      required: 'Phone number is required',
                      setValueAs: (value: string) => value?.trim(),
                      onChange: () => {
                        trigger('phone');
                      }
                    })}
                    //   defaultValue={detailAccount?.phone}
                  />
                  {errors?.phone?.message && (
                    <span className="text-redCustom-3b text-xs">
                      {errors?.phone?.message as string}
                    </span>
                  )}
                </div>
                <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                  <label className={`${OptionStyle.label_plus}`} htmlFor="">
                    Gender
                  </label>
                  <Select
                    options={[
                      {
                        value: 'Male',
                        label: 'Male'
                      },
                      {
                        value: 'Female',
                        label: 'Female'
                      }
                    ]}
                    placeholder="Choose gender"
                    value={gender}
                    onChange={handleGenderChange}
                    className="form-select mt-1"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                  <label className={`${OptionStyle.label_plus}`} htmlFor="">
                    Status
                  </label>
                  <Select
                    options={[
                      {
                        value: true,
                        label: 'Active'
                      },
                      {
                        value: false,
                        label: 'Inactive'
                      }
                    ]}
                    placeholder="Choose status"
                    value={status}
                    onChange={handleStatusChange}
                    className="form-select mt-1"
                    classNamePrefix="select"
                  />
                  <span className="text-redCustom-3b text-xs">
                    {errors?.is_active?.message as string}
                  </span>
                </div>
                <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                  <label className={`${OptionStyle.label_plus}`} htmlFor="">
                    Acount Type
                  </label>
                  <Select
                    options={[
                      {
                        value: 'Premium',
                        label: 'Premium'
                      },
                      {
                        value: 'Basic',
                        label: 'Basic'
                      }
                    ]}
                    placeholder="Choose account type"
                    value={type}
                    onChange={handleAcountTypeChange}
                    className="form-select mt-1"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="form-group my-2 ml-[5px] mr-[23px] w-1/2">
                  <label
                    htmlFor="parent"
                    className={`${OptionStyle.label_plus}`}
                  >
                    Parent Name
                  </label>
                  <input
                    id="parent"
                    type="text"
                    required
                    placeholder="Enter Parent Name"
                    className="form-control"
                    {...register('parent', {
                      required: false,
                      setValueAs: (value: string) => value?.trim(),
                      onChange: () => {
                        trigger('parent');
                      }
                    })}
                  />
                  {errors?.parent?.message && (
                    <span className="text-redCustom-3b text-xs">
                      {errors?.parent?.message as string}
                    </span>
                  )}
                </div>
                <div className="form-group my-2 ml-[5px] mr-[23px] w-1/2">
                  <label
                    htmlFor="parentPhone"
                    className={`${OptionStyle.label_plus}`}
                  >
                    Parent Phone Number
                  </label>
                  <input
                    id="parentPhone"
                    type="tel"
                    required
                    placeholder="Enter Parent Phone Number"
                    className="form-control"
                    {...register('parent_phone', {
                      required: false,
                      setValueAs: (value: string) => value?.trim(),
                      onChange: () => {
                        trigger('parent_phone');
                      }
                    })}
                  />
                  {errors?.parent_phone?.message && (
                    <span className="text-redCustom-3b text-xs">
                      {errors?.parent_phone?.message as string}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group my-2 ml-[5px] mr-[23px] w-[calc(50%-28px)]">
                <label htmlFor="dob" className={`${OptionStyle.label_plus}`}>
                  Date Of Birth
                </label>
                <DatePicker
                  selected={dob}
                  onChange={date => setDOB(date)}
                  className="form-control"
                  placeholderText="Select date of birth"
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat={'dd/MM/YYY'}
                />
                {errors?.dob?.message && (
                  <span className="text-redCustom-3b text-xs">
                    {errors?.dob?.message as string}
                  </span>
                )}
              </div>
            </>
          ) : (
            <StudentDetail
              studentClass={detailStudent?.classes}
              studentID={paramsURL.id}
            />
          )}
        </ArticleCard>
        {location?.pathname !==
          `/portal/student-management/edit-student/${paramsURL.id}/student-detail` && (
          <PortalButton
            type="button"
            className="min-w-[140px]"
            onClick={() => {
              paramsURL.id
                ? setConfirmUpdateModal(true)
                : setConfirmAddModal(true);
            }}
          >
            {paramsURL.id ? 'Update' : 'Add'}
          </PortalButton>
        )}
        <Modal
          show={confirmAddModal}
          title={'Do you want to add this student?'}
          onClose={() => {
            setConfirmAddModal(false);
          }}
          className="max-w-[420px] font-Roboto"
        >
          <div className="flex space-x-4">
            <PortalButton
              type="button"
              className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
              onClick={() => {
                setConfirmAddModal(false);
              }}
            >
              Cancel
            </PortalButton>
            <PortalButton
              type="submit"
              className="flex justify-center w-full md:w-52"
              disabled={submitAdd}
              onClick={handleSubmit(submitHandler, onError)}
            >
              {submitAdd && <CircleSpin />}
              Submit
            </PortalButton>
          </div>
        </Modal>
        <Modal
          show={confirmUpdateModal}
          title={'Confirm Update'}
          onClose={() => {
            setConfirmUpdateModal(false);
          }}
          className="max-w-[420px] font-Roboto"
        >
          <div className="flex space-x-4">
            <PortalButton
              type="button"
              className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
              onClick={() => {
                setConfirmUpdateModal(false);
              }}
            >
              Cancel
            </PortalButton>
            <PortalButton
              type="submit"
              className="flex justify-center w-full md:w-52"
              disabled={submitUpdate}
              onClick={handleSubmit(submitHandler, onError)}
            >
              {submitUpdate && <CircleSpin />}
              Submit
            </PortalButton>
          </div>
        </Modal>
      </form>
    </>
  );
};

export default StudentManagementAdd;
