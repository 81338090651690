import ItemsPerPage from '@shared/components/ItemsPerPage';
import SearchButton from '@shared/components/SearchButton';
import Breadcrumb from '@shared/components/breadcrumb/Breadcrumb';
import TitlePage from '@shared/components/titlePage/TitlePage';
import { CircleSpin, UserIcon } from 'assets/icons/Index';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { bookRes } from 'types/data.type';
import moment from 'moment';
import { FORM_FORMAT } from '@core/constant/form.const';
import PaginationOwn from '@shared/components/Pagination';
import AuthService from '@core/services/auth.service';
import { AiOutlineEdit } from 'react-icons/ai';
import { PortalButton } from '@shared/components/button/PortalButton';

const AccountManagement = () => {
  const navigate = useNavigate();
  const [crumbs] = useState([
    { name: 'Account Management', url: '/portal/account-management' }
  ]);
  const [keyword, setKeyword] = useState<string>();
  const [reloadPage, setReloadPage] = useState<any>();
  const [itemPerPage, setItemPerPage] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [accountData, setAccountData] = useState<bookRes>();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const [status, setStatus] = useState<boolean>();

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const getData = async (params: any) => {
    setLoading(true);
    const res = await AuthService.getAccount(params);

    if (res?.status === 1) {
      setAccountData(res?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (page) {
      setCurrentPage(Number(page));
      let params = {
        search_key: '',
        is_active: null as any,
        page_index: Number(page),
        page_size: itemPerPage
      };
      if (keyword) {
        params = {
          ...params,
          search_key: keyword
        };
      }
      if (status) {
        params = {
          ...params,
          is_active: status
        };
      }

      getData(params);
    }
  }, [itemPerPage, reloadPage, page, status]);

  const onSearch = (reset?: boolean) => {
    navigate(`/portal/account-management`);
    const newKeywork = reset ? '' : keyword?.trim();
    setKeyword(newKeywork);
    setReloadPage(!reloadPage);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
      onSearch();
    }
  };

  const handlePageSizeChange = (value: any) => {
    navigate(`/portal/account-management`);
    setItemPerPage(value);
  };

  const onPageChange = useCallback(async (event: number) => {
    navigate(`/portal/account-management?page=${event}`);
  }, []);

  const handleStudentStatusChange = (value: any) => {
    setStatus(value);
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <div className="flex items-center justify-between">
        <TitlePage icon={() => <UserIcon />} name="Account Management" />
        <div className="flex">
          <div className="mr-3">
            <PortalButton url="/portal/account-management/add-account">
              Create Account
            </PortalButton>
          </div>
        </div>
      </div>
      <div className="mt-7">
        <div className="flex">
          <div className="flex w-1/2 space-x-2 items-center">
            <div className="mt-3">
              <SearchButton
                onSearch={onSearch}
                keyword={keyword}
                setKeyword={setKeyword}
                _handleKeyDown={_handleKeyDown}
              />
            </div>
            <div className="flex flex-col justify-between">
              <label
                htmlFor="account_status"
                className="text-sm font-semibold leading-5"
              >
                Account Status
              </label>
              <select
                name="account_status"
                className="w-[171px] h-10 border border-[#E5E5E5] rounded-2xl text-sm font-normal leading-5 px-[8px]"
                // value={authContext?.articleFilterParams?.article_type}
                onChange={value =>
                  handleStudentStatusChange(value?.target?.value)
                }
              >
                <option value="">---</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
          </div>
          <div className="ml-auto mt-5">
            <ItemsPerPage
              choice={itemPerPage}
              setChoice={(val: number) => {
                handlePageSizeChange(val);
              }}
            />
          </div>
        </div>
        <div className="w-full h-auto relative mt-2 overflow-auto rounded shadow">
          <table className="table w-full rounded-lg">
            <thead className="bg-gray-f2 border-b-2 border-gray-200">
              <tr>
                <th>STT</th>
                <th scope="col">
                  <span>ID</span>
                </th>
                <th scope="col">
                  <span>User Name</span>
                </th>
                <th scope="col">
                  <span>Full Name</span>
                </th>
                <th scope="col">
                  <span>Create Time</span>
                </th>
                <th scope="col">
                  <span>Role</span>
                </th>
                <th scope="col">
                  <span>Status</span>
                </th>
                <th scope="col">
                  <span>Action</span>
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {accountData?.items?.map((item: any, index: number) => {
                  return (
                    <tr key={item?.id}>
                      <th>{(currentPage - 1) * itemPerPage + index + 1}</th>
                      <th className="font-semibold" scope="row">
                        <span>{item?.id}</span>
                      </th>
                      <td className="order">
                        <span>{item?.username}</span>
                      </td>
                      <td>
                        <span>{item?.fullname}</span>
                      </td>
                      <td className="order">
                        <span>
                          {moment(new Date(item?.created_time)).format(
                            FORM_FORMAT.TABLE_DATEFULLYEAR
                          )}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`status status-${
                            item?.is_system_admin ? 'admin' : 'active'
                          }`}
                        >
                          {item?.is_system_admin ? 'Admin' : 'Teacher'}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`status status-${
                            item?.is_active ? 'active' : 'inactive'
                          }`}
                        >
                          {item?.is_active ? 'Active' : 'Inactive'}
                        </span>
                      </td>
                      <td>
                        <Link to={`edit-account/${item.id}`}>
                          <div className="table-action-btn table-action-edit w-fit">
                            <AiOutlineEdit />
                          </div>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        {accountData?.items?.length === 0 && !loading && (
          <p className="text-center text-sm mt-3">No account found</p>
        )}

        {accountData &&
          accountData?.total_results > itemPerPage &&
          !loading && (
            <div className="my-6 d-flex text-right">
              <PaginationOwn
                totalItems={accountData?.total_results}
                itemPerPage={itemPerPage}
                pageChange={onPageChange}
                pageCurrent={currentPage}
              />
            </div>
          )}
        {loading && (
          <>
            <div className="flex justify-center mt-4 items-center tex-sm">
              <CircleSpin color="text-primary-e2" /> Loading...
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AccountManagement;
