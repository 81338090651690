import MasterLayoutPortal from '@shared/layouts/MasterLayoutPortal';
import BookPages from 'portal-pages/booksManagement/BookPages';
import BooksManagement from 'portal-pages/booksManagement/BooksManagement';
import BooksManagementAdd from 'portal-pages/booksManagement/BooksManagementAdd';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const BooksManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayoutPortal />}>
        <Route index element={<BooksManagement />} />
      </Route>
      <Route path="/add-book" element={<MasterLayoutPortal />}>
        <Route index element={<BooksManagementAdd />} />
      </Route>
      <Route path="/edit-book/:id" element={<MasterLayoutPortal />}>
        <Route index element={<BooksManagementAdd />} />
      </Route>
      <Route path="/edit-book/:id/book-page" element={<MasterLayoutPortal />}>
        <Route index element={<BookPages />} />
      </Route>
    </Routes>
  );
};

export default BooksManagementRoutes;
