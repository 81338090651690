import React, { useState } from 'react';
import user_avatar from 'assets/imgs/user_avatar.png';
import { LogoutIcon } from 'assets/icons/LogoutIcon';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const HeaderPortal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const admin = localStorage.getItem('admin');
  const data_parse: any = admin ? jwtDecode(JSON.parse(admin)?.access_token) : null;
  const admin_name = data_parse?.[`http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name`]
  const admin_role = data_parse?.is_system_admin === 'true' ? 'Admin' : 'Teacher'

  const handleLogout = () => {
    localStorage.removeItem('admin');
    navigate(`/portal/login`);
  };

  return (
    <>
      <div
        className={` bg-white min-h-[50px] xl:min-h-[66px] px-8 flex items-center justify-between relative`}
      >
        <div>{/* <SideNavToggle /> */}</div>
        <div>
          <div
            onClick={() => setIsOpen(!isOpen)}
            className={`rounded-full w-[50px] h-[50px] cursor-pointer`}
          >
            <img src={user_avatar} alt="" className="rounded-full" />
          </div>
        </div>
        {isOpen && (
          <div className="rounded-md absolute right-7 top-[70px] min-w-[250px] font-Roboto shadow-lg">
            <div className="p-4 bg-[#8FC0EA] rounded-md shadow-md flex flex-col gap-4">
              <p>{admin_name}</p>
              <p className="text-xs font-bold">{admin_role}</p>
            </div>
            <div className="p-4 item rounded-sm mt-2">
              <div className="flex gap-3" onClick={handleLogout}>
                <LogoutIcon />
                <p>Thoát tài khoản</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderPortal;
