import BooksManagementService from '@core/services/books.portal.service';
import { ArticleCard } from '@shared/components/ArticleCard';
import Modal from '@shared/components/Modal';
import Breadcrumb from '@shared/components/breadcrumb/Breadcrumb';
import { PortalButton } from '@shared/components/button/PortalButton';
import TitlePage from '@shared/components/titlePage/TitlePage';
import { BookIcon } from 'assets/icons/BookIcon';
import { CircleSpin } from 'assets/icons/CircleSpin';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

const OptionStyle = {
  form_group_plus: 'w-full',
  label_plus: 'font-semibold text-sm leading-5'
};

const style = {
  tabActive:
    'font-bold bg-white border-l border-r border-t-primary-main border-t-[3px] border-gray-e0 rounded-t-[4px]'
};

const BooksManagementAdd = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors }
  } = useForm<any>({});
  const _paramsURL = useParams();
  const [paramsURL, setParamsURL] = useState({ ..._paramsURL });
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
  const [confirmAddModal, setConfirmAddModal] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [submitUpdate, setSubmitUpdate] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [level, setLevel] = useState<any>(null);
  const [type, setType] = useState<any>(null);
  const [genre, setGenre] = useState<any>(null);
  const [category, setCategory] = useState<any>(null);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [detailBook, setDetailBook] = useState<any>();
  const location = useLocation();

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const [crumbs] = useState([
    {
      name: 'Book Management',
      url: `/portal/books-management`
    },
    {
      name: `${paramsURL.id ? 'Editing Books' : 'Create Book'}`,
      url: `${
        paramsURL.id
          ? `/portal/books-management/${paramsURL.id}`
          : '/portal/books-management'
      }`
    }
    // ${detailAccount?.id}
  ]);

  const getListCategory = async () => {
    const res = await BooksManagementService.getListCategory();
    if (res?.code === 'Ok') {
      setCategoryList(res?.data);
    }
  };

  const getDetailBookByID = async () => {
    setLoading(true);
    const res = await BooksManagementService.getDetailBook(
      paramsURL.id as string
    );
    if (res?.code === 'Ok') {
      setDetailBook(res?.data);
      setValue('title', res?.data?.title);
      setValue('description', res?.data?.description);
      setValue('single_pdf', res?.data?.single_pdf);
      setValue('double_pdf', res?.data?.double_pdf);
      setValue('pocket_pdf', res?.data?.pocket_pdf);
      setLevel({ value: res?.data?.level, label: res?.data?.level });
      setType({ value: res?.data?.type, label: res?.data?.type });
      setGenre({ value: res?.data?.genre, label: res?.data?.genre });
      setCategory({ value: res?.data?.category, label: res?.data?.category });
      setValue('grade', res?.data?.grade);
      setValue('is_listen', res?.data?.is_listen);
      setValue('is_read', res?.data?.is_read);
      setValue('is_quiz', res?.data?.is_quiz);
    }
    setLoading(false);
  };

  const handleLevelChange = (selectedOption: any) => {
    setLevel(selectedOption);
  };

  const handleTypeChange = (selectedOption: any) => {
    setType(selectedOption);
    clearErrors('book_type');
  };

  const handleGenreChange = (selectedOption: any) => {
    setGenre(selectedOption);
    clearErrors('book_genre');
  };

  const handleCategoryChange = (selectedOption: any) => {
    setCategory(selectedOption);
    clearErrors('book_category');
  };

  const onError = (errors: any, e: any) => {
    if (errors) {
      for (const key in errors) {
        const messageError = `${errors[key].message}`;
        toast.error(messageError || 'Have error, please try again!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      }
    }
    if (!type) {
      if (!errors?.book_type?.message) {
        toast.error('Type is required', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      }
      setError('book_type', {
        type: 'manual',
        message: 'Type is required'
      });
    }

    if (!genre) {
      if (!errors?.book_genre?.message) {
        toast.error('Genre is required', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      }
      setError('book_genre', {
        type: 'manual',
        message: 'Genre is required'
      });
    }

    if (!category) {
      if (!errors?.book_category?.message) {
        toast.error('Category is required', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      }
      setError('book_category', {
        type: 'manual',
        message: 'Category is required'
      });
    }
    if (paramsURL.id) {
      setConfirmUpdateModal(false);
    } else {
      setConfirmAddModal(false);
    }
  };

  const submitHandler: SubmitHandler<any> = async value => {
    if (paramsURL.id) {
      setSubmitUpdate(true);
      const params = {
        ...value,
        id: paramsURL.id,
        level: level?.value,
        type: type?.value,
        genre: genre?.value,
        category: category?.value,
        is_razkid_crawed: false
      };
      try {
        const res = await BooksManagementService.editBook(params);
        if (res?.code === 'Ok') {
          toast.success('Update Success!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
        } else {
          toast.error(res?.message || 'Update Fail! Please try again.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
        }
      } catch (error) {
        toast.error('Update Fail! Please try again.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      }
      setSubmitUpdate(false);
      setConfirmUpdateModal(false);
    }

    if (!paramsURL.id) {
      setSubmitAdd(true);
      const params = {
        ...value,
        level: level?.value,
        type: type?.value,
        genre: genre?.value,
        category: category?.value,
        is_razkid_crawed: false
      };
      try {
        const res = await BooksManagementService.addBook(params);
        if (res?.code === 'Ok') {
          toast.success('Create Success!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          navigate(`/portal/books-management/edit-book/${paramsURL.id}`);
        } else {
          toast.error('Create Fail! Please try again.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
        }
      } catch (error) {
        toast.error('Create Fail! Please try again.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      }

      setSubmitAdd(false);
      setConfirmAddModal(false);
    }
  };

  useEffect(() => {
    getListCategory();
    if (paramsURL.id) {
      getDetailBookByID();
    } else {
      setLoading(false);
    }
  }, [paramsURL.id]);

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage
        icon={() => <BookIcon />}
        name={`${paramsURL.id ? 'Editing Books' : 'Create Book'}`}
      />
      <div className="mt-10">
        {paramsURL.id && (
          <div className="flex">
            <div
              className={`p-[10px] min-w-[110px] text-center cursor-pointer ${
                location.pathname.includes('book-page') ? '' : style.tabActive
              }`}
            >
              <Link to={`/portal/books-management/edit-book/${paramsURL.id}`}>
                Book
              </Link>
            </div>
            <div
              className={`p-[10px] min-w-[110px] text-center cursor-pointer ${
                location.pathname.includes('book-page') ? style.tabActive : ''
              }`}
            >
              <Link
                to={`/portal/books-management/edit-book/${paramsURL.id}/book-page`}
              >
                Book page
              </Link>
            </div>
          </div>
        )}
        <form
          className="form"
          onSubmit={handleSubmit(submitHandler, onError)}
          noValidate
          autoComplete="off"
        >
          <ArticleCard
            className={`${paramsURL.id && 'border-t-0 rounded-t-none'}`}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <CircleSpin />
              </div>
            ) : (
              <>
                <div className="flex justify-between">
                  <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
                    <label
                      htmlFor="title"
                      className={`${OptionStyle.label_plus}`}
                    >
                      Book Title
                    </label>
                    <input
                      id="title"
                      type="text"
                      required
                      placeholder="Enter Book Title"
                      className="form-control"
                      {...register('title', {
                        required: 'Book title is required',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('title');
                        }
                      })}
                    />
                    {errors?.title?.message && (
                      <span className="text-redCustom-3b text-xs">
                        {errors?.title?.message as string}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
                    <label
                      htmlFor="description"
                      className={`${OptionStyle.label_plus}`}
                    >
                      Description
                    </label>
                    <textarea
                      id="description"
                      required
                      placeholder="Enter Description"
                      className="form-control resize-none min-h-[100px]"
                      {...register('description', {
                        required: 'Description is required',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('description');
                        }
                      })}
                      cols={30}
                    ></textarea>
                    {errors?.description?.message && (
                      <span className="text-redCustom-3b text-xs">
                        {errors?.description?.message as string}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
                    <label
                      htmlFor="single_pdf"
                      className={`${OptionStyle.label_plus}`}
                    >
                      Single PDF
                    </label>
                    <input
                      id="single_pdf"
                      type="text"
                      required
                      placeholder="Enter Single PDF"
                      className="form-control"
                      {...register('single_pdf', {
                        required: false,
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('single_pdf');
                        }
                      })}
                    />
                    {errors?.single_pdf?.message && (
                      <span className="text-redCustom-3b text-xs">
                        {errors?.single_pdf?.message as string}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
                    <label
                      htmlFor="double_pdf"
                      className={`${OptionStyle.label_plus}`}
                    >
                      Double PDF
                    </label>
                    <input
                      id="double_pdf"
                      type="text"
                      required
                      placeholder="Enter Double PDF"
                      className="form-control"
                      {...register('double_pdf', {
                        required: false,
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('double_pdf');
                        }
                      })}
                    />
                    {errors?.double_pdf?.message && (
                      <span className="text-redCustom-3b text-xs">
                        {errors?.double_pdf?.message as string}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
                    <label
                      htmlFor="pocket_pdf"
                      className={`${OptionStyle.label_plus}`}
                    >
                      Pocket PDF
                    </label>
                    <input
                      id="pocket_pdf"
                      type="text"
                      required
                      placeholder="Enter Pocket PDF"
                      className="form-control"
                      {...register('pocket_pdf', {
                        required: false,
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('pocket_pdf');
                        }
                      })}
                    />
                    {errors?.pocket_pdf?.message && (
                      <span className="text-redCustom-3b text-xs">
                        {errors?.pocket_pdf?.message as string}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                    <label className={`${OptionStyle.label_plus}`} htmlFor="">
                      Level
                    </label>
                    <Select
                      options={
                        categoryList?.level?.map((item: any) => ({
                          value: item,
                          label: item
                        })) || []
                      }
                      placeholder="Choose level"
                      value={level}
                      onChange={handleLevelChange}
                      className="form-select mt-1"
                      classNamePrefix="select"
                    />
                    {/* <span className="text-redCustom-3b text-xs">
                      {errors?.is_active?.message as string}
                    </span> */}
                  </div>
                  <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                    <label className={`${OptionStyle.label_plus}`} htmlFor="">
                      Type
                    </label>
                    <Select
                      options={
                        categoryList?.type?.map((item: any) => ({
                          value: item,
                          label: item
                        })) || []
                      }
                      placeholder="Choose type"
                      value={type}
                      onChange={handleTypeChange}
                      className="form-select mt-1"
                      classNamePrefix="select"
                    />
                    <span className="text-redCustom-3b text-xs">
                      {errors?.book_type?.message as string}
                    </span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                    <label className={`${OptionStyle.label_plus}`} htmlFor="">
                      Genre
                    </label>
                    <Select
                      options={
                        categoryList?.genre?.map((item: any) => ({
                          value: item,
                          label: item
                        })) || []
                      }
                      placeholder="Choose genre"
                      value={genre}
                      onChange={handleGenreChange}
                      className="form-select mt-1"
                      classNamePrefix="select"
                    />
                    <span className="text-redCustom-3b text-xs">
                      {errors?.book_genre?.message as string}
                    </span>
                  </div>
                  <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                    <label className={`${OptionStyle.label_plus}`} htmlFor="">
                      Category
                    </label>
                    <Select
                      options={
                        categoryList?.category?.map((item: any) => ({
                          value: item,
                          label: item
                        })) || []
                      }
                      placeholder="Choose category"
                      value={category}
                      onChange={handleCategoryChange}
                      className="form-select mt-1"
                      classNamePrefix="select"
                    />
                    <span className="text-redCustom-3b text-xs">
                      {errors?.book_category?.message as string}
                    </span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                    <label className={`${OptionStyle.label_plus}`} htmlFor="">
                      Grade
                    </label>
                    <input
                      id="grade"
                      type="text"
                      required
                      placeholder="Enter grade"
                      className="form-control"
                      {...register('grade', {
                        required: false,
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('grade');
                        }
                      })}
                    />
                    {/* <span className="text-redCustom-3b text-xs">
                      {errors?.grade?.message as string}
                    </span> */}
                  </div>
                  <div
                    className={`form-group my-2 ml-[5px] mr-[23px] w-1/2 flex justify-between`}
                  >
                    <div className="flex flex-col gap-2">
                      <label
                        className={`${OptionStyle.label_plus}`}
                        htmlFor="is_listen"
                      >
                        Listen
                      </label>
                      <input
                        id="is_listen"
                        type="checkbox"
                        required
                        className="form-checkbox"
                        {...register('is_listen', {
                          required: false,
                          onChange: () => {
                            trigger('is_listen');
                          }
                        })}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label
                        className={`${OptionStyle.label_plus}`}
                        htmlFor="is_read"
                      >
                        Read
                      </label>
                      <input
                        id="is_read"
                        type="checkbox"
                        required
                        className="form-checkbox"
                        {...register('is_read', {
                          required: false,
                          onChange: () => {
                            trigger('is_read');
                          }
                        })}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label
                        className={`${OptionStyle.label_plus}`}
                        htmlFor="is_quiz"
                      >
                        Quiz
                      </label>
                      <input
                        id="is_quiz"
                        type="checkbox"
                        required
                        className="form-checkbox"
                        {...register('is_quiz', {
                          required: false,
                          onChange: () => {
                            trigger('is_quiz');
                          }
                        })}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </ArticleCard>
          <PortalButton
            type="button"
            className="min-w-[140px]"
            onClick={() => {
              paramsURL.id
                ? setConfirmUpdateModal(true)
                : setConfirmAddModal(true);
            }}
          >
            {paramsURL.id ? 'Update' : 'Add'}
          </PortalButton>
          <Modal
            show={confirmAddModal}
            title={'Do you want to add this book?'}
            onClose={() => {
              setConfirmAddModal(false);
            }}
            className="max-w-[420px] font-Roboto"
          >
            <div className="flex space-x-4">
              <PortalButton
                type="button"
                className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                onClick={() => {
                  setConfirmAddModal(false);
                }}
              >
                Cancel
              </PortalButton>
              <PortalButton
                type="submit"
                className="flex justify-center w-full md:w-52"
                disabled={submitAdd}
                onClick={handleSubmit(submitHandler, onError)}
              >
                {submitAdd && <CircleSpin />}
                Submit
              </PortalButton>
            </div>
          </Modal>
          <Modal
            show={confirmUpdateModal}
            title={'Confirm Update'}
            onClose={() => {
              setConfirmUpdateModal(false);
            }}
            className="max-w-[420px] font-Roboto"
          >
            <div className="flex space-x-4">
              <PortalButton
                type="button"
                className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                onClick={() => {
                  setConfirmUpdateModal(false);
                }}
              >
                Cancel
              </PortalButton>
              <PortalButton
                type="submit"
                className="flex justify-center w-full md:w-52"
                disabled={submitUpdate}
                onClick={handleSubmit(submitHandler, onError)}
              >
                {submitUpdate && <CircleSpin />}
                Submit
              </PortalButton>
            </div>
          </Modal>
        </form>
      </div>
    </>
  );
};

export default BooksManagementAdd;
