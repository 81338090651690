import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import { Button } from './button/Button';
import { BoxContainer } from './Index';
import { book, news } from 'types/data.type';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import book_corner_icon from 'assets/imgs/book-corner-icon.svg';
import latest_news_icon from 'assets/imgs/latest-news-icon.svg';
import BookTypeBox from './BookTypeBox';
import { BookType } from 'types/Book.type';
import { processBook } from '@core/hooks/common';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';

interface SliderProps {
  items: book[] | news[] | any[];
  type?: string;
  isAssign?: boolean;
  title?: string;
}
export const Slider: React.FC<SliderProps> = ({
  items,
  type,
  isAssign,
  title
}) => {
  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <div className="flex gap-2 items-center">
          <div className="p-2 rounded-lg flex justify-center items-center bg-gray-F7F7F7">
            {type === 'book-corner' ? (
              <img src={book_corner_icon} alt="Book Corner Icon" />
            ) : (
              <img src={latest_news_icon} alt="Latest News Icon" />
            )}
          </div>
          <p className="text-[22px] font-medium">{title}</p>
        </div>
        <a
          href={type === 'book-corner' ? '/books' : '/news'}
          className="text-235E5A font-medium text-sm"
        >
          View All
        </a>
      </div>
      <div className="relative">
        {type === 'news' ? (
          <>
            <div className="hidden xl:flex gap-x-[10px] bottom-[50%] w-full justify-between">
              <Button className="swiper-button-news-prev md:w-8 md:h-8 !bg-F3FAFA rounded-[4px] !p-0 absolute top-[50%] -left-[45px] z-50">
                <LiaAngleLeftSolid size={16} color="#235E5A" />
              </Button>
              <Button className="swiper-button-news-next md:w-8 md:h-8 !bg-F3FAFA rounded-[4px] !p-0 absolute top-[50%] -right-[45px] z-50">
                <LiaAngleRightSolid size={16} color="#235E5A" />
              </Button>
            </div>
            <Swiper
              loop={true}
              spaceBetween={16}
              autoplay={false}
              speed={500}
              navigation={{
                nextEl: '.swiper-button-news-next',
                prevEl: '.swiper-button-news-prev'
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1
                },
                768: {
                  slidesPerView: 2
                },
                1024: {
                  slidesPerView: 3
                }
              }}
              grabCursor={true}
              touchEventsTarget="wrapper"
              modules={[Autoplay, Pagination, Navigation]}
              className="flex relative"
            >
              {items?.map(item => (
                <SwiperSlide key={item?.id} className=" mx-auto">
                  <Link to={`/news/detail/${item?.id}`}>
                    <BoxContainer className="w-full sm:max-w-[340px]">
                      <div className="flex flex-col gap-4">
                        <div className="w-full">
                          <img
                            src={item?.cover_img}
                            alt="Book Img"
                            className="object-cover w-full h-full rounded-lg"
                          />
                        </div>
                        <div className="w-full">
                          <p className="text-base font-medium h-[48px] line-clamp-2">
                            {item?.title}
                          </p>
                        </div>
                      </div>
                    </BoxContainer>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : (
          <>
            <div className="hidden xl:flex gap-x-[10px] bottom-[50%] w-full justify-between">
              <Button className="swiper-button-book-prev md:w-8 md:h-8 !bg-F3FAFA rounded-[4px] !p-0 absolute top-[50%] -left-[45px] z-50">
                <LiaAngleLeftSolid size={16} color="#235E5A" />
              </Button>
              <Button className="swiper-button-book-next md:w-8 md:h-8 !bg-F3FAFA rounded-[4px] !p-0 absolute top-[50%] -right-[45px] z-50">
                <LiaAngleRightSolid size={16} color="#235E5A" />
              </Button>
            </div>
            <Swiper
              loop={true}
              spaceBetween={16}
              autoplay={false}
              speed={500}
              navigation={{
                nextEl: '.swiper-button-book-next',
                prevEl: '.swiper-button-book-prev'
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1
                },
                768: {
                  slidesPerView: 2
                },
                1024: {
                  slidesPerView: 3
                }
              }}
              grabCursor={true}
              touchEventsTarget="wrapper"
              modules={[Autoplay, Pagination, Navigation]}
              className="flex relative"
            >
              {items?.map(item => (
                <SwiperSlide key={item?.id} className=" mx-auto">
                  <BoxContainer className="w-full sm:w-[340px] md:w-full max-h-[218px] h-[218px]">
                    <Link to={`/books/view/${item?.id}`}>
                      <div className="flex flex-row gap-x-4">
                        <div className="flex justify-center md:w-1/2 items-center bg-[#fff] rounded-lg">
                          <img
                            src={
                              item?.is_listen
                                ? item?.cover_listen_img
                                : item?.is_read
                                ? item?.cover_read_img
                                : item?.cover_img
                            }
                            alt="Book Img"
                            className="rounded-lg object-contain min-w-[119px] h-full"
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className="flex gap-1 flex-wrap">
                            <BookTypeBox
                              type={BookType.LEVEL}
                              title={item?.level}
                            />
                            <BookTypeBox
                              type={BookType.CATEGORY}
                              title={item?.category}
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <h1 className="text-base font-medium">
                              {item?.title}
                            </h1>
                            <p
                              className="text-sm font-normal line-clamp-4"
                              dangerouslySetInnerHTML={{
                                __html: item?.description || item?.content
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </Link>
                    {isAssign && (
                      <>
                        <ProgressBar
                          now={processBook(
                            Math.round(
                              (item?.current_page_count /
                                item?.total_page_count) *
                                100
                            ),
                            Math.round(
                              (item?.current_cover_count /
                                item?.total_cover_count) *
                                100
                            )
                          )}
                          label={`${
                            processBook(
                              Math.round(
                                (item?.current_page_count /
                                  item?.total_page_count) *
                                  100
                              ),
                              Math.round(
                                (item?.current_cover_count /
                                  item?.total_cover_count) *
                                  100
                              )
                            ) === 100
                              ? 'Done'
                              : processBook(
                                  Math.round(
                                    (item?.current_page_count /
                                      item?.total_page_count) *
                                      100
                                  ),
                                  Math.round(
                                    (item?.current_cover_count /
                                      item?.total_cover_count) *
                                      100
                                  )
                                ) === 0
                              ? ''
                              : `${processBook(
                                  Math.round(
                                    (item?.current_page_count /
                                      item?.total_page_count) *
                                      100
                                  ),
                                  Math.round(
                                    (item?.current_cover_count /
                                      item?.total_cover_count) *
                                      100
                                  )
                                )}%`
                          }`}
                        />
                      </>
                    )}
                  </BoxContainer>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}
      </div>
    </>
  );
};
