import { SubNav } from '@shared/components/SubNav';
import React, { useCallback, useEffect, useState } from 'react';
import book2 from 'assets/imgs/book2.jpg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BoxContainer } from '@shared/components/BoxContainer';
import PaginationOwn from '@shared/components/Pagination';
import BooksService from '@core/services/books.service';
import { responseType, searchAllType } from 'types/data.type';

const Search = () => {
  const [searchData, setSearchData] = useState<responseType>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const searchKey = searchParams.get('q')?.split('?')[0];
  const page = searchParams.get('q')?.split('?')[1]?.split('page=')[1] || 1;

  const crums = [
    {
      name: 'Home',
      url: '/'
    },
    {
      name: `"${searchKey}"`,
      url: `/search?q=${searchKey}`,
      selected: true
    }
  ];

  const getListSearch = async (params: searchAllType) => {
    const res = await BooksService.searchAll(params);
    if (res.status === 1) {
      setSearchData(res.data);
    }
  };

  useEffect(() => {
    if (page) {
      setCurrentPage(Number(page));
      let params = {
        search_key: searchKey || '',
        page_index: Number(page),
        page_size: 12
      };

      if (searchKey) {
        params = {
          ...params,
          search_key: searchKey
        };
      }
      getListSearch(params);
    }
  }, [page, searchKey]);

  const onPageChange = useCallback(
    async (event: number) => {
      navigate(`?q=${searchKey}?page=${event}`);
    },
    [navigate, searchKey]
  );

  return (
    <>
      <SubNav title={`Search Results for: ${searchKey}`} crums={crums} />

      <div className="container py-20">
        {searchData?.total_results === 0 ? (
          <h1 className="text-4xl font-light text-center text-yellow_3 mt-5 mb-20 leading-[54px]">
            Sorry, but nothing matched your search criteria. Please try again
            with some different keywords.
          </h1>
        ) : (
          <div>
            <div className="flex flex-col gap-3 gap-y-10 lg:flex-row lg:flex-wrap">
              {searchData?.items?.map(book => (
                <div className="flex flex-col gap-y-[15px] items-center cursor-pointer lg:w-[calc(25%-9px)]">
                  <BoxContainer className="max-w-[310px] max-h-[310px] h-[310px] w-[310px] lg:max-w-[216px] lg:max-h-[216px] xl:max-w-[265px] xl:max-h-[265px]">
                    <img
                      src={
                        book?.is_listen
                          ? book?.cover_listen_img
                          : book?.is_read
                          ? book?.cover_read_img
                          : book?.cover_img
                      }
                      alt="Book Img"
                      className="max-w-[100%] max-h-[100%] w-[100%] h-[100%]"
                    />
                  </BoxContainer>
                  <div className="text-yellow_3">
                    <p className="text-[27px] font-bold leading-[37px] text-center md:min-h-[74px]">
                      {book?.title}
                    </p>
                    <p
                      className="font-Roboto text-base font-normal leading-[22px] line-clamp-3 text-center"
                      dangerouslySetInnerHTML={{
                        __html: book?.description || book?.content
                      }}
                    ></p>
                  </div>
                </div>
              ))}
            </div>
            {searchData?.total_results &&
              searchData?.total_results > itemsPerPage && (
                <div className="my-6 d-flex text-right">
                  <div className="my-6 mt-20 flex item-center justify-center gap-3">
                    <PaginationOwn
                      totalItems={searchData?.total_results}
                      itemPerPage={itemsPerPage}
                      pageChange={onPageChange}
                      pageCurrent={currentPage}
                      addClass={'!w-auto'}
                    />
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    </>
  );
};

export default Search;
