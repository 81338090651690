import { BoxContainer, Button, Empty, Loading } from '@shared/components/Index';
import React, { useEffect, useState } from 'react';
import { category, getAllBooksType, responseType } from 'types/data.type';
import BooksService from '@core/services/books.service';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import process_icon from 'assets/imgs/processIcon.png';
import { IoSearchOutline } from 'react-icons/io5';
import Select from 'react-select';
import book_icon from 'assets/imgs/book_icon.png';
import BookTypeBox from '@shared/components/BookTypeBox';
import { BookType } from 'types/Book.type';
import { processBook } from '@core/hooks/common';

const BookStatus = [
  { title: 'All', id: 'ALL' },
  { title: 'Personal Quest', id: 'PERSONAL_QUEST' }
];

const BooksList = () => {
  const [itemsPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const [books, setBooks] = useState<responseType>();
  const [loading, setLoading] = useState(false);
  const [listCategory, setListCategory] = useState<category>();
  const [level, setLevel] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [bookStatusTab, setBookStatusTab] = useState('ALL');
  const [searchKey, setSearchKey] = useState<string>('');
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [stopLoadMore, setStopLoadMore] = useState<boolean>(false);
  const [isScrollEnd, setIsScrollEnd] = useState<boolean>(false);

  useEffect(() => {
    const fetchDataCategory = async () => {
      const resCategory = await BooksService.getListCategory();
      if (resCategory) {
        setListCategory(resCategory?.data);
      }
    };

    fetchDataCategory();
  }, []);

  const fetchBooksData = async (params: getAllBooksType) => {
    setLoading(true);
    const param = {
      ...params,
      search_key: searchKey || '',
      category: category?.value || '',
      level: level?.value || '',
      page_index: currentPage,
      page_size: itemsPerPage
    };

    const resBooks = await BooksService.getAllBooks(param);
    if (resBooks) {
      if (param?.page_index === 1) {
        setBooks(resBooks?.data);
      } else {
        if (resBooks?.data?.items?.length > 0) {
          setStopLoadMore(false);
          setBooks({
            ...resBooks?.data,
            items: [...(books?.items || []), ...resBooks?.data?.items]
          });
        } else {
          setStopLoadMore(true);
        }
      }
      setLoading(false);
      setLoadMoreLoading(false);
      setIsScrollEnd(false);
    }
  };

  const onClickSearch = () => {
    const newPage = 1;
    setCurrentPage(newPage);
    const params = {
      search_key: searchKey || '',
      page_index: newPage,
      page_size: itemsPerPage
    };
    fetchBooksData(params);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: '32px',
      height: '32px',
      boxShadow: state.isFocused ? null : null,
      borderRadius: '8px'
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '32px',
      padding: '0 6px'
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px'
    }),
    // indicatorSeparator: (state: any) => ({
    //   display: 'none'
    // }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '32px'
    })
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    let params = {
      search_key: searchKey || '',
      page_index: Number(currentPage),
      page_size: itemsPerPage
    };

    fetchBooksData(params);
  }, [level, category]);

  useEffect(() => {
    if (loadMoreLoading && !stopLoadMore) {
      setLoadMoreLoading(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      const params = {
        search_key: searchKey || '',
        page_index: newPage,
        page_size: itemsPerPage
      };
      fetchBooksData(params);
    }
  }, [isScrollEnd]);

  const handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + window.scrollY) <
        document.documentElement.scrollHeight ||
      loading ||
      loadMoreLoading ||
      isScrollEnd
    ) {
      return;
    }
    setIsScrollEnd(true);
  };

  const handleKeydown = (event: any) => {
    if (event.key === 'Enter') {
      const newPage = 1;
      setCurrentPage(newPage);
      const params = {
        search_key: searchKey || '',
        page_index: newPage,
        page_size: itemsPerPage
      };
      fetchBooksData(params);
    }
  };

  return (
    <>
      <div className="text-[28px] font-medium text-[#363636] text-center mt-8">
        Book Corner
      </div>
      <div className="text-[16px] font-medium text-[#363636] text-center mt-1">
        Every book is a quest and new world await on every page. <br /> Read,
        listen, and explore!
      </div>
      <div className="container p-4 h-max overflow-hidden">
        <div className="pb-10 flex flex-col gap-4">
          <div className="flex flex-col md:flex-row gap-3 justify-between">
            <div className="flex gap-1 px-1 py-[5px] rounded-md bg-F3FAFA">
              {BookStatus?.map(item => (
                <div
                  key={item?.id}
                  className={`rounded px-4 py-1 cursor-pointer ${
                    bookStatusTab === item.id
                      ? 'bg-white text-235E5A shadow-md'
                      : 'text-888888 bg-F3FAFA'
                  }`}
                  onClick={() => {
                    // setBookStatusTab(item.id);
                    return;
                  }}
                >
                  <p className="text-xs font-medium">{item.title}</p>
                </div>
              ))}
            </div>
            <div className="flex flex-col md:flex-row justify-between gap-3 z-40">
              <div className="flex">
                <div className="relative w-full md:w-[140px]">
                  <div
                    className=" absolute top-0 left-0 p-2 cursor-pointer"
                    onClick={onClickSearch}
                  >
                    <IoSearchOutline size={16} color="#363636" />
                  </div>
                  <input
                    type="text"
                    className="text-sm w-full border border-DEDEDE rounded-lg focus:outline-none pl-9 pr-3 h-8 font-medium text-[#363636]"
                    placeholder="Search books"
                    onChange={event => setSearchKey(event.target.value)}
                    onKeyDown={handleKeydown}
                  />
                </div>
              </div>
              <div className="relative">
                {/* <div className="absolute right-3 top-[11px] z-30">
                  <IoIosArrowDown className="text-base" />
                </div> */}
                <Select
                  value={level}
                  onChange={e => {
                    setLevel(e);
                  }}
                  options={listCategory?.level?.map(item => {
                    return { value: item, label: item };
                  })}
                  placeholder="Level"
                  className="w-full md:w-[140px] !border-C8C8C8 text-sm hover:!border-none custom-select"
                  styles={customStyles}
                />
              </div>
              <div className="relative">
                {/* <div className="absolute right-3 top-[11px] z-30">
                  <IoIosArrowDown className="text-base" />
                </div> */}
                <Select
                  value={category}
                  onChange={e => {
                    setCategory(e);
                  }}
                  options={listCategory?.category?.map(item => {
                    return { value: item, label: item };
                  })}
                  placeholder="Topic"
                  className="w-full md:w-[140px] !border-C8C8C8 text-sm hover:!border-none"
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap gap-4">
            {loading && !loadMoreLoading ? (
              <div className="flex justify-center w-full">
                <Loading />
              </div>
            ) : books?.total_results === 0 && bookStatusTab === 'Learning' ? (
              <Empty>
                <div className="flex flex-col gap-6 text-center">
                  <p className="text-[22px] font-medium">
                    No assigned books at the moment.
                  </p>
                  <div className="flex flex-col gap-3 items-center">
                    <p className="text-sm">
                      How about another adventure with new books?
                    </p>
                    <Button
                      className="px-2 flex gap-2 justify-center items-center rounded-lg bg-blue_2 py-0 text-F3FAFA"
                      url={`/books`}
                    >
                      <img src={book_icon} alt="Book Icon" className="w-4" />
                      <p className="text-sm font-medium">Explore Other Books</p>
                    </Button>
                  </div>
                </div>
              </Empty>
            ) : (
              books?.items?.map((item: any) => (
                <BoxContainer className="lg:max-h-[218px] lg:h-[218px] md:w-[calc(50%-8px)] xl:w-[340px]">
                  <Link to={`/books/view/${item?.id}`}>
                    <div className="flex gap-x-4">
                      <img
                        src={
                          item?.is_read
                            ? item?.cover_read_img
                            : item?.is_listen
                            ? item?.cover_listen_img
                            : item?.cover_img
                        }
                        alt="Book Img"
                        className="rounded-lg h-[186px] w-[119px] object-contain"
                      />
                      <div className="flex flex-col gap-2">
                        <div className="flex gap-1 flex-wrap">
                          <BookTypeBox
                            type={BookType.LEVEL}
                            title={item?.level}
                          />
                          <BookTypeBox
                            type={BookType.CATEGORY}
                            title={item?.category}
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <h1 className="text-base font-medium leading-5">
                            {item?.title}
                          </h1>
                          <p
                            className="text-sm font-normal line-clamp-3 leading-[18px]"
                            dangerouslySetInnerHTML={{
                              __html: item?.description || item?.content
                            }}
                          ></p>
                        </div>
                        <div
                          className={`flex flex-col gap-[2px] flex-1 justify-end ${
                            processBook(
                              Math.round((item?.current_page_count /
                                item?.total_page_count) *
                                100),
                              Math.round(
                                (item?.current_cover_count /
                                  item?.total_cover_count) *
                                  100
                              )
                            ) === 0
                              ? 'hidden'
                              : ''
                          }`}
                        >
                          <div className="flex gap-2 items-center">
                            <div className="flex-1 flex gap-[2px] items-center">
                              <img
                                src={process_icon}
                                alt="Process Icon"
                                className="w-4"
                              />
                              <ProgressBar
                                now={processBook(
                                  Math.round((item?.current_page_count /
                                    item?.total_page_count) *
                                    100),
                                  Math.round(
                                    (item?.current_cover_count /
                                      item?.total_cover_count) *
                                      100
                                  )
                                )}
                                label={
                                  <>
                                    <div className="h-[8px] bg-blue_2 rounded"></div>
                                  </>
                                }
                                className="w-full h-[8px]"
                              />
                            </div>
                            <p className="text-sm">
                              {processBook(
                                Math.round((item?.current_page_count /
                                  item?.total_page_count) *
                                  100),
                                Math.round(
                                  (item?.current_cover_count /
                                    item?.total_cover_count) *
                                    100
                                )
                              )}
                              %
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </BoxContainer>
              ))
            )}
          </div>
          <div>{loadMoreLoading && <Loading />}</div>
        </div>
      </div>
    </>
  );
};

export default BooksList;
