import MasterLayout from '@shared/layouts/MasterLayout';
import BookDetail from 'pages/books/BookDetail';
import BookView from 'pages/books/BookView';
import BooksList from 'pages/books/BooksList';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const BooksRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route index element={<BooksList />} />
      </Route>
      <Route path="/view/:id" element={<MasterLayout />}>
        <Route index element={<BookView />} />
      </Route>
      <Route path={`/detail/:id`} element={<MasterLayout />}>
        <Route index element={<BookDetail />} />
      </Route>
    </Routes>
  );
};
