import React, { ReactEventHandler, useEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Loading, VolumeInput } from '../Index';
import { AudioProgressBar } from './AudioProgressBar';
import { HiOutlineVolumeOff, HiOutlineVolumeUp } from 'react-icons/hi';
import { HiOutlinePlay } from 'react-icons/hi2';
import { LuPause } from 'react-icons/lu';
interface AudioPlayerProps {
  curentSong?: { title: string; src: string };
  clasName?: string;
  isChangePage?: boolean;
  currentIndex?: number;
  selectedIndex?: number;
}

export const AudioPlayer = ({
  curentSong,
  clasName,
  currentIndex,
  selectedIndex
}: AudioPlayerProps) => {
  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [volume, setVolume] = useState(1);
  const [currrentProgress, setCurrrentProgress] = useState(0);
  const [buffered, setBuffered] = useState(0);
  const durationDisplay = formatDurationDisplay(duration);
  const elapsedDisplay = formatDurationDisplay(currrentProgress);
  const [loading, setLoading] = useState(true);
  const [curentAudio, setCurentAudio] = useState<any>();

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      if (loading) return;
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (curentSong) {
      setCurentAudio(curentSong);
      setLoading(false);
    }
  }, [curentSong]);

  useEffect(() => {
    if (currentIndex !== selectedIndex) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      if (loading) return;
      audioRef.current?.play();
      setIsPlaying(true);
    }
  }, [currentIndex, selectedIndex, loading]);

  useEffect(() => {
    if (!isPlaying) {
      if (loading) return;
      setIsPlaying(true);
      audioRef.current?.play();
    }
  }, []);

  const handleVolumeChange = (volumeValue: number) => {
    if (!audioRef.current) return;
    audioRef.current.volume = volumeValue;
    setVolume(volumeValue);
  };

  const handleMuteUnmute = () => {
    if (!audioRef.current) return;
    if (audioRef.current.volume !== 0) {
      audioRef.current.volume = 0;
    } else {
      audioRef.current.volume = 1;
    }
  };

  const handleBufferProgress: ReactEventHandler<HTMLAudioElement> = e => {
    const audio = e.currentTarget;
    const dur = audio.duration;
    if (dur > 0) {
      for (let i = 0; i < audio.buffered.length; i++) {
        if (
          audio.buffered.start(audio.buffered.length - 1 - i) <
          audio.currentTime
        ) {
          const bufferedLength = audio.buffered.end(
            audio.buffered.length - 1 - i
          );
          setBuffered(bufferedLength);
          break;
        }
      }
    }
  };

  function formatDurationDisplay(duration: number) {
    const min = Math.floor(duration / 60);
    const sec = Math.floor(duration - min * 60);
    const formatted = [min, sec].map(n => (n < 10 ? '0' + n : n)).join(':'); // format - mm:ss
    return formatted;
  }

  return (
    <div className={`${clasName}`}>
      {loading ? (
        <div className="flex justify-center items-center">
          <Loading className="w-5 h-5" />
        </div>
      ) : (
        <>
          <audio
            ref={audioRef}
            preload="metadata"
            onDurationChange={e => setDuration(e.currentTarget.duration)}
            onCanPlay={e => {
              e.currentTarget.volume = volume;
              setIsReady(true);
            }}
            onVolumeChange={e => setVolume(e.currentTarget.volume)}
            onPlaying={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onTimeUpdate={e => {
              setCurrrentProgress(e.currentTarget.currentTime);
              handleBufferProgress(e);
            }}
            onProgress={handleBufferProgress}
          >
            <source type="audio/mpeg" src={curentAudio?.src} />
          </audio>
          {/* <div className="text-center mb-1">
        <p className="text-yellow_3 text-xl font-bold">
          {curentSong?.title || 'Audio'}
        </p>
      </div> */}
          <div className="flex flex-col items-center max-w-[500px] w-full">
            <div className="flex items-center gap-3 justify-self-center flex-col w-full">
              <div className="flex gap-2 items-center lg:w-[333px]">
                <div className="flex gap-2 lg:gap-4 lg:max-w-[316px] lg:w-[316px]">
                  <Button
                    disabled={!isReady}
                    onClick={togglePlayPause}
                    aria-label={isPlaying ? 'Pause' : 'Play'}
                    size="sm"
                    className="outline-none"
                  >
                    {!isReady && curentSong ? (
                      <Spinner className="animate-spin" />
                    ) : isPlaying ? (
                      <LuPause className={`w-6 h-6`} />
                    ) : (
                      <HiOutlinePlay className={`w-6 h-6`} />
                    )}
                  </Button>
                  <AudioProgressBar
                    duration={duration}
                    currentProgress={currrentProgress}
                    buffered={buffered}
                    onChange={e => {
                      if (!audioRef.current) return;
                      audioRef.current.currentTime =
                        e.currentTarget.valueAsNumber;
                      setCurrrentProgress(e.currentTarget.valueAsNumber);
                    }}
                  />
                </div>
                <div className="w-[90px]">
                  <p className="!text-sm font-normal whitespace-nowrap">
                    {`${elapsedDisplay}/ ${durationDisplay}`}
                  </p>
                </div>
              </div>
              <div className="gap-4 md:justify-self-end items-center w-[400px] hidden">
                <Button
                  size="sm"
                  onClick={handleMuteUnmute}
                  aria-label={volume === 0 ? 'unmute' : 'mute'}
                  className="outline-none"
                >
                  {volume === 0 ? (
                    <HiOutlineVolumeOff className={`w-10 h-10 lg:w-6 lg:h-6`} />
                  ) : (
                    <HiOutlineVolumeUp className={`w-10 h-10 lg:w-6 lg:h-6`} />
                  )}
                </Button>
                <VolumeInput
                  volume={volume}
                  onVolumeChange={handleVolumeChange}
                />
              </div>
            </div>
          </div>
          {/* {!isPlaying && !loading && (
        <div className="bg-yellow_1 text-yellow_3 rounded-[8px] p-3 flex z-50">
          <Button
            disabled={!isReady}
            onClick={togglePlayPause}
            aria-label={isPlaying ? 'Pause' : 'Play'}
            size="lg"
            className={`flex justify-center items-center`}
          >
            {!isReady && curentAudio ? (
              <Spinner className="animate-spin" />
            ) : isPlaying ? (
              <PlayIcon />
            ) : (
              <StopIcon />
            )}
          </Button>
        </div>
      )} */}
        </>
      )}
    </div>
  );
};
