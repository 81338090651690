import AuthService from '@core/services/auth.service';
import { ArticleCard } from '@shared/components/ArticleCard';
import Modal from '@shared/components/Modal';
import Breadcrumb from '@shared/components/breadcrumb/Breadcrumb';
import { PortalButton } from '@shared/components/button/PortalButton';
import TitlePage from '@shared/components/titlePage/TitlePage';
import { CircleSpin } from 'assets/icons/CircleSpin';
import { UserIcon } from 'assets/icons/UserIcon';
import React, { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

const OptionStyle = {
  form_group_plus: 'w-full',
  label_plus: 'font-semibold text-sm leading-5'
};

const AccountManagementAdd = () => {
  const navigate = useNavigate();
  const [confirmAddModal, setConfirmAddModal] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
  const [submitUpdate, setSubmitUpdate] = useState(false);
  const [status, setStatus] = useState<any>({
    value: true,
    label: 'Active'
  });
  const [role, setRole] = useState<any>({ value: false, label: 'Teacher' });
  const _paramsURL = useParams();
  const [paramsURL, setParamsURL] = useState({ ..._paramsURL });
  const [detailAccount, setDetailAccount] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [crumbs] = useState([
    {
      name: 'Account Management',
      url: `/portal/account-management`
    },
    {
      name: `${paramsURL.id ? 'Edit Account' : 'Create Account'}`,
      url: `${
        paramsURL.id
          ? `/portal/account-management/${detailAccount?.id}`
          : '/portal/account-management'
      }`
    }
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors }
  } = useForm<any>({});

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const getDetail = async (id: string) => {
    setLoading(true);
    const res = await AuthService.getDetailAccount(id);
    if (res?.status === 1) {
      setDetailAccount(res?.data);
      setValue('is_system_admin', res?.data?.is_system_admin);
      setValue('is_active', res?.data?.is_active);
      setValue('username', res?.data?.username);
      setValue('password', res?.data?.password);
      setValue('fullname', res?.data?.fullname);
      setStatus({
        value: res?.data?.is_active,
        label: res?.data?.is_active ? 'Active' : 'Inactive'
      });
      setRole({
        value: res?.data?.is_system_admin,
        label: res?.data?.is_system_admin ? 'Admin' : 'Teacher'
      });
      setLoading(false);
    } else {
      toast.error('Get Detail Fail! Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paramsURL.id) {
      getDetail(paramsURL.id);
    } else {
      setLoading(false);
    }
  }, [paramsURL.id]);

  const submitHandler: SubmitHandler<any> = async value => {
    if (paramsURL.id) {
      const params = {
        ...value,
        id: paramsURL.id
      };
      setSubmitUpdate(true);
      try {
        const res = await AuthService.updateAccount(params);
        if (res?.status === 1) {
          setSubmitUpdate(false);
          setConfirmUpdateModal(false);
          toast.success('Update Success!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
        } else {
          toast.error('Update Fail! Please try again.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          setSubmitUpdate(false);
          setConfirmUpdateModal(false);
        }
      } catch (error) {
        toast.error('Create Fail! Please try again.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
        setSubmitAdd(false);
        setConfirmAddModal(false);
      }
    }

    if (!paramsURL.id) {
      const params = {
        ...value
      };
      setSubmitAdd(true);
      try {
        const res = await AuthService.addAccount(params);
        if (res?.status === 1) {
          setSubmitAdd(false);
          setConfirmAddModal(false);
          toast.success('Create Success!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          navigate(`/portal/account-management/edit-account/${res?.data?.id}`);
        } else {
          toast.error('Create Fail! Please try again.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
          });
          setSubmitAdd(false);
          setConfirmAddModal(false);
        }
      } catch (error) {
        toast.error('Create Fail! Please try again.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
        setSubmitAdd(false);
        setConfirmAddModal(false);
      }
    }
  };

  const onError = (errors: any, e: any) => {
    if (errors) {
      for (const key in errors) {
        const messageError = `${errors[key].message}`;
        toast.error(messageError || 'Error, Please try again!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      }
      setConfirmAddModal(false);
    }
  };

  const handleStatusChange = (status: any) => {
    setStatus(status);
    setValue('is_active', status.value);
  };

  const handleRoleChange = (role: any) => {
    setRole(status);
    setValue('is_system_admin', role.value);
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage icon={() => <UserIcon />} name="Create Account" />
      <div className="mt-10">
        <form
          className="form"
          onSubmit={handleSubmit(submitHandler, onError)}
          noValidate
          autoComplete="off"
        >
          <ArticleCard>
            {loading ? (
              <div className="flex justify-center items-center">
                <CircleSpin />
              </div>
            ) : (
              <>
                <div className="flex justify-between">
                  <div className="form-group my-2 ml-[5px] mr-[23px] w-1/2">
                    <label
                      htmlFor="username"
                      className={`${OptionStyle.label_plus}`}
                    >
                      Username
                    </label>
                    <input
                      id="username"
                      type="text"
                      disabled={paramsURL.id ? true : false}
                      required
                      placeholder="Enter username"
                      className="form-control"
                      {...register('username', {
                        required: 'Username is required',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('username');
                        }
                      })}
                      defaultValue={detailAccount?.username}
                    />
                    {errors?.username?.message && (
                      <span className="text-redCustom-3b text-xs">
                        {errors?.username?.message as string}
                      </span>
                    )}
                  </div>
                  <div className="form-group my-2 ml-[5px] mr-[23px] w-1/2">
                    <label
                      htmlFor="password"
                      className={`${OptionStyle.label_plus}`}
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      type="text"
                      required
                      placeholder="Enter password"
                      className="form-control"
                      {...register('password', {
                        required: 'Password is required',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('password');
                        }
                      })}
                      defaultValue={detailAccount?.password}
                    />
                    {errors?.password?.message && (
                      <span className="text-redCustom-3b text-xs">
                        {errors?.password?.message as string}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
                    <label
                      htmlFor="fullname"
                      className={`${OptionStyle.label_plus}`}
                    >
                      Full Name
                    </label>
                    <input
                      id="fullname"
                      type="text"
                      required
                      placeholder="Enter full name"
                      className="form-control"
                      {...register('fullname', {
                        required: 'Full name is required',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('fullname');
                        }
                      })}
                      defaultValue={detailAccount?.fullname}
                    />
                    {errors?.fullname?.message && (
                      <span className="text-redCustom-3b text-xs">
                        {errors?.fullname?.message as string}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                    <label className={`${OptionStyle.label_plus}`} htmlFor="">
                      Role
                    </label>
                    <Select
                      options={[
                        {
                          value: true,
                          label: 'Admin'
                        },
                        {
                          value: false,
                          label: 'Teacher'
                        }
                      ]}
                      placeholder="Choose status"
                      value={role}
                      onChange={handleRoleChange}
                      className="form-select mt-1"
                      classNamePrefix="select"
                    />
                    <span className="text-redCustom-3b text-xs">
                      {errors?.is_system_admin?.message as string}
                    </span>
                  </div>
                  <div className={`form-group my-2 ml-[5px] mr-[23px] w-1/2`}>
                    <label className={`${OptionStyle.label_plus}`} htmlFor="">
                      Status
                    </label>
                    <Select
                      options={[
                        {
                          value: true,
                          label: 'Active'
                        },
                        {
                          value: false,
                          label: 'Inactive'
                        }
                      ]}
                      placeholder="Choose status"
                      value={status}
                      onChange={handleStatusChange}
                      className="form-select mt-1"
                      classNamePrefix="select"
                    />
                    <span className="text-redCustom-3b text-xs">
                      {errors?.is_active?.message as string}
                    </span>
                  </div>
                </div>
              </>
            )}
          </ArticleCard>
          <PortalButton
            type="button"
            className="min-w-[140px]"
            onClick={() => {
              paramsURL.id
                ? setConfirmUpdateModal(true)
                : setConfirmAddModal(true);
            }}
          >
            {paramsURL.id ? 'Update' : 'Add'}
          </PortalButton>
          <Modal
            show={confirmAddModal}
            title={'Do you want to add this account?'}
            onClose={() => {
              setConfirmAddModal(false);
            }}
            className="max-w-[420px] font-Roboto"
          >
            <div className="flex space-x-4">
              <PortalButton
                type="button"
                className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                onClick={() => {
                  setConfirmAddModal(false);
                }}
              >
                Cancel
              </PortalButton>
              <PortalButton
                type="submit"
                className="flex justify-center w-full md:w-52"
                disabled={submitAdd}
                onClick={handleSubmit(submitHandler, onError)}
              >
                {submitAdd && <CircleSpin />}
                Submit
              </PortalButton>
            </div>
          </Modal>
          <Modal
            show={confirmUpdateModal}
            title={'Confirm Update'}
            onClose={() => {
              setConfirmUpdateModal(false);
            }}
            className="max-w-[420px] font-Roboto"
          >
            <div className="flex space-x-4">
              <PortalButton
                type="button"
                className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                onClick={() => {
                  setConfirmUpdateModal(false);
                }}
              >
                Cancel
              </PortalButton>
              <PortalButton
                type="submit"
                className="flex justify-center w-full md:w-52"
                disabled={submitUpdate}
                onClick={handleSubmit(submitHandler, onError)}
              >
                {submitUpdate && <CircleSpin />}
                Submit
              </PortalButton>
            </div>
          </Modal>
        </form>
      </div>
    </>
  );
};

export default AccountManagementAdd;
