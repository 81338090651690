export const countRemainingDays = (endDate: string) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 999);
  const end = new Date(endDate);
  end.setHours(23, 59, 59, 999);
  const diff = end.getTime() - currentDate.getTime();
  return Math.ceil(diff / (1000 * 60 * 60 * 24)) < 0
    ? 0
    : Math.ceil(diff / (1000 * 60 * 60 * 24));
};

export const checkComingSoon = (fromDate: string) => {
  return new Date(fromDate) > new Date();
};

export const countComingSoonDays = (startDate: string) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 999);
  const start = new Date(startDate);
  start.setHours(0, 0, 0, 999);
  const diff = start.getTime() - currentDate.getTime();
  return Math.ceil(diff / (1000 * 60 * 60 * 24)) < 0
    ? 0
    : Math.ceil(diff / (1000 * 60 * 60 * 24));
};

export const checkFinishedBook = (endDate: string) => {
  return `Completed on 
    ${new Date(endDate).getDate()} ${new Date(endDate).toLocaleString('en', {
    month: 'short',
    year: '2-digit'
  })}`;
};

export const processBook = (
  processBookListen: number,
  processBookRead: number
) => {
  let processBook = 0;
  if (
    (processBookListen || processBookListen === 0) &&
    !processBookRead &&
    processBookRead !== 0
  ) {
    processBook = processBookListen;
  } else if (
    !processBookListen &&
    processBookListen !== 0 &&
    (processBookRead || processBookRead === 0)
  ) {
    processBook = processBookRead;
  } else if (
    (processBookListen || processBookListen === 0) &&
    (processBookRead || processBookRead === 0)
  ) {
    processBook = Math.round((processBookListen + processBookRead) / 2);
  } else {
    processBook = 0;
  }

  return processBook > 100 ? 100 : processBook;
};

export const getAssignStatus = (item: any) => {
  const isFinishRead = item?.current_page_count >= item?.total_page_count;
  const isFinishListen = item?.current_cover_count >= item?.total_cover_count;
  if (isFinishRead && isFinishListen) {
    return 'Completed';
  } else {
    if (countRemainingDays(item?.to_date) === 0) {
      return 'Unfinished';
    } else if (checkComingSoon(item?.from_date)) {
      return 'Coming Soon';
    } else {
      return 'Learning';
    }
  }
};

export const processReadOrListen = (
  pageComplete: number,
  totalPage: number
) => {
  const process = Math.round((pageComplete / totalPage) * 100);
  return process > 100 ? 100 : process;
};
