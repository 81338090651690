import React from 'react';
import { Link } from 'react-router-dom';
import { RightArrow } from 'assets/icons/Index';

type BreadcrumbProps = {
  crums: Array<{ url?: string; name: string; selected?: boolean }>;
};

export const Breadcrumb = ({ crums }: BreadcrumbProps) => {
  return (
    <>
      <div className="flex flex-wrap gap-x-1 items-center text-sm font-normal text-yellow_3 font-Roboto">
        {crums.map(crum => {
          return (
            <>
              {crum?.selected ? (
                <p>{crum?.name}</p>
              ) : (
                <Link to={crum?.url || ''} title={crum?.name}>
                  <p>{crum?.name}</p>
                </Link>
              )}
              <div className="last:hidden">
                <RightArrow color="#a2825c" className="!w-[18px] !h-[18px]" />
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
