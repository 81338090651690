import Routes from '@core/routes/Routes';
import ContainerLayout from '@shared/layouts/ContainerLayout';
import { useLocation } from 'react-router-dom';

const App: React.FC = () => {
  const url = useLocation().pathname;

  let admin: any = localStorage.getItem('admin');

  return (
    <div className="h-[100vh]">
      {url.split('/')[1] !== 'portal' ? (
        <Routes />
      ) : !admin ? (
        <Routes />
      ) : (
        <ContainerLayout>
          <Routes />
        </ContainerLayout>
      )}
    </div>
  );
};

export default App;
