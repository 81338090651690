export type AuthLoginType = {
  user_name: string;
  password: string;
};

export type UserType = {
  code: string;
  data: string;
  message: string;
  status: number;
};

export type ChangePasswordType = {
  old_password: string;
  new_password: string;
};

export enum AccoutType {
  PREMIUM = 'Premium',
  BASIC = 'Basic',
}
