import React, { useEffect, useState } from 'react';
import { LoginFrom } from 'types/data.type';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@shared/components/Index';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa6';
import { AuthLoginType } from 'types/Auth.type';
import AuthService from '@core/services/auth.service';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { HiOutlineLockClosed, HiOutlineMail } from 'react-icons/hi';
import { MdError } from 'react-icons/md';
import { LoginIcon } from 'assets/icons/LoginIcon';
import Logo from 'assets/imgs/logo.svg';
import Banner from 'assets/imgs/LoginBanner.svg';
import BannerPortal from 'assets/imgs/portal-banner.png';

export const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    trigger,
    formState: { errors }
  } = useForm<LoginFrom>({
    defaultValues: {
      user_name: '',
      password: ''
    },
    mode: 'onChange'
  });
  const [isChecked, setIsChecked] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const accountUserLocal = localStorage.getItem('account_user');

  const onSubmit: SubmitHandler<AuthLoginType> = async ({
    user_name,
    password
  }: AuthLoginType) => {
    setLoading(true);
    const user = {
      user_name: user_name,
      password: password
    };

    const res = await AuthService.login_admin(user);

    if (res) {
      if (res?.status !== -1) {
        const tokenInfo: any = jwtDecode(res?.data);
        const adminInfo = {
          access_token: res?.data,
          admin_id: tokenInfo?.admin_id,
          role: tokenInfo?.is_system_admin === 'true' ? 'Admin' : 'Teacher'
        };
        localStorage.setItem('admin', JSON.stringify(adminInfo));
        if (isChecked) {
          const accountAdmin = {
            user_name: user_name,
            password: password
          };
          localStorage.setItem('account_admin', JSON.stringify(accountAdmin));
        } else {
          localStorage.removeItem('account_admin');
        }
        navigate('/portal/student-management');
      } else {
        setError('password', {
          type: 'custom',
          message: 'Username or password is incorrect!'
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    let admin: any = localStorage.getItem('admin');

    if (admin) {
      navigate('/portal/student-management');
    } else {
      navigate('/portal/login');
    }
  }, [navigate]);

  const inputStyle =
    'border border-yellow_2 border-solid p-[10px] text-base font-normal text-yellow_3 rounded w-[100%] h-[42px] mt-1';
  const errorStyle = 'text-red-500 text-sm font-normal leading-5 mt-1 text-red';

  // const crums = [
  //   {
  //     name: 'Home',
  //     url: '/'
  //   },
  //   {
  //     name: 'My account',
  //     url: '/my-account',
  //     selected: true
  //   }
  // ];

  useEffect(() => {
    if (accountUserLocal) {
      setUserName(JSON.parse(accountUserLocal).user_name);
      setValue('user_name', JSON.parse(accountUserLocal).user_name);
      setPassword(JSON.parse(accountUserLocal).password);
      setValue('password', JSON.parse(accountUserLocal).password);
      setIsChecked(true);
    }
  }, [accountUserLocal, setValue]);

  const handleRememberMe = (e: any) => {
    setIsChecked(e.target.checked);
  };

  const onChangeUserName = (e: any) => {
    setUserName(e.target.value);
  };

  const onChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  return (
    <div className="flex justify-center items-center h-full w-full bg-white">
      <div className="flex flex-col gap-8">
        <div className="relative flex justify-center lg:justify-between w-full lg:w-[992px] bg-F3FAFA py-[64px] px-10 rounded-2xl">
          <div className="absolute top-[31px] right-[31px] hidden lg:block">
            <img alt="logo" src={Logo} className="w-[65px] h-[65px]" />
          </div>
          <div className="p-6 rounded-2xl bg-white w-full lg:w-[391px] h-[422px] flex flex-col shadow-xl">
            <h1 className="text-[22px] font-medium text-545454">Login Admin</h1>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="mt-4 flex flex-1 flex-col"
            >
              <div className="flex flex-col font-Roboto gap-4 flex-1">
                <div className="flex flex-col relative">
                  <div className="absolute left-3 top-[12.5px]">
                    <HiOutlineMail />
                  </div>
                  <input
                    id="user_name"
                    type="text"
                    className={`form-input !pl-[36px] !h-[40px] !items-center ${
                      errors.user_name
                        ? '!outline-F86862 focus:!outline-blue_2'
                        : ''
                    }`}
                    placeholder="User name"
                    value={userName}
                    {...register('user_name', {
                      required: 'Please enter your username or email address',
                      onChange: e => {
                        trigger('user_name');
                        onChangeUserName(e);
                      }
                    })}
                  />
                  {errors.user_name && (
                    <div className="flex gap-[2px] items-center mt-1 text-D92D20">
                      <MdError className="text-D92D20" />
                      <p className={`input-error`}>
                        {errors.user_name?.message}
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex flex-col relative">
                  <div className="absolute left-3 top-[12.5px]">
                    <HiOutlineLockClosed />
                  </div>
                  <input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    className={`form-input !pl-[36px] !h-[40px] ${
                      errors.password
                        ? '!outline-F86862 focus:!outline-blue_2'
                        : ''
                    }`}
                    placeholder="Password"
                    {...register('password', {
                      required: 'Please enter your password',
                      onChange: e => {
                        trigger('password');
                        onChangePassword(e);
                      }
                    })}
                  />
                  <div
                    className="absolute w-4 h-4 right-3 top-[12.5px] cursor-pointer"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  </div>
                  {errors.password && (
                    <div className="flex gap-[2px] items-center mt-1 text-D92D20">
                      <MdError className="text-D92D20" />
                      <p className={`input-error`}>
                        {errors.password?.message}
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex gap-x-1 items-center mb-2">
                  <input
                    type="checkbox"
                    id="remember"
                    checked={isChecked}
                    className={`outline-none w-4 h-4`}
                    onChange={handleRememberMe}
                  />
                  <label
                    htmlFor="remember"
                    className="text-sm text-545454 font-medium"
                  >
                    Remember me
                  </label>
                </div>
                <div className="flex flex-1 items-end">
                  <Button
                    type="submit"
                    className={` ${
                      loading ? 'form-button-loading' : 'form-button'
                    } !h-[40px] !py-0`}
                  >
                    {!loading && <LoginIcon />}

                    <p className="text-F3FAFA">
                      {loading ? 'Logging in....' : 'Log In'}
                    </p>
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <div className="items-end hidden lg:flex w-[60%]">
            <img src={BannerPortal} alt="login banner" />
          </div>
        </div>
        <div className="mx-auto md:mx-0 md:w-full">
          <p className="text-xs font-medium text-blue_2">
            2024 @Copyright to QMC
          </p>
        </div>
      </div>
    </div>
  );
};
