import React from 'react';

interface AudioProgressBarProps
  extends React.ComponentPropsWithoutRef<'input'> {
  duration: number;
  currentProgress: number;
  buffered: number;
}

export const AudioProgressBar = (props: AudioProgressBarProps) => {
  const { duration, currentProgress, buffered, ...rest } = props;
  return (
    <div className="flex-1 group flex items-center">
      <input
        type="range"
        name="progress"
        className="w-full appearance-none rounded-full h-2 bg-DEDEDE"
        min={0}
        max={Math.ceil(duration)}
        value={Math.ceil(currentProgress)}
        {...rest}
      />
    </div>
  );
};
