import React, { useEffect, useState } from 'react';
import { Loading, Slider, SliderBookAssign } from '@shared/components/Index';
import BooksService from '@core/services/books.service';
import NewsService from '@core/services/news.service';
import { book, news } from 'types/data.type';
import { AccoutType } from 'types/Auth.type';

export const HomePage = () => {
  const [books, setBooks] = useState<book[]>([]);
  const [news, setNews] = useState<news[]>();
  const [booksAssign, setBooksAssign] = useState<book[]>([]);
  const [loadingBooks, setLoadingBooks] = useState(false);
  const [loadingNews, setLoadingNews] = useState(false);
  const [loadingBooksAssign, setLoadingBooksAssign] = useState(false);
  const accountType = localStorage.getItem('user_type');
  const user: any = localStorage.getItem('user');

  useEffect(() => {
    setLoadingBooks(true);
    setLoadingNews(true);
    setLoadingBooksAssign(true);
    const fetchData = async () => {
      const params = {
        page_index: 1,
        page_size: 10
      };
      const resBooksAssign = await BooksService.getUserBooks(params);
      if (resBooksAssign) {
        setBooksAssign(resBooksAssign?.data?.items);
        setLoadingBooksAssign(false);
      }

      const resBooks = await BooksService.getAllBooks(params);
      if (resBooks) {
        setBooks(resBooks?.data?.items);
        setLoadingBooks(false);
      }

      if (!user) {
        const resNews = await NewsService.getAllNewsHome(params);

        if (resNews) {
          setNews(resNews?.data?.items);
          setLoadingNews(false);
        }
      } else {
        const resNews = await NewsService.getAllNews(params);

        if (resNews) {
          setNews(resNews?.data?.items);
          setLoadingNews(false);
        }
      }
    };

    fetchData();
  }, [user]);

  return (
    <>
      <div className="container">
        {accountType === AccoutType.PREMIUM && (booksAssign?.length > 0) && (
          <section className="p-4">
            {loadingBooksAssign ? (
              <Loading />
            ) : (
              <div>
                <SliderBookAssign items={booksAssign} title="My Learning" />
              </div>
            )}
          </section>
        )}
        <section className="p-4">
          {loadingBooks ? (
            <Loading />
          ) : (
            <Slider items={books} type="book-corner" title="Book Corner" />
          )}
        </section>
        <section className="mt-4 p-4">
          {/* <h1 className="text-[32px] font-bold leading-10 text-blue_2 mb-2 pl-[10px]">
            News
          </h1> */}
          {loadingNews ? (
            <Loading />
          ) : (
            <Slider items={news || []} type="news" title="News" />
          )}
        </section>
      </div>
    </>
  );
};
