import React from 'react';
import { Link } from 'react-router-dom';

type PortalButtonProps = {
  className?: string;
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  url?: string;
  disabled?: boolean;
};

export const PortalButton = ({
  className,
  children,
  type,
  onClick,
  url,
  disabled
}: PortalButtonProps) => {
  return (
    <>
      {!url ? (
        <button
          type={type || 'button'}
          onClick={onClick}
          disabled={disabled}
          className={`h-10 p-6 rounded-[50px] font-bold flex justify-center items-center cursor-pointer bg-Maximum_Blue text-black ${
            disabled ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-80'
          } ${className}`}
        >
          {children}
        </button>
      ) : (
        <Link
          to={url}
          className={`h-10 p-6 rounded-[50px] font-bold flex justify-center items-center cursor-pointer bg-Maximum_Blue text-black hover:opacity-80 ${className}`}
        >
          {children}
        </Link>
      )}
    </>
  );
};
