import React, { ReactNode } from 'react';
import no_item_banner from 'assets/imgs/no-item-banner.svg';

type EmptyProps = {
  children?: ReactNode;
};

export const Empty = ({ children }: EmptyProps) => {
  return (
    <div className="flex flex-col items-center mt-4 w-full">
      <img
        src={no_item_banner}
        alt="No item banner"
        className="w-[220px] h-[220px]"
      />
      {children}
    </div>
  );
};
