import React from 'react';

interface VolumeInputProps {
  volume: number;
  onVolumeChange: (volume: number) => void;
}
export const VolumeInput = (props: VolumeInputProps) => {
  const { volume, onVolumeChange } = props;
  return (
    <div className="group w-full">
      <input
        aria-label="volume"
        name="volume"
        type="range"
        min={0}
        step={0.05}
        max={1}
        value={volume}
        className="w-full h-2 rounded-full appearance-none cursor-pointer bg-DEDEDE"
        onChange={e => {
          onVolumeChange(e.currentTarget.valueAsNumber);
        }}
      />
    </div>
  );
};
