import MasterLayout from '@shared/layouts/MasterLayout';
import { ChangePassword } from 'pages/user/ChangePassword';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const AccountRoutes = () => {
  return (
    <Routes>
      <Route path="/change-password" element={<MasterLayout />}>
        <Route index element={<ChangePassword />} />
      </Route>
    </Routes>
  );
};
