import BooksManagementService from '@core/services/books.portal.service';
import { ArticleCard } from '@shared/components/ArticleCard';
import { LevelBox } from '@shared/components/LevelBox';
import BookPageListenDetail from '@shared/components/bookPage/BookPageListenDetail';
import BookPageReadDetail from '@shared/components/bookPage/BookPageReadDetail';
import Breadcrumb from '@shared/components/breadcrumb/Breadcrumb';
import TitlePage from '@shared/components/titlePage/TitlePage';
import { BookIcon } from 'assets/icons/BookIcon';
import { CircleSpin } from 'assets/icons/CircleSpin';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

const style = {
  tabActive:
    'font-bold bg-white border-l border-r border-t-primary-main border-t-[3px] border-gray-e0 rounded-t-[4px]'
};

const BookPages = () => {
  const paramsURL = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [book, setBook] = useState<any>();

  const [bookStyle, setBookStyle] = useState<any>();

  const [pagesByStyleSelected, setPagesByStyleSelected] = useState<any>([]);
  const [pageSeLected, setPageSeLected] = useState<any>();

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const [crumbs] = useState([
    {
      name: 'Book Management',
      url: `/portal/books-management`
    },
    {
      name: `${paramsURL.id ? 'Editing Books' : 'Create Book'}`,
      url: `${
        paramsURL.id
          ? `/portal/books-management/${paramsURL.id}`
          : '/portal/books-management'
      }`
    }
  ]);

  const getDetailBookByID = async () => {
    setLoading(true);
    const res = await BooksManagementService.getDetailBook(
      paramsURL.id as string
    );
    if (res?.code === 'Ok') {
      setBook(res?.data);
      if (res?.data?.book_cover_details?.length > 0) {
        setBookStyle({
          value: 'Read',
          label: 'Read'
        });
      } else if (res?.data?.book_cover_details?.length > 0) {
        setBookStyle({
          value: 'Listen',
          label: 'Listen'
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (paramsURL.id) {
      getDetailBookByID();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (bookStyle?.value === 'Listen') {
      setPagesByStyleSelected(book?.book_page_details);
    }
    if (bookStyle?.value === 'Read') {
      setPagesByStyleSelected(book?.book_cover_details);
    }
  }, [bookStyle]);

  const handleBookStyleChange = (value: any) => {
    setBookStyle(value);
    setPageSeLected(undefined);
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage
        icon={() => <BookIcon />}
        name={`${paramsURL.id ? 'Editing Books' : 'Create Book'}`}
      />
      <div className="mt-10">
        {paramsURL.id && (
          <div className="flex">
            <div
              className={`p-[10px] min-w-[110px] text-center cursor-pointer ${
                location.pathname.includes('book-page') ? '' : style.tabActive
              }`}
            >
              <Link to={`/portal/books-management/edit-book/${paramsURL.id}`}>
                Book
              </Link>
            </div>
            <div
              className={`p-[10px] min-w-[110px] text-center cursor-pointer ${
                location.pathname.includes('book-page') ? style.tabActive : ''
              }`}
            >
              <Link
                to={`/portal/books-management/edit-book/${paramsURL.id}/book-page`}
              >
                Book page
              </Link>
            </div>
          </div>
        )}
        <ArticleCard
          className={`${paramsURL.id && 'border-t-0 rounded-t-none'}`}
        >
          {loading ? (
            <div className="flex justify-center items-center">
              <CircleSpin />
            </div>
          ) : (
            <div>
              <div className=" text-xl font-bold my-5">{`${book?.title} - ${book?.id}`}</div>
              <div className="flex items-center gap-5">
                <div>Book style</div>
                <div className="w-[200px]">
                  <Select
                    options={[
                      { value: 'Listen', label: 'Listen' },
                      { value: 'Read', label: 'Read' }
                    ]}
                    placeholder="Choose style"
                    value={bookStyle}
                    onChange={handleBookStyleChange}
                    className="form-select mt-1"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <div className="mt-5 flex gap-5 items-center">
                <div>Book pages:</div>
                <div className="flex flex-wrap gap-3">
                  {pagesByStyleSelected?.map((item: any, index: number) => {
                    const orderNumber =
                      bookStyle?.value === 'Listen'
                        ? item?.page_order
                        : item?.cover_order;
                    const selectedOrderNumber =
                      bookStyle?.value === 'Listen'
                        ? pageSeLected?.page_order
                        : pageSeLected?.cover_order;
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setPageSeLected(item);
                        }}
                      >
                        <LevelBox
                          className={`${
                            selectedOrderNumber === orderNumber
                              ? '!bg-yellow_2'
                              : ''
                          }`}
                        >
                          {orderNumber}
                        </LevelBox>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-5">
                {bookStyle?.value === 'Listen' && pageSeLected && (
                  <BookPageListenDetail
                    bookPage={pageSeLected}
                    bookId={paramsURL.id || ''}
                  />
                )}
                {bookStyle?.value === 'Read' && pageSeLected && (
                  <BookPageReadDetail
                    bookPage={pageSeLected}
                    bookId={paramsURL.id || ''}
                  />
                )}
              </div>
            </div>
          )}
        </ArticleCard>
      </div>
    </>
  );
};

export default BookPages;
