import { BoxContainer, Button, Loading } from '@shared/components/Index';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import NewsService from '@core/services/news.service';
import { getAllNewsType, newsDetailData, responseType } from 'types/data.type';
import moment from 'moment';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SaveIcon } from 'assets/icons/SaveIcon';
import { jwtDecode } from 'jwt-decode';

const NewDetail = () => {
  const [loading, setLoading] = useState(false);
  const [newsDetail, setNewsDetail] = useState<newsDetailData>();
  const params = useParams();
  const newsID = params?.id || '';
  const url = useLocation().pathname;
  const navigate = useNavigate();
  const user: any = localStorage.getItem('user');
  const [news, setNews] = useState<responseType>();

  const userJsonInfo = JSON.parse(user);

  let token = '';
  let userInfo: any = null;
  let studentID = '';

  if (userJsonInfo) {
    token = userJsonInfo?.data;
    userInfo = jwtDecode(token);
    studentID = userInfo?.student_id;
  }

  const getDetailNews = async (newsID: string) => {
    setLoading(true);
    if (user) {
      const res = await NewsService.getDetailNews(newsID);
      if (res) {
        setNewsDetail(res?.data);
        setLoading(false);
      }
    } else {
      const res = await NewsService.getDetailNewsHome(newsID);
      if (res) {
        setNewsDetail(res?.data);
        setLoading(false);
      }
    }
  };

  const readNews = async (newsID: string, studentID: string) => {
    const params = {
      news_id: newsID,
      studen_id: studentID
    };

    const res = await NewsService.postReadNews(params);
    if (res) {
      console.log(res);
    }
  };

  const fetchNewsData = async (params: getAllNewsType) => {
    setLoading(true);
    if (user) {
      const resBooks = await NewsService.getAllNews(params);
      if (resBooks) {
        setNews(resBooks?.data);
        setLoading(false);
      }
    } else {
      const resBooks = await NewsService.getAllNewsHome(params);
      if (resBooks) {
        setNews(resBooks?.data);
        setLoading(false);
      }
    }
  };

  const handleLogin = () => {
    navigate(`/login?redirectTo=${url}`);
  };

  useEffect(() => {
    getDetailNews(newsID);
    if (user) {
      readNews(newsID, studentID);
    }
  }, [newsID, studentID, user]);

  useEffect(() => {
    fetchNewsData({ page_index: 1, page_size: 10 });
  }, []);

  return (
    <>
      <div className="w-full px-4 md:px-0 md:w-[710px] my-10 md:mx-auto">
        {loading ? (
          <Loading />
        ) : (
          <>
            <h1 className="text-[28px] font-medium leading-10 text-[#363636]">
              {newsDetail?.title}
            </h1>
            <div className=" text-sm font-light text-[#363636] mb-6 mt-2">
              {moment(newsDetail?.created_time).format('MMMM DD, YYYY')}
            </div>
            <div className="flex flex-col gap-8 lg:flex-row">
              <div className="flex flex-col gap-10 mb-10">
                {newsDetail?.link_soundcloud && (
                  <div className="p-4 border border-EDEDED rounded-[16px]">
                    <iframe
                      title={newsDetail?.title}
                      width="100%"
                      height="166"
                      allow="autoplay"
                      src={newsDetail?.link_soundcloud || ''}
                    ></iframe>
                  </div>
                )}
                <p
                  className=" text-lg font-normal leading-[24px] text-[#363636]"
                  dangerouslySetInnerHTML={{
                    __html: newsDetail?.content || ''
                  }}
                ></p>
                {!user && (
                  <p className="font-Roboto">
                    <span
                      className="text-blue_2 cursor-pointer"
                      onClick={() => {
                        handleLogin();
                      }}
                    >
                      Login here
                    </span>{' '}
                    to get more detail.
                  </p>
                )}

                {newsDetail?.link_youtube && (
                  <div className="p-4 border border-EDEDED rounded-[16px]">
                    <iframe
                      title={newsDetail?.title}
                      // width="100%"
                      height="400"
                      className='w-full rounded-[8px]'
                      allow="autoplay"
                      src={newsDetail?.link_youtube || ''}
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="mt-[48px] mx-4 lg:max-w-[1216px] xl:mx-auto mb-8">
        <h2 className="font-medium text-[28px] text-center">
          Other latest news
        </h2>
        <div className="relative mt-4">
          <div className="hidden min-[1380px]:flex gap-x-[10px] w-full justify-between">
            <Button className="swiper-button-prev md:w-12 md:h-12 !bg-F3FAFA rounded-[4px] !p-0 absolute top-[50%] xl:-left-[80px] z-50">
              <LiaAngleLeftSolid size={20} color="#235E5A" />
            </Button>
            <Button className="swiper-button-next md:w-12 md:h-12 !bg-F3FAFA rounded-[4px] !p-0 absolute top-[50%] xl:-right-[80px] z-50">
              <LiaAngleRightSolid size={20} color="#235E5A" />
            </Button>
          </div>
          <Swiper
            loop={true}
            spaceBetween={16}
            autoplay={false}
            speed={500}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }}
            breakpoints={{
              500: {
                slidesPerView: 2
              },
              768: {
                slidesPerView: 3
              }
            }}
            touchEventsTarget="wrapper"
            modules={[Autoplay, Pagination, Navigation]}
            className="flex relative sliderBookAssign"
          >
            {news?.items?.map((item: any) => {
              return (
                <SwiperSlide
                  key={item?.id}
                  className="!flex justify-center w-full"
                >
                  <Link
                    to={`/news/detail/${item?.id}`}
                    key={item.id}
                    className="w-full"
                  >
                    <BoxContainer className="w-full">
                      <div className="flex flex-col gap-[5px] w-full">
                        <div className="w-full lg:h-[158px]">
                          <img
                            src={item?.cover_img}
                            alt="Book Img"
                            className="object-cover h-full w-full rounded-lg"
                          />
                        </div>
                        <div className="w-full">
                          <p className="text-base font-medium h-[48px] line-clamp-2">
                            {item?.title}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="text-676767 text-[12px] font-medium">
                            {moment(item?.created_time).format('DD MMMM, YYYY')}
                          </p>
                          {/* <div
                            className="cursor-poiter"
                            onClick={e => e.stopPropagation()}
                          >
                            <SaveIcon />
                          </div> */}
                        </div>
                      </div>
                    </BoxContainer>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default NewDetail;
