import React from 'react';
import { Breadcrumb } from './Breadcrumb';

type SubNavProps = {
  title: string;
  crums: Array<{ url?: string; name: string; selected?: boolean }>;
};

export const SubNav = ({ title, crums }: SubNavProps) => {
  return (
    <div className="border-t-[7px] border-solid border-yellow_2 pt-[2px] pb-[6px] bg-yellow_1">
      <div className="container">
        <h1 className="text-2xl font-bold leading-[1.16] text-yellow_3">
          {title}
        </h1>
        <div className="mt-2">
          <Breadcrumb crums={crums} />
        </div>
      </div>
    </div>
  );
};
