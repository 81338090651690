import React from 'react';

type LevelBoxProps = {
  children: React.ReactNode;
  className?: string;
};

export const LevelBox = ({ children, className }: LevelBoxProps) => {
  return (
    <div
      className={`w-7 h-7 px-1 flex justify-center items-center bg-yellow_1 font-Roboto text-base font-normal leading-[22px] text-yellow_3 rounded-md cursor-pointer ${className}`}
    >
      {children}
    </div>
  );
};
