import { getAllBooksType, readPageType, searchAllType } from 'types/data.type';
import axiosInstance from './restful.service';
import {
  BOOK_MANAGEMENT,
  BOOKS,
  HOST_URL,
  HOST_URL_PORTAL,
  SEARCH
} from '@core/constant/api.contant';
import axiosInstance_admin from './restful.portal.service';

const getAllBooks = (params: getAllBooksType) => {
  return axiosInstance
    .get(HOST_URL + BOOKS.GET_ALL, { params })
    .then(response => {
      return response?.data;
    });
};

const getUserBooks = (params: getAllBooksType) => {
  return axiosInstance
    .get(HOST_URL + BOOKS.GET_USER_BOOKS, { params })
    .then(response => {
      return response?.data;
    });
};

const searchAll = (params: searchAllType) => {
  return axiosInstance
    .get(HOST_URL + SEARCH.SEARCH_ALL, { params })
    .then(response => {
      return response?.data;
    });
};

const getDetailBook = (id: string) => {
  return axiosInstance
    .get(HOST_URL + BOOKS.GET_BOOK_BY_ID(id))
    .then(response => {
      return response?.data;
    });
};

const getDetailBookHome = (id: string) => {
  return axiosInstance
    .get(HOST_URL + BOOKS.GET_BOOK_BY_ID_HOME(id))
    .then(response => {
      return response?.data;
    });
};

const postReadPage = (params: readPageType) => {
  return axiosInstance
    .post(HOST_URL + BOOKS.READ_BOOK, params)
    .then(response => {
      return response?.data;
    });
};

const getListCategory = () => {
  return axiosInstance
    .get(HOST_URL + BOOKS.GET_LIST_CATEGORY)
    .then(response => {
      return response?.data;
    });
};

const getListCategoryPortal = () => {
  return axiosInstance_admin
    .get(HOST_URL_PORTAL + BOOK_MANAGEMENT.GET_LIST_CATEGORY)
    .then(response => {
      return response.data;
    });
};

const BooksService = {
  getAllBooks,
  searchAll,
  getUserBooks,
  getDetailBook,
  getDetailBookHome,
  postReadPage,
  getListCategory,
  getListCategoryPortal
};

export default BooksService;
