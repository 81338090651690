import FilterButton from '@shared/components/FilterButton';
import ItemsPerPage from '@shared/components/ItemsPerPage';
import SearchButton from '@shared/components/SearchButton';
import Breadcrumb from '@shared/components/breadcrumb/Breadcrumb';
import PanelSiderBar from '@shared/components/panelSiderBar/PanelSiderBar';
import TitlePage from '@shared/components/titlePage/TitlePage';
import { CircleSpin, ClassroomIcon } from 'assets/icons/Index';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { bookRes } from 'types/data.type';
import ClassManagementService from '@core/services/class.portal.service';
import moment from 'moment';
import { FORM_FORMAT } from '@core/constant/form.const';
import PaginationOwn from '@shared/components/Pagination';
import { AiOutlineEdit } from 'react-icons/ai';
import { PortalButton } from '@shared/components/button/PortalButton';

const ClassManagement = () => {
  const navigate = useNavigate();
  const [crumbs] = useState([
    { name: 'Class Management', url: '/portal/class-management' }
  ]);
  const [keyword, setKeyword] = useState<string>();
  const [reloadPage, setReloadPage] = useState<any>();
  const [openedFilter, setOpenedFilter] = useState(false);
  const [itemPerPage, setItemPerPage] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<any>({});
  const [classesData, setClassesData] = useState<bookRes>();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const { register, handleSubmit, trigger, reset } = useForm<any>();

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const getData = async (params: any) => {
    setLoading(true);
    const res = await ClassManagementService.getAllClasses(params);

    if (res?.status === 1) {
      setClassesData(res?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (page) {
      setCurrentPage(Number(page));
      let params = {
        ...filters,
        page_index: Number(page),
        page_size: itemPerPage
      };
      if (keyword) {
        params = {
          ...params,
          search_key: keyword
        };
      }

      getData(params);
    }
  }, [filters, itemPerPage, reloadPage, page]);

  const onSearch = (reset?: boolean) => {
    navigate(`/portal/class-management`);
    const newKeywork = reset ? '' : keyword?.trim();
    setKeyword(newKeywork);
    setReloadPage(!reloadPage);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
      onSearch();
    }
  };

  const toggleFilter = () => {
    setOpenedFilter(!openedFilter);
  };

  const handlePageSizeChange = (value: any) => {
    navigate(`/portal/class-management`);
    setItemPerPage(value);
  };

  const submitHandler: SubmitHandler<any> = async value => {
    let filerValues = value;

    setOpenedFilter(false);
    navigate(`/portal/class-management`);
    setFilters(filerValues);
  };

  const clearFilter = () => {
    reset();
    setFilters({});
    setOpenedFilter(false);
    navigate(`/portal/class-management`);
  };

  const onPageChange = useCallback(async (event: number) => {
    navigate(`/portal/class-management?page=${event}`);
  }, []);

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <div className="flex items-center justify-between">
        <TitlePage icon={() => <ClassroomIcon />} name="Class Management" />
        <div className="flex">
          <div className="mr-3">
            <PortalButton url="/portal/class-management/add-class">
              Create Class
            </PortalButton>
          </div>
        </div>
      </div>
      <div className="mt-7">
        <div className="flex">
          <div className="flex w-1/2 space-x-2">
            <div className="mt-3">
              <SearchButton
                onSearch={onSearch}
                keyword={keyword}
                setKeyword={setKeyword}
                _handleKeyDown={_handleKeyDown}
              />
            </div>
            <div className="space-x-2 mt-3">
              <FilterButton toggleFilter={toggleFilter} />
            </div>
          </div>
          <div className="ml-auto mt-5">
            <ItemsPerPage
              choice={itemPerPage}
              setChoice={(val: number) => {
                handlePageSizeChange(val);
              }}
            />
          </div>
        </div>
        <div className="w-full h-auto relative mt-2 overflow-auto rounded shadow">
          <table className="table w-full rounded-lg">
            <thead className="bg-gray-f2 border-b-2 border-gray-200">
              <tr>
                <th>STT</th>
                <th scope="col">
                  <span>ID</span>
                </th>
                <th scope="col">
                  <span>Name</span>
                </th>
                <th scope="col">
                  <span>Type</span>
                </th>
                <th scope="col">
                  <span>Create Time</span>
                </th>
                <th scope="col">
                  <span>Status</span>
                </th>
                <th scope="col">
                  <span>Action</span>
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {classesData?.items?.map((item: any, index: number) => {
                  return (
                    <tr key={item?.id}>
                      <th>{(currentPage - 1) * itemPerPage + index + 1}</th>
                      <th className="font-semibold" scope="row">
                        <span>{item?.id}</span>
                      </th>
                      <td className="order">
                        <span>{item?.name}</span>
                      </td>
                      <td>
                        <span>{item?.type}</span>
                      </td>
                      <td>
                        <span>
                          {moment(new Date(item.created_time)).format(
                            FORM_FORMAT.TABLE_DATEFULLYEAR
                          )}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`status status-${
                            item?.is_active ? 'active' : 'inactive'
                          }`}
                        >
                          {item?.is_active ? 'Active' : 'Inactive'}
                        </span>
                      </td>
                      <td>
                        <Link to={`edit-class/${item.id}`} className="">
                          <div className="table-action-btn table-action-edit w-fit">
                            <AiOutlineEdit />
                          </div>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        {classesData?.items?.length === 0 && !loading && (
          <p className="text-center text-sm mt-3">No account found</p>
        )}
        {classesData &&
          classesData?.total_results > itemPerPage &&
          !loading && (
            <div className="my-6 flex text-right justify-center">
              <PaginationOwn
                totalItems={classesData?.total_results}
                itemPerPage={itemPerPage}
                pageChange={onPageChange}
                pageCurrent={currentPage}
              />
            </div>
          )}
        {loading && (
          <>
            <div className="flex justify-center mt-4 items-center tex-sm">
              <CircleSpin color="text-primary-e2" /> Loading...
            </div>
          </>
        )}

        <PanelSiderBar
          open={openedFilter}
          close={() => {
            setOpenedFilter(false);
          }}
          submit={handleSubmit(submitHandler)}
          clear={clearFilter}
        >
          <form>
            <div className="flex space-x-2 form-group">
              <div className="w-1/2">
                <label className="text-sm">Class Type</label>
                <select
                  className="form-control mt-1"
                  {...register('type', {
                    required: false,
                    setValueAs: (value: string) => value?.trim(),
                    onChange: () => {
                      trigger('type');
                    }
                  })}
                >
                  <option value="">{'---'}</option>
                  <option value="Pronunciation">Pronunciation</option>
                  <option value="Advanced">Advanced</option>
                  <option value="ReadingPractice">ReadingPractice</option>
                </select>
              </div>
              <div className="w-1/2">
                <label className="text-sm">Class Status</label>
                <select
                  className="form-control mt-1"
                  {...register('is_active', {
                    setValueAs: (value: string) => value?.trim(),
                    onChange: () => {
                      trigger('is_active');
                    }
                  })}
                >
                  <option value="">{'---'}</option>
                  <option value="true">Active</option>
                  <option value="false">Isactive</option>
                </select>
              </div>
            </div>
          </form>
        </PanelSiderBar>
      </div>
    </>
  );
};

export default ClassManagement;
