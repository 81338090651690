import React from 'react';
import { Login } from 'pages/login/Login';
import { Route, Routes } from 'react-router-dom';
import MasterLayout from '@shared/layouts/MasterLayout';

export const UserInfoRoutes = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Login />} />
      </Route>
    </Routes>
  );
};
