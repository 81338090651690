import { CloseIcon } from 'assets/icons/CloseIcon';
import React from 'react';
import { BiSearch } from 'react-icons/bi';
import { placeholderCSS } from 'react-select/dist/declarations/src/components/Placeholder';

type SearchButtonProps = {
  onSearch: (status: boolean) => void;
  keyword?: string;
  setKeyword: (value: any) => void;
  _handleKeyDown: (value: any) => void;
  className?: string;
};

const SearchButton = ({
  onSearch,
  keyword,
  setKeyword,
  _handleKeyDown,
  className
}: SearchButtonProps) => {
  return (
    <div className={`relative ${className}`}>
      <button
        className="absolute left-[14px] top-[14px]"
        onClick={() => {
          onSearch(false);
        }}>
        <BiSearch />
      </button>
      <input
        id="search"
        type="text"
        required
        value={keyword ? keyword : ''}
        placeholder={'Keyword search...'}
        className="form-input-search !rounded-2xl outline-none"
        onChange={(e: any) => setKeyword(e.target.value)}
        onKeyDown={_handleKeyDown}
      />
      {keyword && (
        <button
          className="absolute right-2 top-[6px]"
          onClick={() => {
            setKeyword('');
            setTimeout(() => {
              onSearch(true);
            }, 200);
          }}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

export default SearchButton;
