import React from 'react';
import { cloneElement } from "react";
// import { useAuth } from "contexts/Auth/AuthContext";

const hasRole = (roleUser: string, scopes: string[]) => {
  return scopes.includes(roleUser);
}

export default function PermissionsGate({
  children,
  RenderError = () => <></>,
  errorProps = null,
  scopes = [],
  auth
}: {children: any, RenderError?: any, errorProps?: any, scopes: any, auth?: any}) {

  if (auth?.role) {
    const permissionGranted = hasRole(auth?.role, scopes);
    if (!permissionGranted && !errorProps) return <RenderError />;
    if (!permissionGranted && errorProps)
      return cloneElement(children, { ...errorProps });
    return <>{children}</>;
  }else {
    return <RenderError />
  }
  
}