import MasterLayout from '@shared/layouts/MasterLayout';
import ListAssign from 'pages/listAssign/ListAssign';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const ListAssignRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route index element={<ListAssign />} />
      </Route>
    </Routes>
  );
};
