import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RoutePortalProtection: React.FC<{ children: JSX.Element }> = ({
  children
}: {
  children: JSX.Element;
}) => {
  const location = useLocation();

  const user = localStorage.getItem('admin');
  
  if (!user) {
    return <Navigate to="/portal/login" state={{ from: location }} replace />;
  }

  const adminInfo: any = JSON.parse(user || '');
  
  if (location.pathname.includes('/portal/account-management') && (adminInfo.role !== 'Admin')) {
    return <Navigate to="/portal/student-management" state={{ from: location }} replace />;
  }

  return children;
};

export default RoutePortalProtection;
