import React from 'react';

type BoxContainerProps = {
  children: React.ReactNode;
  className?: string;
};

export const BoxContainer = ({ children, className }: BoxContainerProps) => {
  return (
    <div
      className={`bg-[#fff] border border-DEDEDE rounded-xl flex justify-center p-4 ${className}`}
    >
      {children}
    </div>
  );
};
