import FilterButton from '@shared/components/FilterButton';
import ItemsPerPage from '@shared/components/ItemsPerPage';
import PaginationOwn from '@shared/components/Pagination';
import SearchButton from '@shared/components/SearchButton';
import Breadcrumb from '@shared/components/breadcrumb/Breadcrumb';
import PanelSiderBar from '@shared/components/panelSiderBar/PanelSiderBar';
import TitlePage from '@shared/components/titlePage/TitlePage';
import { BookIcon } from 'assets/icons/BookIcon';
import { CircleSpin } from 'assets/icons/CircleSpin';
import 'react-datepicker/dist/react-datepicker.css';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { book, bookRes, category } from 'types/data.type';
import BooksManagementService from '@core/services/books.portal.service';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import Modal from '@shared/components/Modal';
import { toast } from 'react-toastify';
import { PortalButton } from '@shared/components/button/PortalButton';

const BooksManagement = () => {
  const navigate = useNavigate();
  const [crumbs] = useState([
    { name: 'Book Management', url: '/portal/books-management' }
  ]);
  const [keyword, setKeyword] = useState<string>();
  const [reloadPage, setReloadPage] = useState<any>();
  const [openedFilter, setOpenedFilter] = useState(false);
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<any>({});
  const [listCategory, setListCategory] = useState<category>();
  const [booksData, setBooksData] = useState<bookRes>();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [submitDelete, setSubmitDelete] = useState(false);
  const [bookId, setBookId] = useState<any>();

  const { register, handleSubmit, trigger, reset } = useForm<any>();

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const getData = async (params: any) => {
    setLoading(true);
    const res = await BooksManagementService.getAllBooks(params);

    if (res?.status === 1) {
      setBooksData(res?.data);
      setLoading(false);
    }
  };

  const fetchDataCategory = async () => {
    const resCategory = await BooksManagementService.getListCategory();
    if (resCategory) {
      setListCategory(resCategory?.data);
    }
  };

  useEffect(() => {
    fetchDataCategory();
  }, []);

  useEffect(() => {
    if (page) {
      setCurrentPage(Number(page));
      let params = {
        ...filters,
        page_index: Number(page),
        page_size: itemPerPage
      };
      if (keyword) {
        params = {
          ...params,
          search_key: keyword
        };
      }
      getData(params);
    }
  }, [filters, itemPerPage, page, reloadPage]);

  const onSearch = (reset?: boolean) => {
    navigate(`/portal/books-management`);
    const newKeywork = reset ? '' : keyword?.trim();
    setKeyword(newKeywork);
    setReloadPage(!reloadPage);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
      onSearch();
    }
  };

  const toggleFilter = () => {
    setOpenedFilter(!openedFilter);
  };

  const handlePageSizeChange = (value: any) => {
    navigate(`/portal/books-management`);
    setItemPerPage(value);
  };

  const submitHandler: SubmitHandler<any> = async value => {
    let filerValues = value;

    setOpenedFilter(false);
    navigate(`/portal/books-management`);
    setFilters(filerValues);
  };

  const clearFilter = () => {
    reset();
    setFilters({});
    setOpenedFilter(false);
    navigate(`/portal/books-management`);
  };

  const onPageChange = useCallback(async (event: number) => {
    navigate(`/portal/books-management?page=${event}`);
  }, []);

  const handleDeleteBook = async (id: string) => {
    setSubmitDelete(true);
    const res = await BooksManagementService.deleteBook(id);
    if (res?.status === 1) {
      toast.success('Delete success!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
    } else {
      toast.error(res?.message || 'Delete Fail! Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
    }
    setSubmitDelete(false);
    setConfirmDeleteModal(false);
    setReloadPage(!reloadPage);
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />

      <div className="flex items-center justify-between">
        <TitlePage icon={() => <BookIcon />} name="Book Management" />
        <div className="flex">
          <div className="mr-3">
            <PortalButton url="/portal/books-management/add-book">
              Create Book
            </PortalButton>
          </div>
        </div>
      </div>
      <div className="mt-7">
        <div className="flex">
          <div className="flex w-1/2 space-x-2">
            <div className="mt-3">
              <SearchButton
                onSearch={onSearch}
                keyword={keyword}
                setKeyword={setKeyword}
                _handleKeyDown={_handleKeyDown}
              />
            </div>
            <div className="space-x-2 mt-3">
              <FilterButton toggleFilter={toggleFilter} />
            </div>
          </div>
          <div className="ml-auto mt-5">
            <ItemsPerPage
              choice={itemPerPage}
              setChoice={(val: number) => {
                handlePageSizeChange(val);
              }}
            />
          </div>
        </div>
        <div className="w-full h-auto relative mt-2 overflow-auto rounded shadow">
          <table className="table w-full rounded-lg">
            <thead className="bg-gray-f2 border-b-2 border-gray-200">
              <tr>
                <th>STT</th>
                <th scope="col" className="text-center">
                  <span>Image</span>
                </th>
                <th scope="col">
                  <span>Title</span>
                </th>
                <th scope="col">
                  <span>Style</span>
                </th>
                <th>
                  <span>Category</span>
                </th>
                <th scope="col">
                  <span>Genre</span>
                </th>
                <th scope="col">
                  <span>Level</span>
                </th>
                <th scope="col">
                  <span>Type</span>
                </th>
                {/* <th scope="col">
                  <span>Created Time</span>
                </th>
                <th scope="col">
                  <span>Grade</span>
                </th> */}
                <th scope="col">
                  <span>Action</span>
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {booksData?.items?.map((item: book, index: number) => {
                  return (
                    <tr key={item?.id}>
                      <th>{(currentPage - 1) * itemPerPage + index + 1}</th>
                      <th
                        className="font-semibold flex justify-center"
                        scope="row"
                      >
                        <img
                          src={
                            item?.cover_listen_img
                              ? item?.cover_listen_img
                              : item?.cover_read_img
                          }
                          alt=""
                          className="h-[200px]"
                        />
                      </th>
                      <td className="order">
                        <span>{item?.title}</span>
                      </td>
                      <td>
                        <div className="flex flex-col gap-1">
                          <span className="client">
                            {item?.is_listen ? 'Listen' : '-'}
                          </span>
                          <span className="client">
                            {item?.is_read ? 'Read' : '-'}
                          </span>
                        </div>
                      </td>
                      <td className="order">
                        <span>{item?.category || '-'}</span>
                      </td>
                      <td className="order">
                        <span>{item?.genre || '-'}</span>
                      </td>
                      <td className="order">
                        <span>{item?.level || '-'}</span>
                      </td>
                      <td>
                        <span className="mb-0">{item?.type || '-'}</span>
                      </td>
                      {/* <td>
                        <span>
                          {moment(new Date(item?.created_time)).format(
                            FORM_FORMAT.TABLE_DATEFULLYEAR
                          )}
                        </span>
                      </td>
                      <td>
                        <span>{item?.grade || '-'}</span>
                      </td> */}
                      <td>
                        <div className="flex gap-1">
                          <Link to={`edit-book/${item.id}`}>
                            <div className="table-action-btn table-action-edit w-fit">
                              <AiOutlineEdit />
                            </div>
                          </Link>
                          <div
                            className="table-action-btn table-action-edit w-fit"
                            onClick={() => {
                              setConfirmDeleteModal(true);
                              setBookId(item.id);
                            }}
                          >
                            <AiOutlineDelete className="text-red" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        {booksData?.items?.length === 0 && !loading && (
          <p className="text-center text-sm mt-3">No account found</p>
        )}
        {booksData && booksData?.total_results > itemPerPage && !loading && (
          <div className="my-6 flex text-right justify-center">
            <PaginationOwn
              totalItems={booksData?.total_results}
              itemPerPage={itemPerPage}
              pageChange={onPageChange}
              pageCurrent={currentPage}
            />
          </div>
        )}
        {loading && (
          <>
            <div className="flex justify-center mt-4 items-center tex-sm">
              <CircleSpin color="text-primary-e2" /> Loading...
            </div>
          </>
        )}

        <PanelSiderBar
          open={openedFilter}
          close={() => {
            setOpenedFilter(false);
          }}
          submit={handleSubmit(submitHandler)}
          clear={clearFilter}
        >
          <form>
            <div className="flex space-x-2 form-group">
              <div className="w-1/2">
                <label className="text-sm">Category</label>
                <select
                  className="form-control mt-1"
                  {...register('category', {
                    required: false,
                    setValueAs: (value: string) => value?.trim(),
                    onChange: () => {
                      trigger('category');
                    }
                  })}
                >
                  <option value="">{'---'}</option>
                  {listCategory?.category?.map((item: any, index: number) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="w-1/2">
                <label className="text-sm">Genre</label>
                <select
                  className="form-control mt-1"
                  {...register('genre', {
                    setValueAs: (value: string) => value?.trim(),
                    onChange: () => {
                      trigger('genre');
                    }
                  })}
                >
                  <option value="">{'---'}</option>
                  {listCategory?.genre?.map((item: any, index: number) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="flex space-x-2 form-group">
              <div className="w-1/2">
                <label className="text-sm">Level</label>
                <select
                  className="form-control mt-1"
                  {...register('level', {
                    required: false,
                    setValueAs: (value: string) => value?.trim(),
                    onChange: () => {
                      trigger('level');
                    }
                  })}
                >
                  <option value="">{'---'}</option>
                  {listCategory?.level?.map((item: any, index: number) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="w-1/2">
                <label className="text-sm">Type</label>
                <select
                  className="form-control mt-1"
                  {...register('type', {
                    setValueAs: (value: string) => value?.trim(),
                    onChange: () => {
                      trigger('type');
                    }
                  })}
                >
                  <option value="">{'---'}</option>
                  {listCategory?.type?.map((item: any, index: number) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="flex form-group space-x-2"></div>
          </form>
        </PanelSiderBar>
      </div>
      <Modal
        show={confirmDeleteModal}
        title={'Confirm Delete'}
        onClose={() => {
          setConfirmDeleteModal(false);
        }}
        className="max-w-[420px] font-Roboto"
      >
        <div className="flex space-x-4">
          <PortalButton
            type="button"
            className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
            onClick={() => {
              setConfirmDeleteModal(false);
            }}
          >
            Cancel
          </PortalButton>
          <PortalButton
            type="submit"
            className="flex justify-center w-full md:w-52"
            disabled={submitDelete}
            onClick={() => {
              handleDeleteBook(bookId);
            }}
          >
            {submitDelete && <CircleSpin />}
            Submit
          </PortalButton>
        </div>
      </Modal>
    </>
  );
};

export default BooksManagement;
