import React from 'react';
import { Link } from 'react-router-dom';

type ButtonProps = {
  className?: string;
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  url?: string;
  style?: 'primary' | 'secondary' | 'disabled';
};

export const Button = ({
  className,
  children,
  type,
  onClick,
  url,
  style
}: ButtonProps) => {
  return (
    <>
      {!url ? (
        <button
          type={type || 'button'}
          onClick={onClick}
          className={`h-10 p-6 rounded-[6px] font-bold flex justify-center items-center cursor-pointer color-transition ${
            style === 'secondary'
              ? 'bg-F3FAFA py-0 text-235E5A hover:bg-D6F1EE focus:bg-C6F3EE'
              : style === 'disabled'
              ? 'bg-EDEDED text-999999'
              : 'bg-blue_2 py-0 text-F3FAFA hover:bg-35857E focus:bg-235E5A'
          } ${className}`}
        >
          {children}
        </button>
      ) : (
        <Link
          to={url}
          className={`h-10 p-6 rounded-[6px] font-bold flex justify-center items-center cursor-pointer ${
            style === 'secondary'
              ? 'bg-F3FAFA py-0 text-235E5A hover:bg-D6F1EE focus:bg-C6F3EE'
              : style === 'disabled'
              ? 'bg-EDEDED text-999999'
              : 'bg-blue_2 py-0 text-F3FAFA hover:bg-35857E focus:bg-235E5A'
          } color-transition ${className}`}
        >
          {children}
        </Link>
      )}
    </>
  );
};
