import React from 'react';

const Footer = () => {
  return (
    <div className="py-4 w-fit mx-auto md:mx-0 md:pl-20">
      <p className="text-xs text-blue_2 font-medium">
        Copyrights: @2023 QuangMinh English Center
      </p>
    </div>
  );
};

export default Footer;
