import { CircleSpin } from 'assets/icons/CircleSpin';
import React, { ReactNode } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { PortalButton } from '../button/PortalButton';

const PanelSiderBar = ({
  children,
  open = false,
  close,
  submit,
  clear,
  title,
  submitButtonName,
  onSubmitExport
}: {
  children: ReactNode;
  open: boolean;
  close: () => void;
  submit: () => void;
  clear?: () => void;
  title?: string;
  submitButtonName?: string;
  onSubmitExport?: boolean;
}) => {
  return (
    <>
      {open && (
        <>
          <div
            className="fixed w-full h-full bg-overlay-menu z-100 top-0 left-0"
            onClick={close}
          ></div>
          <div className="z-600 fixed h-full bg-[#fff] top-0 right-0 w-11/12 md:w-full max-w-[500px]">
            <div className="flex flex-col h-full">
              <div className="flex-none flex items-center text-black-100 font-semibold text-xl py-4 p-6">
                <span>{title ? title : 'Filter'}</span>
                <button className="ml-auto" onClick={close}>
                  <AiOutlineClose />
                </button>
              </div>
              <div className="flex-grow py-4 p-6 overflow-y-auto">
                {children}
              </div>
              <div className="flex-none flex justify-between bg-[#F2F2F2] py-4 p-6 z-50 space-x-2">
                <PortalButton
                  type="button"
                  className="flex justify-center w-full md:w-52 !bg-white border border-Maximum_Blue"
                  onClick={clear}
                >
                  Reset
                </PortalButton>
                <PortalButton
                  type="button"
                  onClick={submit}
                  disabled={onSubmitExport}
                  className="flex justify-center w-full md:w-52"
                >
                  {onSubmitExport && <CircleSpin />}
                  {submitButtonName ? submitButtonName : 'Done'}
                </PortalButton>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PanelSiderBar;
