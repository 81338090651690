export enum BookType {
  LEVEL,
  CATEGORY
}

export enum BookAssignType {
  NOT_ASSIGN = '0001-01-01T00:00:00'
}

export enum SliderType {
  START,
  END
}
