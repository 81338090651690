import React, { useEffect, useState } from 'react';
import { BoxContainer, Button, Empty, Loading } from '@shared/components/Index';
import { getAllNewsType, responseType } from 'types/data.type';
import { Link } from 'react-router-dom';
import NewsService from '@core/services/news.service';
import { IoSearchOutline } from 'react-icons/io5';
import { FaRegNewspaper } from 'react-icons/fa6';
import moment from 'moment';

const NewsList = () => {
  const [itemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [news, setNews] = useState<responseType>();
  const [newsItems, setNewsItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [stopLoadMore, setStopLoadMore] = useState<boolean>(false);
  const [isScrollEnd, setIsScrollEnd] = useState<boolean>(false);

  const user: any = localStorage.getItem('user');

  // const crums = [
  //   {
  //     name: 'Home',
  //     url: '/'
  //   },
  //   {
  //     name: 'News',
  //     url: '/news',
  //     selected: true
  //   }
  // ];

  const fetchNewsData = async (params: getAllNewsType) => {
    setLoading(true);
    if (user) {
      const resBooks = await NewsService.getAllNews(params);
      if (resBooks) {
        setNews(resBooks?.data);
        if (params.page_index === 1) {
          setNewsItems(resBooks?.data?.items);
        } else {
          setNewsItems([...newsItems, ...resBooks?.data?.items]);
        }
        setLoading(false);
        setLoadMoreLoading(false);
      }
    } else {
      const resBooks = await NewsService.getAllNewsHome(params);
      if (resBooks) {
        setNews(resBooks?.data);
        if (params.page_index === 1) {
          setNewsItems(resBooks?.data?.items);
        } else {
          if (resBooks?.data?.items?.length > 0) {
            setStopLoadMore(false);
            setNewsItems([...newsItems, ...resBooks?.data?.items]);
          } else {
            setStopLoadMore(true);
          }
        }
        setLoading(false);
        setLoadMoreLoading(false);
        setIsScrollEnd(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    window.addEventListener('scroll', handleScroll);

    let params = {
      search_key: searchKey || '',
      page_index: Number(currentPage),
      page_size: itemsPerPage
    };

    fetchNewsData(params);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (loadMoreLoading && !stopLoadMore) {
      setLoadMoreLoading(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      const params = {
        search_key: searchKey || '',
        page_index: newPage,
        page_size: itemsPerPage
      };
      fetchNewsData(params);
    }
  }, [isScrollEnd]);

  const handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + window.scrollY) <
        document.documentElement.scrollHeight ||
      loading ||
      loadMoreLoading ||
      isScrollEnd
    ) {
      return;
    }
    setIsScrollEnd(true);
  };

  const handleKeydown = (event: any) => {
    if (event.key === 'Enter') {
      const newPage = 1;
      setCurrentPage(newPage);
      const params = {
        search_key: searchKey || '',
        page_index: newPage,
        page_size: itemsPerPage
      };
      fetchNewsData(params);
    }
  };

  const onClickSearch = () => {
    const newPage = 1;
    setCurrentPage(newPage);
    const params = {
      search_key: searchKey || '',
      page_index: newPage,
      page_size: itemsPerPage
    };
    fetchNewsData(params);
  };

  return (
    <div>
      <div className="container ">
        <div className="text-[28px] font-medium text-[#363636] text-center mt-[64px]">
          News Hub
        </div>
        <div className="text-[16px] font-medium text-[#363636] text-center mt-1">
          Stay in the know and grow with dynamic learning options at once: news,
          audio, and video.
        </div>

        <div className="w-full flex flex-col mt-8">
          <div className="flex items-center justify-between mb-5">
            <div></div>
            <div className="flex">
              <div className="relative">
                <div
                  className=" absolute top-0 left-0 p-2 cursor-pointer"
                  onClick={onClickSearch}
                >
                  <IoSearchOutline size={16} color="#363636" />
                </div>
                <input
                  type="text"
                  className="w-[140px] h-8 border border-DEDEDE rounded-lg focus:outline-none pl-8 pr-2 text-[16px] font-medium text-[#363636]"
                  placeholder="Search news"
                  onChange={event => setSearchKey(event.target.value)}
                  onKeyDown={handleKeydown}
                />
              </div>
            </div>
          </div>
          {loading && <Loading />}
          {news?.total_results === 0 ? (
            <Empty>
              <div className="flex flex-col gap-6 text-center">
                <p className="text-[22px] font-medium">
                  No news at the moment.
                </p>
                <div className="flex flex-col gap-3 items-center">
                  <p className="text-sm">
                    How about another adventure with new news?
                  </p>
                  <Button
                    className="px-2 flex gap-2 justify-center items-center rounded-lg bg-blue_2 py-0 text-F3FAFA"
                    url={`/`}
                  >
                    <FaRegNewspaper size={16} />
                    <p className="text-sm font-medium">Explore Other News</p>
                  </Button>
                </div>
              </div>
            </Empty>
          ) : (
            <>
              <div className="flex gap-4 flex-wrap justify-center">
                {newsItems?.map((item: any) => (
                  <Link to={`/news/detail/${item?.id}`} key={item.id}>
                    <BoxContainer className="w-full sm:w-[340px]">
                      <div className="flex flex-col gap-4">
                        <div className="w-full">
                          <img
                            src={item?.cover_img}
                            alt="Book Img"
                            className="object-cover h-full rounded-lg"
                          />
                        </div>
                        <div className="w-full">
                          <p className="text-base font-medium h-[48px] line-clamp-2">
                            {item?.title}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="text-676767 text-[12px] font-medium">
                            {moment(item?.created_time).format(
                              'DD-MM-YYYY HH:MM'
                            )}
                          </p>
                          {/* <div
                            className="cursor-poiter"
                            onClick={e => e.stopPropagation()}
                          >
                            <SaveIcon />
                          </div> */}
                        </div>
                      </div>
                    </BoxContainer>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
        <div>{loadMoreLoading && <Loading />}</div>
      </div>
    </div>
  );
};

export default NewsList;
