import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HomeRoutes } from './HomeRoutes';
import { UserInfoRoutes } from './UserInfoRoutes';
import { AccountRoutes } from './AccountRoutes';
import ContactRoutes from './ContactRoutes';
import { NewsRoutes } from './NewsRoutes';
import { BooksRoutes } from './BooksRoutes';
import { ListAssignRoutes } from './ListAssignRoutes';
import { SearchRoutes } from './SearchRoutes';
import AdminInfoRoutes from './AdminInfoRoutes';
import BooksManagementRoutes from './BooksManagementRoutes';
import StudentManagementRoutes from './StudentManagementRoutes';
import ClassManagementRoutes from './ClassManagementRoutes';
import AccountManagementRoutes from './AccountManagementRoutes';
import RouteProtection from '@shared/components/RouteProtection';
import RoutePortalProtection from '@shared/components/RoutePortalProtection';

const routes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <RouteProtection>
              <HomeRoutes />
            </RouteProtection>
          }
        />
        <Route path="/login/*" element={<UserInfoRoutes />} />
        <Route path="/portal/*" element={<AdminInfoRoutes />} />
        <Route
          path="/user-info/*"
          element={
            <RouteProtection>
              <AccountRoutes />
            </RouteProtection>
          }
        />
        <Route
          path="/contact/*"
          element={
            <RouteProtection>
              <ContactRoutes />
            </RouteProtection>
          }
        />
        <Route
          path="/books/*"
          element={
            <RouteProtection>
              <BooksRoutes />
            </RouteProtection>
          }
        />
        <Route
          path="/news/*"
          element={
            <RouteProtection>
              <NewsRoutes />
            </RouteProtection>
          }
        />
        <Route
          path="/list-assign/*"
          element={
            <RouteProtection>
              <ListAssignRoutes />
            </RouteProtection>
          }
        />
        <Route
          path="/search/*"
          element={
            <RouteProtection>
              <SearchRoutes />
            </RouteProtection>
          }
        />
        <Route
          path="/portal/books-management/*"
          element={
            <RoutePortalProtection>
              <BooksManagementRoutes />
            </RoutePortalProtection>
          }
        />
        <Route
          path="/portal/student-management/*"
          element={
            <RoutePortalProtection>
              <StudentManagementRoutes />
            </RoutePortalProtection>
          }
        />
        <Route
          path="/portal/class-management/*"
          element={
            <RoutePortalProtection>
              <ClassManagementRoutes />
            </RoutePortalProtection>
          }
        />
        <Route
          path="/portal/account-management/*"
          element={
            <RoutePortalProtection>
              <AccountManagementRoutes />
            </RoutePortalProtection>
          }
        />
      </Routes>
    </>
  );
};

export default routes;
