import React from 'react';
import { BookType } from 'types/Book.type';

type BookTypeBoxProps = {
  type: number;
  title: string;
  status?: boolean;
};

const BookTypeBox = ({ type, title, status = true }: BookTypeBoxProps) => {
  return title ? (
    <div
      className={`px-2 py-1 rounded-[100px] text-[10px] font-medium ${
        !status ? '!bg-EDEDED !text-999999' : ''
      } ${
        type === BookType.LEVEL && status
          ? 'bg-F0F9FF text-065986'
          : 'bg-ECFDF3 text-05603A '
      }`}
    >
      {title}
    </div>
  ) : null;
};

export default BookTypeBox;
