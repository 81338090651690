import React from 'react';

const FooterPortal = () => {
  return (
    <>
      <div className="flex min-h-[45px] px-10 items-center">
        <div>
          <p>Copyright © 2023 Quang Minh English Center</p>
        </div>
        <div className=""></div>
      </div>
    </>
  );
};

export default FooterPortal;
