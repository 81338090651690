import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { Button } from '../button/Button';
import BooksManagementService from '@core/services/books.portal.service';
import { toast } from 'react-toastify';
import { CircleSpin } from 'assets/icons/CircleSpin';
import { PortalButton } from '../button/PortalButton';

type BookPageReadDetailProps = {
  bookPage: any;
  bookId: string;
};

const BookPageReadDetail = ({ bookPage, bookId }: BookPageReadDetailProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors }
  } = useForm<any>({});

  const [typeSelected, setTypeSelected] = useState<any>();
  const [loading, setLoading] = useState<any>(true);

  const imgUrlWatch = watch('url');

  useEffect(() => {
    setLoading(true);
    if (bookPage) {
      setValue('type', bookPage.type);
      setValue('url', bookPage.url);
      if (bookPage.type === 'portrait') {
        setTypeSelected({
          value: 'portrait',
          label: 'Portrait'
        });
      } else {
        setTypeSelected({
          value: 'landscape',
          label: 'Landscape'
        });
      }
    }
    setLoading(false);
  }, [bookPage]);

  const submitHandler = async (data: any) => {
    try {
      const params = {
        book_type: 'Read',
        book_cover_detail: {
          id: bookPage?.id,
          url: data.url,
          type: data.type
        }
      };
      const response = await BooksManagementService.updateBookPage(
        bookId,
        params
      );

      if (response?.code === 'Ok') {
        toast.success('Update Success!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      } else {
        toast.error(response?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          pauseOnFocusLoss: false
        });
      }
    } catch (error) {
      toast.error('Update Fail! Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
      });
    }
  };

  const handleTypeChange = (data: any) => {
    setTypeSelected(data);
    setValue('type', data?.value);
  };
  return (
    <>
      {!loading && (
        <form
          className="form"
          onSubmit={handleSubmit(submitHandler)}
          noValidate
          autoComplete="off"
        >
          <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
            <label htmlFor="type" className={``}>
              Page Type
            </label>
            <Select
              options={[
                {
                  value: 'portrait',
                  label: 'Hình nằm dọc'
                },
                {
                  value: 'landscape',
                  label: 'Hình nằm ngang'
                }
              ]}
              placeholder="Choose genre"
              value={typeSelected}
              onChange={handleTypeChange}
              className="form-select mt-1"
              classNamePrefix="select"
            />
            {errors?.type?.message && (
              <span className="text-redCustom-3b text-xs">
                {errors?.type?.message as string}
              </span>
            )}
          </div>
          <div className="form-group my-2 ml-[5px] mr-[23px] w-full">
            <label htmlFor="url" className={``}>
              Image Url
            </label>
            <input
              id="url"
              type="text"
              required
              placeholder="Image Url"
              className="form-control"
              {...register('url', {
                required: 'Book title is required',
                setValueAs: (value: string) => value?.trim(),
                onChange: () => {
                  trigger('url');
                }
              })}
            />
            {errors?.title?.message && (
              <span className="text-redCustom-3b text-xs">
                {errors?.title?.message as string}
              </span>
            )}
          </div>
          {imgUrlWatch && (
            <div className="mt-3">
              <img
                src={imgUrlWatch}
                alt="ImageUrl"
                className="w-[30vw] h-auto"
              />
            </div>
          )}

          <PortalButton
            type="submit"
            className="min-w-[140px]"
            onClick={() => {
              handleSubmit(submitHandler)();
            }}
          >
            Update
          </PortalButton>
        </form>
      )}
      {loading && (
        <>
          <div className="flex justify-center mt-4 items-center tex-sm">
            <CircleSpin color="text-primary-e2" /> Loading...
          </div>
        </>
      )}
    </>
  );
};

export default BookPageReadDetail;
