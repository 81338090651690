import {
  HOST_URL_PORTAL,
  STUDENT_MANAGEMENT
} from '@core/constant/api.contant';
import axiosInstance_admin from './restful.portal.service';

const getAllStudents = (params: any) => {
  return axiosInstance_admin
    .get(HOST_URL_PORTAL + STUDENT_MANAGEMENT.STUDENT, { params })
    .then(response => {
      return response?.data;
    });
};

const addStudent = (params: any) => {
  return axiosInstance_admin
    .post(HOST_URL_PORTAL + STUDENT_MANAGEMENT.STUDENT, params)
    .then(response => {
      return response?.data;
    });
};

const editStudent = (params: any) => {
  return axiosInstance_admin
    .put(HOST_URL_PORTAL + STUDENT_MANAGEMENT.STUDENT, params)
    .then(response => {
      return response?.data;
    });
};

const getDetailStudent = (id: string) => {
  return axiosInstance_admin
    .get(HOST_URL_PORTAL + STUDENT_MANAGEMENT.GET_STUDENT_BY_ID(id))
    .then(response => {
      return response?.data;
    });
};

const assignStudentBook = (params: any) => {
  return axiosInstance_admin
    .post(HOST_URL_PORTAL + STUDENT_MANAGEMENT.ASSIGN_STUDENT_BOOK, params)
    .then(response => {
      return response?.data;
    });
};

const getBooksAssignToStudent = (id: string) => {
  return axiosInstance_admin
    .get(HOST_URL_PORTAL + STUDENT_MANAGEMENT.GET_BOOKS_ASSIGN_TO_STUDENT(id))
    .then(response => {
      return response?.data;
    });
};

const deleteBookAssignToStudent = (student_id: string, assign_id: string) => {
  return axiosInstance_admin
    .delete(
      HOST_URL_PORTAL +
        STUDENT_MANAGEMENT.DELETE_BOOK_ASSIGN_TO_STUDENT(student_id, assign_id)
    )
    .then(response => {
      return response?.data;
    });
};

const StudentManagementService = {
  getAllStudents,
  addStudent,
  editStudent,
  getDetailStudent,
  assignStudentBook,
  getBooksAssignToStudent,
  deleteBookAssignToStudent
};

export default StudentManagementService;
