
import MasterLayoutPortal from '@shared/layouts/MasterLayoutPortal';
import { Login } from 'portal-pages/login/Login';
import PortalHome from 'portal-pages/portalHome/PortalHome';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const AdminInfoRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayoutPortal />}>
        <Route index element={<PortalHome />} />
      </Route>
      <Route path="/login">
        <Route index element={<Login />} />
      </Route>
    </Routes>
  );
};

export default AdminInfoRoutes;
