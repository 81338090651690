import BooksService from '@core/services/books.service';
import BookTypeBox from '@shared/components/BookTypeBox';
import { BoxContainer } from '@shared/components/BoxContainer';
import { Button, Loading } from '@shared/components/Index';
import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { FaFireAlt } from 'react-icons/fa';
import process_icon from 'assets/imgs/processIcon.png';
import book_icon from 'assets/imgs/book_icon.png';
import { FaDownload, FaHeadphones, FaRegClock } from 'react-icons/fa6';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BookAssignType, BookType } from 'types/Book.type';
import { book } from 'types/data.type';
import {
  checkComingSoon,
  countComingSoonDays,
  countRemainingDays,
  processBook
} from '@core/hooks/common';
import { AccoutType } from 'types/Auth.type';
import { IoCheckmark } from 'react-icons/io5';

const BookView = () => {
  const [bookViewDataHome, setBookViewDataHome] = useState<book>();
  const [bookViewDataAssign, setBookViewDataAssign] = useState<any>();
  const [isAssign, setIsAssign] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const bookID = params?.id?.split('-')[0] || params?.id || '';
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const url = useLocation().pathname;
  const navigate = useNavigate();
  const accountType = localStorage.getItem('user_type');

  const getDetailBook = async (bookID: string) => {
    const res = await BooksService.getDetailBookHome(bookID);
    if (res) {
      setBookViewDataHome(res?.data);
      setLoading(false);
    }
  };

  const getDetailBookAssign = async (bookID: string) => {
    const res = await BooksService.getDetailBook(bookID);
    if (res?.data) {
      setBookViewDataAssign(res?.data);
      setIsAssign(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDetailBook(bookID);
    if (user?.data) {
      getDetailBookAssign(bookID);
    }
  }, [bookID, user?.data]);

  const handleLogin = () => {
    navigate(`/login?redirectTo=${url}`);
  };

  return (
    <div>
      <div className="container mt-8">
        {loading ? (
          <Loading />
        ) : (
          <BoxContainer className="p-8 flex flex-col lg:flex-row gap-8 xl:max-w-[1088px] xl:w-[1088px] items-center lg:items-stretch lg:!justify-start">
            <div className="flex md:justify-center md:w-[40%] rounded-2xl">
              <img
                src={
                  bookViewDataHome?.is_listen
                    ? bookViewDataHome?.cover_listen_img
                    : bookViewDataHome?.cover_read_img
                }
                alt="Book Img"
                className="w-full rounded-2xl"
              />
            </div>
            <div className="w-full flex flex-col">
              <div className="flex gap-1 flex-wrap">
                <BookTypeBox
                  type={BookType.LEVEL}
                  title={bookViewDataAssign?.level || ''}
                />
                <BookTypeBox
                  type={BookType.CATEGORY}
                  title={bookViewDataAssign?.category || ''}
                />
              </div>
              <div className="my-2">
                <h1 className="text-[28px] font-medium ">
                  {bookViewDataHome?.title}
                </h1>
              </div>
              <p
                className="text-lg font-normal leading-6 line-clamp-6 tracking-[0.096px]"
                dangerouslySetInnerHTML={{
                  __html: bookViewDataHome?.description || ''
                }}
              ></p>
              {!user?.data && (
                <p>
                  <span
                    className="text-blue_2 cursor-pointer"
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    Login here
                  </span>{' '}
                  to get more detail.
                </p>
              )}
              <div className={`mt-8 text-sm`}>
                {accountType === AccoutType.PREMIUM &&
                  bookViewDataAssign?.to_date &&
                  bookViewDataAssign?.to_date !== BookAssignType.NOT_ASSIGN && (
                    <>
                      <div
                        className={`flex ${
                          checkComingSoon(bookViewDataAssign?.from_date)
                            ? 'gap-[2px]'
                            : 'gap-2'
                        } ${
                          countRemainingDays(bookViewDataAssign?.to_date) > 1
                            ? ''
                            : 'text-D92D20'
                        }`}
                      >
                        <div className="flex gap-[2px] items-center">
                          {countRemainingDays(
                            bookViewDataAssign?.to_date || ''
                          ) > 1 ? (
                            <FaRegClock />
                          ) : (
                            <FaFireAlt />
                          )}
                          {!checkComingSoon(bookViewDataAssign?.from_date) && (
                            <p>Remaining Days</p>
                          )}
                        </div>
                        <p>
                          {!checkComingSoon(bookViewDataAssign?.from_date)
                            ? `${countRemainingDays(
                                bookViewDataAssign?.to_date
                              )}${
                                countRemainingDays(
                                  bookViewDataAssign?.to_date
                                ) > 1
                                  ? ' days left'
                                  : ' day left'
                              }`
                            : `Opens in ${countComingSoonDays(
                                bookViewDataAssign?.from_date
                              )}${
                                countComingSoonDays(
                                  bookViewDataAssign?.from_date
                                ) > 1
                                  ? ' days'
                                  : ' day'
                              }`}
                        </p>
                      </div>
                      {!checkComingSoon(bookViewDataAssign?.from_date) && (
                        <div className="flex gap-2 mt-2">
                          <div className="flex gap-[2px] items-center">
                            <img
                              src={process_icon}
                              alt="Process Icon"
                              className="w-4"
                            />
                            <p>Your Progress</p>
                          </div>
                          <div className="flex-1 flex gap-2 items-center">
                            <ProgressBar
                              now={processBook(
                                Math.round(
                                  (bookViewDataAssign?.current_page_count /
                                    bookViewDataAssign?.total_page_count) *
                                    100
                                ),
                                Math.round(
                                  (bookViewDataAssign?.current_cover_count /
                                    bookViewDataAssign?.total_cover_count) *
                                    100
                                )
                              )}
                              label={
                                <>
                                  <div className="h-[8px] bg-blue_2 rounded"></div>
                                </>
                              }
                              className="w-full h-[8px]"
                            />
                            <p className="text-sm">
                              {processBook(
                                Math.round(
                                  (bookViewDataAssign?.current_page_count /
                                    bookViewDataAssign?.total_page_count) *
                                    100
                                ),
                                Math.round(
                                  (bookViewDataAssign?.current_cover_count /
                                    bookViewDataAssign?.total_cover_count) *
                                    100
                                )
                              )}
                              %
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
              </div>
              <div className="flex-1 flex-col lg:flex-row flex justify-between gap-y-5 items-end mt-10 lg:mt-0">
                <div className="flex flex-col lg:flex-row gap-2 w-full lg:w-1/2">
                  {bookViewDataAssign?.is_read && isAssign && (
                    <Button
                      className="px-2 flex gap-2 justify-center items-center rounded-lg"
                      url={`/books/detail/read-${bookViewDataAssign?.id}`}
                    >
                      {bookViewDataAssign?.current_cover_count ===
                      bookViewDataAssign?.total_cover_count ? (
                        <IoCheckmark size={16} color="#F3FAFA" />
                      ) : (
                        <img
                          src={book_icon}
                          alt="Book Icon"
                          className="w-[14px]"
                        />
                      )}
                      <p className="text-sm font-medium">Read</p>
                    </Button>
                  )}
                  {bookViewDataAssign?.is_listen && isAssign && (
                    <Button
                      className="px-2 flex gap-2 justify-center items-center rounded-lg"
                      url={`/books/detail/listen-${bookViewDataAssign?.id}`}
                    >
                      {bookViewDataAssign?.current_page_count ===
                      bookViewDataAssign?.total_page_count ? (
                        <IoCheckmark size={16} color="#F3FAFA" />
                      ) : (
                        <FaHeadphones size={16} />
                      )}
                      <p className="text-sm font-medium">Listen</p>
                    </Button>
                  )}
                </div>
                <div className="flex w-full justify-center lg:justify-end gap-2 lg:w-1/2">
                  {bookViewDataAssign?.single_pdf && (
                    <Link
                      to={bookViewDataAssign?.single_pdf}
                      target="_blank"
                      className="flex gap-2 items-center px-2 py-3 text-235E5A"
                    >
                      <FaDownload className="w-4" />
                      <p className="text-sm line-clamp-1">Single PDF</p>
                    </Link>
                  )}
                  {bookViewDataAssign?.double_pdf && (
                    <Link
                      to={bookViewDataAssign?.double_pdf}
                      target="_blank"
                      className="flex gap-2 items-center px-2 py-3 text-235E5A"
                    >
                      <FaDownload className="w-4" />
                      <p className="text-sm line-clamp-1">Double PDF</p>
                    </Link>
                  )}
                  {bookViewDataAssign?.pocket_pdf && (
                    <Link
                      to={bookViewDataAssign?.pocket_pdf}
                      target="_blank"
                      className="flex gap-2 items-center px-2 py-3 text-235E5A"
                    >
                      <FaDownload className="w-4" />
                      <p className="text-sm line-clamp-1">Pocket PDF</p>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </BoxContainer>
        )}
      </div>
    </div>
  );
};

export default BookView;
