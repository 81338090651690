import { AuthLoginType, ChangePasswordType } from 'types/Auth.type';
import axiosInstance from './restful.service';
import { AUTH, HOST_URL, HOST_URL_PORTAL } from '@core/constant/api.contant';
import axiosInstance_admin from './restful.portal.service';

const login = (user: AuthLoginType) => {
  return axiosInstance.post(HOST_URL + AUTH.LOGIN, user).then(response => {
    return response?.data;
  });
};

const login_admin = (user: AuthLoginType) => {
  return axiosInstance_admin
    .post(HOST_URL_PORTAL + AUTH.LOGIN, user)
    .then(response => {
      return response?.data;
    });
};

const changePassword = (params: ChangePasswordType) => {
  return axiosInstance
    .put(HOST_URL + AUTH.CHANGE_PASSWORD, params)
    .then(response => {
      return response?.data;
    });
};

const getAccount = (params: any) => {
  return axiosInstance_admin
    .get(HOST_URL_PORTAL + AUTH.ACCOUNT, { params })
    .then(response => {
      return response?.data;
    });
};

const addAccount = (params: any) => {
  return axiosInstance_admin
    .post(HOST_URL_PORTAL + AUTH.ACCOUNT, params)
    .then(response => {
      return response?.data;
    });
};

const updateAccount = (params: any) => {
  return axiosInstance_admin
    .put(HOST_URL_PORTAL + AUTH.ACCOUNT, params)
    .then(response => {
      return response?.data;
    });
};

const getDetailAccount = (id: string) => {
  return axiosInstance_admin
    .get(HOST_URL_PORTAL + AUTH.ACCOUNT_DETAIL(id))
    .then(response => {
      return response?.data;
    });
};

const AuthService = {
  login,
  login_admin,
  changePassword,
  getAccount,
  addAccount,
  updateAccount,
  getDetailAccount,
};

export default AuthService;
