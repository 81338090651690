import MasterLayoutPortal from '@shared/layouts/MasterLayoutPortal';
import AccountManagement from 'portal-pages/accountManagement/AccountManagement';
import AccountManagementAdd from 'portal-pages/accountManagement/AccountManagementAdd';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const AccountManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayoutPortal />}>
        <Route index element={<AccountManagement />} />
      </Route>
      <Route path="/add-account" element={<MasterLayoutPortal />}>
        <Route index element={<AccountManagementAdd />} />
      </Route>
      <Route path="/edit-account/:id" element={<MasterLayoutPortal />}>
        <Route index element={<AccountManagementAdd />} />
      </Route>
    </Routes>
  );
};

export default AccountManagementRoutes;
