import React from 'react';
import styles from './index.module.scss';
import logo from 'assets/imgs/portal-logo.png';

import { NavBar } from '@shared/components/navbar';

const SideBar: React.FC = () => {
  return (
    <>
      <div
        className={`${styles['page-sidebar']} min-w-[280px] w-auto fixed overflow-auto h-full font-Roboto z-50`}
      >
        <div
          className={`${styles['page-logo']} bg-brand-gradient-1 flex items-center justify-center`}
        >
          <img
            className={`${styles['loginLeftContent-logo']} max-h-[100px] w-auto mr-4`}
            src={logo}
            alt="tititada"
          />
        </div>
        <div className={styles['page-sidebar-content']}>
          <div>
            <h1 className="text-center font-bold">Admin Website</h1>
          </div>
          <div className="mt-2">
            <NavBar />
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
