import React, { useState } from 'react';
import styles from './index.module.scss';
import { NavBarItem } from './NavBarItem';
import { useLocation } from 'react-router-dom';
import { BiChevronRight } from 'react-icons/bi';

export const NavBar: React.FC = () => {
  const location = useLocation();
  const newPathname = location.pathname.split('/');
  const [isShowAccount, setIsShowAccount] = useState<any>(() => {
    if (
      newPathname[1] === 'account-management' ||
      newPathname[1] === 'duplicate-account'
    ) {
      return true;
    } else {
      return false;
    }
  });

  const adminInfo: any = JSON.parse(localStorage.getItem('admin') || '');

  const showSubmenu = (name?: string) => {
    // if (name === 'accountManagement') {
    //   setIsShowAccount(!isShowAccount);
    // }
  };

  const [isShowMoney, setIsShowMoney] = useState<any>(() => {
    if (
      newPathname[1] === 'deposit-management' ||
      newPathname[1] === 'withdraw-management' ||
      newPathname[1] === 'approve-withdraw-management'
    ) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <>
      <div className={`${styles.navbar} overflow-y-auto min-h-[200px] pb-4`}>
        <NavBarItem
          position="I"
          name="FEATURE MANAGEMENT"
          icon={() => <></>}
          url=""
          childrenUrl={["/portal/*"]}
          isShowSubmenu={showSubmenu}
          roles={['Admin', 'Teacher']}
          auth={adminInfo}
          selfClassName={'!font-bold'}
        />
        <NavBarItem
          position="1"
          name="Account Management"
          icon={() => <BiChevronRight />}
          url="/portal/account-management"
          childrenUrl={["/portal/account-management/*"]}
          isShowSubmenu={showSubmenu}
          nameToggleMenu="accountManagement"
          roles={['Admin']}
          auth={adminInfo}
          isRotateIcon={isShowMoney}
          selfClassName={'!pl-2'}
        />
        <NavBarItem
          position="2"
          name="Student Management"
          icon={() => <BiChevronRight />}
          url="/portal/student-management"
          childrenUrl={["/portal/student-management/*"]}
          isShowSubmenu={showSubmenu}
          nameToggleMenu="studentManagement"
          roles={['Admin', 'Teacher']}
          auth={adminInfo}
          isRotateIcon={isShowMoney}
          selfClassName={'!pl-2'}
        />
        <NavBarItem
          position="3"
          name="Class Management"
          icon={() => <BiChevronRight />}
          url="/portal/class-management"
          childrenUrl={['/portal/class-management/*']}
          isShowSubmenu={showSubmenu}
          nameToggleMenu="classManagement"
          roles={['Admin', 'Teacher']}
          auth={adminInfo}
          isRotateIcon={isShowMoney}
          selfClassName={'!pl-2'}
        />
        <NavBarItem
          position="4"
          name="Book Management"
          icon={() => <BiChevronRight />}
          url="/portal/books-management"
          childrenUrl={['/portal/books-management/*']}
          isShowSubmenu={showSubmenu}
          nameToggleMenu="booksManagement"
          roles={['Admin', 'Teacher']}
          auth={adminInfo}
          isRotateIcon={isShowMoney}
          selfClassName={'!pl-2'}
        />
      </div>
    </>
  );
};
