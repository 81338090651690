import MasterLayout from '@shared/layouts/MasterLayout';
import Contact from 'pages/contact/Contact';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const ContactRoutes = () => {
    return (
        <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route index element={<Contact />} />
      </Route>
    </Routes>
    );
};

export default ContactRoutes;