import BooksService from '@core/services/books.service';
import BookTypeBox from '@shared/components/BookTypeBox';
import { BoxContainer, Button, Empty, Loading } from '@shared/components/Index';
import process_icon from 'assets/imgs/processIcon.png';
import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { BookType } from 'types/Book.type';
import { category } from 'types/data.type';
import { FaFireAlt } from 'react-icons/fa';
import { FaCircleCheck, FaRegClock } from 'react-icons/fa6';
import Select from 'react-select';
import { IoSearchOutline } from 'react-icons/io5';
import book_icon from 'assets/imgs/book_icon.png';
import {
  checkComingSoon,
  checkFinishedBook,
  countComingSoonDays,
  countRemainingDays,
  processBook
} from '@core/hooks/common';
import { AiOutlineNotification } from 'react-icons/ai';

enum BOOK_STATUS {
  COMING_SOON = 'ComingSoon',
  LEARNING = 'Learning',
  COMPLETED = 'Completed',
  UNFINISHED = 'Unfinished'
}

const BookStatus = [
  { title: 'Coming Soon', id: 'ComingSoon' },
  { title: 'Learning', id: 'Learning' },
  { title: 'Completed', id: 'Completed' },
  { title: 'Unfinished', id: 'Unfinished' }
];

const ListAssign = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');
  const [booksUserDataItems, setBooksUserDataItems] = useState<any>([]);
  const [loadingBooks, setLoadingBooks] = useState(false);
  const [itemsPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const [listCategory, setListCategory] = useState<category>();
  const [level, setLevel] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [bookStatusTab, setBookStatusTab] = useState(
    status || BOOK_STATUS.LEARNING
  );
  const [searchKey, setSearchKey] = useState<string>('');
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [stopLoadMore, setStopLoadMore] = useState<boolean>(false);
  const [isScrollEnd, setIsScrollEnd] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    window.addEventListener('scroll', handleScroll);

    const fetchDataCategory = async () => {
      const resCategory = await BooksService.getListCategory();
      if (resCategory) {
        setListCategory(resCategory?.data);
      }
    };

    fetchDataCategory();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const fetchData = async (param: any) => {
    setLoadingBooks(true);
    let params = {
      ...param,
      page_size: itemsPerPage,
      level: level?.value,
      category: category?.value,
      finish_status: bookStatusTab,
      search_key: searchKey || ''
    };

    if (bookStatusTab === BOOK_STATUS.COMING_SOON) {
      params = {
        ...params,
        finish_status: BOOK_STATUS.LEARNING
      };
    }

    const resBooks = await BooksService.getUserBooks(params);
    if (resBooks) {
      if (param?.page_index === 1) {
        let listBooks = resBooks?.data?.items;
        if (bookStatusTab === BOOK_STATUS.COMING_SOON) {
          listBooks = listBooks.filter((item: any) =>
            checkComingSoon(item?.from_date)
          );
        } else if (bookStatusTab === BOOK_STATUS.LEARNING) {
          listBooks = listBooks.filter(
            (item: any) => !checkComingSoon(item?.from_date)
          );
        }

        setBooksUserDataItems(listBooks);
      } else {
        if (resBooks?.data?.items?.length > 0) {
          setStopLoadMore(false);
          setBooksUserDataItems([
            ...booksUserDataItems,
            ...resBooks?.data?.items
          ]);
        } else {
          setStopLoadMore(true);
        }
      }
      setLoadingBooks(false);
      setLoadMoreLoading(false);
      setIsScrollEnd(false);
    }
  };

  const handleKeydown = (event: any) => {
    if (event.key === 'Enter') {
      const newPage = 1;
      setCurrentPage(newPage);
      const params = {
        page_index: newPage
      };
      fetchData(params);
    }
  };

  const onClickSearch = () => {
    const newPage = 1;
    setCurrentPage(newPage);
    const params = {
      page_index: newPage
    };
    fetchData(params);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: '32px',
      height: '32px',
      boxShadow: state.isFocused ? null : null,
      borderRadius: '8px'
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '32px',
      padding: '0 6px'
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px'
    }),
    // indicatorSeparator: (state: any) => ({
    //   display: 'none'
    // }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '32px'
    })
  };

  useEffect(() => {
    let params = {
      page_index: 1
    };

    if (loadMoreLoading || loadingBooks) {
      return;
    }
    fetchData(params);
  }, [level, category, bookStatusTab]);

  useEffect(() => {
    if (isScrollEnd && !stopLoadMore) {
      setLoadMoreLoading(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      const params = {
        page_index: newPage
      };
      fetchData(params);
    }
  }, [isScrollEnd]);

  const handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + window.scrollY) <
        document.documentElement.scrollHeight ||
      loadingBooks ||
      loadMoreLoading ||
      isScrollEnd
    ) {
      return;
    }
    setIsScrollEnd(true);
  };

  const handleClickTab = (itemId: string) => {
    if (loadingBooks || loadMoreLoading) {
      return;
    }
    setBookStatusTab(itemId);
  };

  useEffect(() => {
    if (status) {
      setBookStatusTab(status);
    }
  }, [status]);

  return (
    <>
      <div className="container p-4 h-max overflow-hidden">
        <div className="pb-10 flex flex-col gap-4">
          <h1 className="text-[22px] font-medium">My Learning</h1>
          <div className="flex flex-col md:flex-row justify-between gap-3">
            <div className="flex justify-between gap-1 px-1 py-[5px] rounded-md bg-F3FAFA">
              {BookStatus?.map(item => (
                <div
                  key={item?.id}
                  className={`rounded px-4 py-1 cursor-pointer ${
                    bookStatusTab === item.id
                      ? 'bg-white text-235E5A shadow-md'
                      : 'text-888888 bg-F3FAFA'
                  }`}
                  onClick={() => {
                    handleClickTab(item.id);
                  }}
                >
                  <p className="text-xs font-medium">{item.title}</p>
                </div>
              ))}
            </div>
            <div className="flex flex-col md:flex-row justify-between gap-3 z-40">
              <div className="flex">
                <div className="relative w-full md:w-[140px]">
                  <div
                    className=" absolute top-0 left-0 p-2 cursor-pointer"
                    onClick={onClickSearch}
                  >
                    <IoSearchOutline size={16} color="#363636" />
                  </div>
                  <input
                    type="text"
                    className="text-sm w-full border border-DEDEDE rounded-lg focus:outline-none pl-9 pr-3 h-8 font-medium text-[#363636]"
                    placeholder="Search books"
                    onChange={event => setSearchKey(event.target.value)}
                    onKeyDown={handleKeydown}
                  />
                </div>
              </div>
              <div className="relative">
                {/* <div className="absolute right-3 top-[11px] z-30">
                  <IoIosArrowDown className="text-base" />
                </div> */}
                <Select
                  value={level}
                  onChange={e => {
                    setLevel(e);
                  }}
                  options={listCategory?.level?.map(item => {
                    return { value: item, label: item };
                  })}
                  placeholder="Level"
                  className="w-full md:w-[140px] !border-C8C8C8 text-sm hover:!border-none custom-select"
                  styles={customStyles}
                />
              </div>
              <div className="relative">
                {/* <div className="absolute right-3 top-[11px] z-30">
                  <IoIosArrowDown className="text-base" />
                </div> */}
                <Select
                  value={category}
                  onChange={e => {
                    setCategory(e);
                  }}
                  options={listCategory?.category?.map(item => {
                    return { value: item, label: item };
                  })}
                  placeholder="Topic"
                  className="w-full md:w-[140px] !border-C8C8C8 text-sm hover:!border-none"
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
          {bookStatusTab === 'Unfinished' && (
            <div className="h-[64px] px-4 rounded-lg bg-F0F9FF flex items-center justify-between">
              <div className=" flex items-center gap-2">
                <AiOutlineNotification size={16} color="#026AA2" />
                <p className="text-026AA2 text-sm">
                  Your assignments have expired. You will be assigned new ones
                  shortly.
                </p>
              </div>
              <div
                className="px-2 h-8 flex items-center"
                onClick={() => {
                  setBookStatusTab('Learning');
                }}
              >
                <p className="text-sm font-medium text-235E5A cursor-pointer">
                  View Assignments
                </p>
              </div>
            </div>
          )}
          <div className="flex items-center flex-wrap gap-4 lg:justify-between">
            {loadingBooks && !loadMoreLoading ? (
              <div className="flex justify-center w-full">
                <Loading />
              </div>
            ) : booksUserDataItems?.length === 0 &&
              bookStatusTab === 'Learning' ? (
              <Empty>
                <div className="flex flex-col gap-6 text-center">
                  <p className="text-[22px] font-medium">
                    No assigned books at the moment.
                  </p>
                  <div className="flex flex-col gap-3 items-center">
                    <p className="text-sm">
                      How about another adventure with new books?
                    </p>
                    <Button
                      className="px-2 flex gap-2 justify-center items-center rounded-lg !bg-blue_2 py-0 text-F3FAFA"
                      url={`/books`}
                    >
                      <img src={book_icon} alt="Book Icon" className="w-4" />
                      <p className="text-sm font-medium">Explore Other Books</p>
                    </Button>
                  </div>
                </div>
              </Empty>
            ) : (
              booksUserDataItems.map((item: any) => (
                <BoxContainer className="lg:max-h-[218px] lg:h-[218px] md:w-[calc(50%-8px)] xl:w-[340px]">
                  <Link to={`/books/view/${item?.id}`}>
                    <div className="flex flex-col lg:flex-row gap-x-4">
                      <img
                        src={
                          item?.is_listen
                            ? item?.cover_listen_img
                            : item?.is_read
                            ? item?.cover_read_img
                            : item?.cover_img
                        }
                        alt="Book Img"
                        className="rounded-lg lg:h-[186px] lg:w-[119px] object-contain"
                      />
                      <div className="flex flex-col gap-2 mt-5 lg:mt-0">
                        <div className="flex gap-1 flex-wrap">
                          <BookTypeBox
                            type={BookType.LEVEL}
                            title={item?.level}
                            status={
                              countRemainingDays(item?.to_date) < 1 &&
                              bookStatusTab === 'Unfinished'
                                ? false
                                : true
                            }
                          />
                          <BookTypeBox
                            type={BookType.CATEGORY}
                            title={item?.category}
                            status={
                              countRemainingDays(item?.to_date) < 1 &&
                              bookStatusTab === 'Unfinished'
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div
                          className={`flex flex-col gap-1 ${
                            bookStatusTab === 'Unfinished' ? '!text-999999' : ''
                          }`}
                        >
                          <h1 className="text-base font-medium leading-5">
                            {item?.title}
                          </h1>
                          <p
                            className="text-sm font-normal line-clamp-3 leading-[18px]"
                            dangerouslySetInnerHTML={{
                              __html: item?.description || item?.content
                            }}
                          ></p>
                        </div>
                        {bookStatusTab === 'Completed' && (
                          <div className="flex flex-1 items-end">
                            <div className="flex gap-1 items-center">
                              <FaCircleCheck color="#039855" size={16} />
                              <p className="text-xs font-medium">
                                {checkFinishedBook(item?.to_date)}
                              </p>
                            </div>
                          </div>
                        )}
                        {bookStatusTab !== 'Completed' && (
                          <div
                            className={`flex flex-col gap-[2px] flex-1 justify-end`}
                          >
                            <div
                              className={`flex items-center gap-[2px] ${
                                countRemainingDays(item?.to_date) > 1
                                  ? ''
                                  : `${
                                      bookStatusTab === 'Unfinished'
                                        ? 'text-999999'
                                        : 'text-D92D20'
                                    }`
                              }`}
                            >
                              {countRemainingDays(item?.to_date) > 1 ? (
                                <FaRegClock />
                              ) : (
                                <FaFireAlt />
                              )}
                              <p className="text-sm font-normal leading-[14px]">
                                {!checkComingSoon(item?.from_date)
                                  ? `${countRemainingDays(item?.to_date)}${
                                      countRemainingDays(item?.to_date) > 1
                                        ? ' days left'
                                        : ' day left'
                                    }`
                                  : `Opens in ${countComingSoonDays(
                                      item?.from_date
                                    )}${
                                      countComingSoonDays(item?.from_date) > 1
                                        ? ' days'
                                        : ' day'
                                    }`}
                              </p>
                            </div>
                            {!checkComingSoon(item?.from_date) && (
                              <div className="flex gap-2 items-center">
                                <div className="flex-1 flex gap-[2px] items-center">
                                  <img
                                    src={process_icon}
                                    alt="Process Icon"
                                    className="w-4"
                                  />
                                  <ProgressBar
                                    now={processBook(
                                      Math.round(
                                        (item?.current_page_count /
                                          item?.total_page_count) *
                                          100
                                      ),
                                      Math.round(
                                        (item?.current_cover_count /
                                          item?.total_cover_count) *
                                          100
                                      )
                                    )}
                                    label={
                                      <>
                                        <div
                                          className={`h-[8px] rounded ${
                                            bookStatusTab === 'Unfinished'
                                              ? 'bg-999999'
                                              : 'bg-blue_2'
                                          }`}
                                        ></div>
                                      </>
                                    }
                                    className={`w-full h-[8px] ${
                                      bookStatusTab === 'Unfinished'
                                        ? 'progress-disabled'
                                        : ''
                                    }`}
                                  />
                                </div>
                                <p className="text-sm">
                                  {processBook(
                                    Math.round(
                                      (item?.current_page_count /
                                        item?.total_page_count) *
                                        100
                                    ),
                                    Math.round(
                                      (item?.current_cover_count /
                                        item?.total_cover_count) *
                                        100
                                    )
                                  )}
                                  %
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                </BoxContainer>
              ))
            )}
          </div>
          <div>{loadMoreLoading && <Loading />}</div>
        </div>
      </div>
    </>
  );
};

export default ListAssign;
