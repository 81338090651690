// import { PermissionsGate } from 'components/PermissionsGate';
import React, { useEffect } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import styles from './index.module.scss';
import { NavBarType } from 'types/Navbar.type';
import { BiChevronDown } from 'react-icons/bi';
import { PermissionsGate } from '../PermissionsGate';

export const NavBarItem: React.FC<NavBarType> = ({
  position,
  name,
  url,
  icon,
  childrenUrl,
  isShowSubmenu,
  nameToggleMenu,
  roles,
  auth,
  isRotateIcon,
  selfClassName
}: NavBarType) => {
  let resolved = null;
  let match = null;
  let isMatch = false;

  if (url) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    resolved = useResolvedPath(url);
  }

  if (resolved) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    match = useMatch({ path: resolved.pathname, end: true });
  }

  if (childrenUrl) {
    childrenUrl?.map(item => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const resolvedChild = useResolvedPath(item);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matchChild = useMatch({ path: resolvedChild.pathname, end: true });
      if (matchChild) {
        isMatch = true;
        return true;
      }
      return false;
    });
  }

  const handleOnclick = () => {
    if (nameToggleMenu) {
      isShowSubmenu(nameToggleMenu);
    }
  };

  return (
    <>
    <PermissionsGate scopes={roles} auth={auth}>
      {url && (
        <Link
          to={url}
          className={`flex ${styles['navbar-item']} ${
            match || isMatch ? styles['navbar-item--active'] : ''
          } space-x-2`}
          onClick={handleOnclick}
        >
          {/* <span className='flex-none justify-center items-center text-[22px] w-6'>
            {React.createElement(icon)}
          </span> */}
          <div className={`${selfClassName} flex space-x-2`}>
            <span className="pr-2 min-w-[20px]">{position}</span>
            <span>{name}</span>
          </div>
        </Link>
      )}
      {!url && (
        <div
          className={`flex justify-between ${styles['navbar-item']} ${
            match || isMatch ? styles['navbar-item--active'] : ''
          } space-x-2  cursor-pointer`}
          onClick={handleOnclick}
        >
          <div className={`${selfClassName} flex space-x-2`}>
            {/* {React.createElement(icon)} */}
            <span className="pr-2 min-w-[20px]">{position}</span>
            <span>{name}</span>
          </div>
          {selfClassName !== '!font-bold' && (
            <div>
              <span className={`text-[22px]`}>
                <BiChevronDown
                  className={`${isRotateIcon ? 'rotate-180' : ''}`}
                />
              </span>
            </div>
          )}
        </div>
      )}
    </PermissionsGate>
    </>
  );
};
