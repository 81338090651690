export const HOST_URL = process.env.REACT_APP_API_ENDPOINT;
export const HOST_URL_PORTAL = process.env.REACT_APP_API_PORTAL_ENDPOINT;

export const AUTH = {
  LOGIN: '/auth/login',
  CHANGE_PASSWORD: '/auth/change-password',
  ACCOUNT: '/admin',
  ACCOUNT_DETAIL: (id: string) => `/admin/${id}`
};

export const BOOKS = {
  GET_ALL: '/home/books',
  GET_USER_BOOKS: '/user-book',
  GET_BOOK_BY_ID: (id: string) => `/user-book/${id}`,
  GET_BOOK_BY_ID_HOME: (id: string) => `/home/book-detail/${id}`,
  READ_BOOK: '/user-book/read-page',
  GET_LIST_CATEGORY: '/home/list-category'
};

export const BOOK_MANAGEMENT = {
  BOOKS: '/book',
  GET_BOOK_BY_ID: (id: string) => `/book/${id}`,
  DELETE_BOOK: (id: string) => `/book/${id}`,
  LIST_CATEGORY: '/book/list-category',
  UPDATE_BOOKPAGE: (id: string) => `/book/book-page/${id}`,
  GET_LIST_CATEGORY: '/book/list-category'
};

export const STUDENT_MANAGEMENT = {
  STUDENT: '/student',
  GET_STUDENT_BY_ID: (id: string) => `/student/${id}`,
  ASSIGN_STUDENT_BOOK: '/student/assign-book',
  GET_BOOKS_ASSIGN_TO_STUDENT: (id: string) => `/student/${id}/books-assign`,
  DELETE_BOOK_ASSIGN_TO_STUDENT: (student_id: string, assign_id: string) =>
    `/student/${student_id}/books-assign/${assign_id}`
};

export const CLASS_MANAGEMENT = {
  CLASS: '/classes',
  DETAIL_CLASS: (id: string) => `/classes/${id}`,
  ADD_STUDENT_TO_CLASS: '/classes/add-student',
  REMOVE_STUDENT_FROM_CLASS: '/classes/delete-student',
  GET_STUDENT_IN_CLASS: (id: string) => `/classes/${id}/students-of-class`
};

export const NEWS = {
  GET_ALL: '/user-news',
  GET_ALL_HOME: '/home/news',
  GET_NEWS_BY_ID: (id: string) => `/user-news/${id}`,
  GET_NEWS_BY_ID_HOME: `/home/news-detail`,
  READ_NEWS: '/user-news/read-news'
};

export const SEARCH = {
  SEARCH_ALL: '/home/search-all'
};
