import MasterLayout from '@shared/layouts/MasterLayout';
import NewDetail from 'pages/news/NewDetail';
import NewsList from 'pages/news/NewsList';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const NewsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route index element={<NewsList />} />
      </Route>
      <Route path="/detail/:id" element={<MasterLayout />}>
        <Route index element={<NewDetail />} />
      </Route>
    </Routes>
  );
};
