import { BOOK_MANAGEMENT, HOST_URL_PORTAL } from '@core/constant/api.contant';
import axiosInstance_admin from './restful.portal.service';

const getAllBooks = (params: any) => {
  return axiosInstance_admin
    .get(HOST_URL_PORTAL + BOOK_MANAGEMENT.BOOKS, {params})
    .then(response => {
      return response?.data;
    });
};

const addBook = (params: any) => {
  return axiosInstance_admin
    .post(HOST_URL_PORTAL + BOOK_MANAGEMENT.BOOKS, params)
    .then(response => {
      return response?.data;
    });
};

const editBook = (params: any) => {
  return axiosInstance_admin
    .put(HOST_URL_PORTAL + BOOK_MANAGEMENT.BOOKS, params)
    .then(response => {
      return response?.data;
    });
};

const getDetailBook = (id: string) => {
  return axiosInstance_admin
    .get(HOST_URL_PORTAL + BOOK_MANAGEMENT.GET_BOOK_BY_ID(id))
    .then(response => {
      return response?.data;
    });
};

const getListCategory = () => {
  return axiosInstance_admin
    .get(HOST_URL_PORTAL + BOOK_MANAGEMENT.LIST_CATEGORY)
    .then(response => {
      return response?.data;
    });
}

const deleteBook = (id: string) => {
  return axiosInstance_admin
    .delete(HOST_URL_PORTAL + BOOK_MANAGEMENT.DELETE_BOOK(id))
    .then(response => {
      return response?.data;
    });
}

const updateBookPage = (id: string, params: any) => {
  return axiosInstance_admin
  .put(HOST_URL_PORTAL + BOOK_MANAGEMENT.UPDATE_BOOKPAGE(id), params)
  .then(response => {
      return response?.data;
    });
}

const BooksManagementService = {
  getAllBooks,
  addBook,
  editBook,
  deleteBook,
  getDetailBook,
  getListCategory,
  updateBookPage
};

export default BooksManagementService;
