import React from 'react';

type RightArrowProps = {
  color?: string;
  className?: string;
}

export const RightArrow = ({color, className} : RightArrowProps) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24.00 24.00"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className = {`${className}`}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M10 7L15 12L10 17"
          stroke={`${color ? color : `#fff`}`}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  );
};
